import axios from 'axios';
import dayjs from 'dayjs';
import {
  RemoteError,
  RemoteRequestConfig,
  RemoteRequestOptions,
} from './types';

const TOKEN = 'qz-jwt';

// TODO: 做成顶部进度条
// configLoading('正在加载', 300);

export const API_ROOT =
  process.env.REACT_APP_API_ROOT || process.env.API_ROOT || '';

let token: string;

//  let globalHideLoading = false;
//  export const setHideLoading = (value: boolean) => (globalHideLoading = value);

export const getHeaders = () => {
  if (!token) {
    token = localStorage.getItem(TOKEN) || '';
  }
  return token ? { Authorization: `Bearer ${token}` } : { Authorization: '' };
};

const DEFAULT_OPTIONS: RemoteRequestOptions = {
  //  hideLoading: false,
  //  loadingText: '',
  //  throwException: false,
  method: 'GET',
};

type GlobalConfig = {
  onServerError: (requestId: string, msg: string, error: RemoteError) => void;
  onRateLimiting: (retryAfter: number) => void;
};
let globalConfig: GlobalConfig = {
  onServerError: () => {},
  onRateLimiting: () => {},
};
export const config = (newConfig: GlobalConfig) => {
  globalConfig = {
    ...globalConfig,
    ...newConfig,
  };
};

export const $request = async (
  endpoint: string,
  payload?: object,
  options?: RemoteRequestOptions,
) => {
  if (!endpoint) {
    throw new Error('remote endpoint 为空');
  }
  const _options = { ...DEFAULT_OPTIONS, ...options };
  const {
    //  throwException,
    method,
    //  hideLoading,
    //  loadingText,
    onServerError,
    extraHeaders,
    ...axiosConfig
  } = _options;
  let requestPromise = () => axios({});
  let requestUrl: string;
  if (endpoint.startsWith('http')) {
    requestUrl = endpoint;
  } else {
    requestUrl = API_ROOT + endpoint;
  }
  const headers = getHeaders();
  const config: RemoteRequestConfig = {
    headers: {
      ...headers,
      ...extraHeaders,
    },
    ...axiosConfig,
  };

  try {
    //  if (!hideLoading && !globalHideLoading) {
    //    pushRequest(loadingText);
    //  }

    if (method === 'PUT') {
      requestPromise = () => axios.put(requestUrl, payload, config);
    } else if (method === 'DELETE') {
      requestPromise = () => axios.delete(requestUrl, config);
    } else if (method === 'POST') {
      requestPromise = () => axios.post(requestUrl, payload, config);
    } else if (method === 'PATCH') {
      requestPromise = () => axios.patch(requestUrl, payload, config);
    } else {
      requestPromise = () => axios.get(requestUrl, config);
    }
    const response = await requestPromise();
    //  if (!hideLoading) {
    //    popRequest();
    //  }

    return response.data;
  } catch (_e) {
    const e = _e as RemoteError;
    // remote 只处理业务异常
    if (!e.isAxiosError) {
      throw _e;
    }
    //  if (!hideLoading) {
    //    popRequest();
    //  }

    // TODO: 等青舟明确账号体系后再实现
    if (
      e.response?.status === 403 &&
      e.response?.data.message?.includes?.('安全验证')
    ) {
      // const info = await $get('/user/unlock/request');
      // return new Promise((res, rej) => {
      //   let captchaModalRef: any;
      //   const modalProps: CaptchaModalProps = {
      //     phone: info.phone,
      //     inputProps: {
      //       onSend: () => $post('/user/unlock/sms'),
      //       onSendError: (err: any) => {
      //         Modal.error({
      //           title: '请求失败',
      //           content: '发送失败'
      //         });
      //         console.error(err);
      //       }
      //     },
      //     btnProps: {
      //       onPromiseClick: (code: any) => {
      //         return Promise.resolve()
      //           .then(() =>
      //             $post(
      //               '/user/unlock',
      //               { code },
      //               { throwException: 'showMessage' }
      //             )
      //           )
      //           .then(() => captchaModalRef?.destroy?.())
      //           .catch(rej)
      //           .then(requestPromise)
      //           .then(res)
      //           .catch(rej);
      //       }
      //     }
      //   };
      //   captchaModalRef = CaptchaModal.open(modalProps);
      // });
    }

    if (
      e.response?.status === 429 &&
      e.response?.data?.message?.includes?.('ThrottlerException')
    ) {
      // const xRetryAfter = 'Wed, 03 Mar 2021 06:35:10 GMT';
      const xRetryAfter = e.response.headers['x-retry-after'];
      if (xRetryAfter && dayjs().isBefore(xRetryAfter)) {
        const retryAfter = dayjs(xRetryAfter).diff(dayjs(), 'second');
        globalConfig.onRateLimiting(retryAfter);
      }
    } else if (!isInLoginPage() && e.response?.status === 401) {
      logout();
      requestLogin();
    } else if (e.response && e.response.status > 400) {
      let errorMessage = e.response?.data;
      if (
        typeof errorMessage === 'object' &&
        typeof errorMessage.message === 'string'
      ) {
        errorMessage = errorMessage.message;
      }
      const args: [requestId: string, msg: string, error: RemoteError] = [
        e.response?.headers?.['x-boyu-request-id'] || '',
        errorMessage,
        e,
      ];
      if (onServerError) {
        onServerError(...args);
      } else {
        globalConfig.onServerError(...args);
      }
    }

    // remote 中可以额外加自动处理，但是禁止吞错误
    throw e;
  }
};

export const $get = (
  endpoint: string,
  payload?: object,
  options: RemoteRequestOptions = {},
) => {
  options.params = payload;
  return $request(endpoint, {}, options);
};
export const $put = (
  endpoint: string,
  payload?: object,
  options: RemoteRequestOptions = {},
) => {
  options.method = 'PUT';
  return $request(endpoint, payload, options);
};
export const $post = (
  endpoint: string,
  payload?: object,
  options: RemoteRequestOptions = {},
) => {
  options.method = 'POST';
  return $request(endpoint, payload, options);
};
export const $patch = (
  endpoint: string,
  payload?: object,
  options: RemoteRequestOptions = {},
) => {
  options.method = 'PATCH';
  return $request(endpoint, payload, options);
};
export const $delete = (
  endpoint: string,
  payload?: object,
  options: RemoteRequestOptions = {},
) => {
  options.method = 'DELETE';
  options.data = payload;
  return $request(endpoint, payload, options);
};
export const $upload = (
  endpoint: string,
  payload: { [key: string]: any },
  options: RemoteRequestOptions = {},
) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
  return $post(endpoint, formData, options);
};

export const LOGIN_PATH = process.env.REACT_APP_LOGIN_PATH;
export const getLoginHref = () => {
  return `${window.location.protocol}//${window.location.host}${
    LOGIN_PATH || '/login'
  }?redirect=${encodeURIComponent(
    window.location.pathname + window.location.search,
  )}`;
};
export const isInLoginPage = () => {
  // 判断的时候不带 query，避免在开发独立组件的时候缺少登录功能无限跳转
  return window.location.href.includes(
    `${window.location.host}${LOGIN_PATH || '/login'}`,
  );
};
export const isLogin = () => {
  if (!token) {
    token = localStorage.getItem(TOKEN) || '';
  }
  return !!token;
};
export const requestLogin = () => {
  window.location.href = getLoginHref();
};
export const login = (newToken: string) => {
  localStorage.setItem(TOKEN, newToken);
  token = newToken;
  //  document.cookie = `token=${token}; Path=/; expires=Thu, 18 Dec ${new Date().getFullYear() +
  //    1} 12:00:00 UTC`;
};
export const logout = () => {
  localStorage.removeItem(TOKEN);
  token = '';
  //  document.cookie = `token=removed; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
