import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Clock } from '@/icons/clock/icon.svg';
import { ReactComponent as BackSvg } from '@/icons/back/icon.svg';

import styles from './top-bar.module.less';

/**
 *
 * @param startedAt 开始时间戳
 * @param timeLimit 总时间限制，分钟
 */
const calcLeftTime = (startedAt: string, timeLimit: number) => {
  const now = new Date().getTime();
  const startTime = new Date(startedAt).getTime();
  let leftSeconds = timeLimit * 60 - Math.floor((now - startTime) / 1000);
  if (leftSeconds <= 0) {
    return {
      seconds: '00',
      minutes: '00',
      hours: '',
      timeUp: true,
    };
  }
  const leftHours = Math.floor(leftSeconds / 3600);
  leftSeconds = leftSeconds % 3600;
  const leftMinutes = Math.floor(leftSeconds / 60);
  leftSeconds = leftSeconds % 60;
  return {
    seconds: String(leftSeconds).padStart(2, '0'),
    minutes: String(leftMinutes).padStart(2, '0'),
    hours: leftHours ? String(leftHours).padStart(2, '0') : '',
    timeUp: false,
  };
};

const Timer = ({
  startedAt,
  timeLimit,
  onTimeUp,
}: {
  startedAt: string;
  timeLimit: number;
  onTimeUp?: () => void;
}) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    return calcLeftTime(startedAt, timeLimit);
  });
  useEffect(() => {
    let timer = setInterval(() => {
      const timeLeftRes = calcLeftTime(startedAt, timeLimit);
      if (timeLeftRes.timeUp) {
        clearInterval(timer);
        if (onTimeUp) onTimeUp();
        return;
      }
      setTimeLeft(timeLeftRes);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [startedAt, timeLimit, onTimeUp]);
  return (
    <div className={styles.timer}>
      <Clock /> 倒计时：
      {(timeLeft.hours && `${timeLeft.hours}:`) +
        `${timeLeft.minutes}:${timeLeft.seconds}`}
    </div>
  );
};

type Props = {
  examId: number;
  examTitle: string;
  partTitle: string;
  startedAt: string;
  timeLimit: number;
  onTimeUp?: () => void;
  extraEle?: ReactElement;
  webcamStatus?: string;
  screenStatus?: string;
};

const TopBar = ({
  examId,
  examTitle,
  partTitle,
  startedAt,
  timeLimit,
  onTimeUp,
  extraEle,
  webcamStatus,
  screenStatus,
}: Props) => {
  const history = useHistory();

  return (
    <div className={styles.topBar}>
      <div className={styles.topBarLeft}>
        <div
          className={styles.back}
          onClick={() => {
            history.push(`/cert-exam/${examId}/session`);
          }}
        >
          <BackSvg />
        </div>
        <Timer
          startedAt={startedAt}
          timeLimit={timeLimit}
          onTimeUp={onTimeUp}
        />
        {extraEle}
      </div>
      <div className={styles.title}>
        <div className={styles.mainTitle}>{examTitle}</div>
        <div className={styles.subTitle}>{partTitle}</div>
      </div>
      <div className={styles.status}>
        {webcamStatus === 'consent' ? (
          <div>
            <label className={styles.yes} />
            <span>摄像拍摄中</span>
          </div>
        ) : (
          <div>
            <label className={styles.no} />
            <span>摄像未启用</span>
          </div>
        )}
        {screenStatus === 'consent' ? (
          <div>
            <label className={styles.yes} />
            <span>屏幕录制中</span>
          </div>
        ) : (
          <div>
            <label className={styles.no} />
            <span>屏幕未录制</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
