import { useEffect, useRef } from 'react';
// @ts-ignore
import lifecycle from 'page-lifecycle';
import { debounce } from 'lodash';
import { uuid } from '@/utils';
import useReportEvent from './report-event';
import { getHeaders } from '@/utils/remote';

// 心跳间隔
const ALIVE_DURATION = 60 * 1000;
// const ALIVE_DURATION = 5 * 1000;

// 失活倒计时
const PASSIVE_TIMEOUT = 10 * 60 * 1000;
// const PASSIVE_TIMEOUT = 15 * 1000;

// 状态机：https://www.zhangxinxu.com/wordpress/2021/11/js-visibilitychange-pagehide-lifecycle/
export function usePageSession(
  module?: string, // 如果想区分统计页面内的特定模块，可以加这个参数
  moduleProps?: Record<string, any>,
) {
  const { ready, reportEvent } = useReportEvent();
  const activeRef = useRef(true);
  const activeTimesRef = useRef(1);
  const durationRef = useRef(0);
  const beginTimeRef = useRef(new Date().getTime());
  const sessionRef = useRef(uuid());
  const subSessionRef = useRef(sessionRef.current);

  // 除了关闭页面外，在 SPA 里还可以切换路由，所以需要一个标识避免重复发送结束事件
  const endedRef = useRef(false);

  useEffect(() => {
    if (!ready) return;

    // 进入时开启 session
    reportEvent('pageSessionBegin', {
      module,
      ...moduleProps,
      url: window.location.href,
      urlPath: window.location.pathname,
      uuid: sessionRef.current,
    });

    // 页面存活时，上报心跳
    const timer = setInterval(() => {
      if (!activeRef.current) return;

      durationRef.current += ALIVE_DURATION;
      reportEvent('pageSessionAlive', {
        module,
        ...moduleProps,
        url: window.location.href,
        urlPath: window.location.pathname,
        uuid: sessionRef.current,
        subUuid: subSessionRef.current,
        duration: ALIVE_DURATION,
      });
    }, ALIVE_DURATION);

    // 未变更过的 ref 初始值需要复制一下，不然可能丢失
    const copiedBeginTime = beginTimeRef.current;
    const copiedUuid = sessionRef.current;
    // 如果用户退出登录回到首页，可能会 401
    const copiedHeaders = getHeaders();
    return () => {
      // 取消心跳
      window.clearInterval(timer);

      // 这里只负责 React SPA 页面切换触发的关闭事件
      if (endedRef.current) return;
      const totalDuration = new Date().getTime() - copiedBeginTime;
      reportEvent(
        'pageSessionEnd',
        {
          module,
          ...moduleProps,
          url: window.location.href,
          urlPath: window.location.pathname,
          uuid: copiedUuid,
          subUuid: subSessionRef.current,
          activeTimes: activeTimesRef.current,
          duration: durationRef.current,
          totalDuration,
        },
        copiedHeaders,
      );
      endedRef.current = true;
    };
  }, [module, moduleProps, ready, reportEvent]);

  useEffect(() => {
    if (!ready) return;

    const handleStateChange = function ({ oldState, newState }: any) {
      // 更新页面活跃状态
      if (oldState !== 'active' && newState === 'active') {
        // console.log('页面已激活')
        activeRef.current = true;
        activeTimesRef.current += 1;
        subSessionRef.current = uuid(); // 页面激活时，开启子 session
      } else if (oldState === 'active' && newState !== 'active') {
        // console.log('页面已失活')
        activeRef.current = false;
      }

      // 离开时关闭 session，两个 uuid 都需要
      // 这里只负责浏览器触发的关闭事件
      if (newState === 'terminated' || newState === 'discarded') {
        if (endedRef.current) return;
        const totalDuration = new Date().getTime() - beginTimeRef.current;
        // TODO: 换成 sendBeacon，不然可能会被浏览器干掉
        reportEvent('pageSessionEnd', {
          module,
          ...moduleProps,
          url: window.location.href,
          urlPath: window.location.pathname,
          uuid: sessionRef.current,
          subUuid: subSessionRef.current,
          activeTimes: activeTimesRef.current,
          duration: durationRef.current,
          totalDuration,
        });
        endedRef.current = true;
      }
    };

    lifecycle.addEventListener('statechange', handleStateChange);
    return () =>
      lifecycle.removeEventListener('statechange', handleStateChange);
  }, [module, moduleProps, ready, reportEvent]);

  // 用户移动鼠标后重置定时器
  useEffect(() => {
    let timer: number;
    const setTimer = () => {
      // 用户 10 分钟无操作标记页面失活
      timer = window.setTimeout(() => {
        activeRef.current = false;
      }, PASSIVE_TIMEOUT);
    };
    setTimer();
    const debouncedResetTimer = debounce(() => {
      if (timer) {
        window.clearTimeout(timer);
      }
      setTimer();
    }, 3000);

    const handleMouseMove = () => {
      // 如果页面仍活跃，重置定时器
      if (activeRef.current) {
        debouncedResetTimer();
        return;
      }

      // 用户恢复操作后开启子 session
      activeRef.current = true;
      activeTimesRef.current += 1;
      subSessionRef.current = uuid();
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);
}
