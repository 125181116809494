import React, { useMemo } from 'react';
import { Root, List, Trigger, Content } from '@radix-ui/react-tabs';

import './index.less';

type NormalTab<K = string> = {
  title: string | React.ReactNode;
  key: K;
  content: React.ReactNode;
};

export type Tab<K = any> =
  | { title: string; content: React.ReactNode }
  | NormalTab<K>;

export const Tabs: React.FC<{
  tabs: Tab[];
  defaultKey?: string;
  current?: string;
  onChange?: (key: string) => void;
  tabRight?: React.ReactChild;
}> = ({ tabs, defaultKey, current, onChange, tabRight }) => {
  const normalizedTabs: NormalTab[] = useMemo(() => {
    return tabs.map((t) => {
      if ((t as NormalTab).key) {
        return t as NormalTab;
      } else {
        return {
          ...t,
          key: t.title as string,
        };
      }
    });
  }, [tabs]);
  return (
    <Root defaultValue={defaultKey} value={current} onValueChange={onChange}>
      <div className="qz-tab">
        <List className="qz-tab-triggers">
          {normalizedTabs.map((t) => (
            <Trigger className="qz-tab-trigger" value={t.key} key={t.key}>
              {t.title}
            </Trigger>
          ))}
        </List>
        {tabRight}
      </div>
      {normalizedTabs.map((t) => (
        <Content value={t.key} key={t.key}>
          {t.content}
        </Content>
      ))}
    </Root>
  );
};
