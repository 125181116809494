export const Star = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18.051L5.82021 21.5102L7.20021 14.5637L2 9.75519L9.03321 8.92105L12 2.48979L14.9668 8.92105L22 9.75519L16.7998 14.5637L18.1798 21.5102L12 18.051Z"
        fill="currentColor"
      />
    </svg>
  );
};
