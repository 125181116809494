export const StackIcon = () => {
  return (
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7875 21.6183L29.6245 22.7202C29.7379 22.7881 29.8317 22.8841 29.8968 22.9991C29.9619 23.114 29.9962 23.2438 29.9962 23.3759C29.9962 23.508 29.9619 23.6378 29.8968 23.7527C29.8317 23.8676 29.7379 23.9637 29.6245 24.0315L16.2214 32.0734C15.9837 32.2162 15.7116 32.2917 15.4343 32.2917C15.157 32.2917 14.8849 32.2162 14.6472 32.0734L1.24408 24.0315C1.13075 23.9637 1.03694 23.8676 0.971809 23.7527C0.906674 23.6378 0.872437 23.508 0.872437 23.3759C0.872437 23.2438 0.906674 23.114 0.971809 22.9991C1.03694 22.8841 1.13075 22.7881 1.24408 22.7202L3.08109 21.6183L15.4343 29.0306L27.7875 21.6183V21.6183ZM27.7875 14.4354L29.6245 15.5373C29.7379 15.6051 29.8317 15.7012 29.8968 15.8161C29.9619 15.931 29.9962 16.0608 29.9962 16.1929C29.9962 16.325 29.9619 16.4548 29.8968 16.5697C29.8317 16.6846 29.7379 16.7807 29.6245 16.8485L15.4343 25.3627L1.24408 16.8485C1.13075 16.7807 1.03694 16.6846 0.971809 16.5697C0.906674 16.4548 0.872437 16.325 0.872437 16.1929C0.872437 16.0608 0.906674 15.931 0.971809 15.8161C1.03694 15.7012 1.13075 15.6051 1.24408 15.5373L3.08109 14.4354L15.4343 21.8476L27.7875 14.4354ZM16.2198 0.388788L29.6245 8.43068C29.7379 8.49852 29.8317 8.59459 29.8968 8.70949C29.9619 8.8244 29.9962 8.95423 29.9962 9.08632C29.9962 9.2184 29.9619 9.34823 29.8968 9.46314C29.8317 9.57805 29.7379 9.67411 29.6245 9.74196L15.4343 18.2561L1.24408 9.74196C1.13075 9.67411 1.03694 9.57805 0.971809 9.46314C0.906674 9.34823 0.872437 9.2184 0.872437 9.08632C0.872437 8.95423 0.906674 8.8244 0.971809 8.70949C1.03694 8.59459 1.13075 8.49852 1.24408 8.43068L14.6472 0.388788C14.8849 0.245979 15.157 0.170532 15.4343 0.170532C15.7116 0.170532 15.9837 0.245979 16.2214 0.388788H16.2198ZM15.4343 3.48053L6.09183 9.08632L15.4343 14.6921L24.7768 9.08632L15.4343 3.48053Z"
        fill="#7B6FFF"
      />
    </svg>
  );
};
