import * as H from 'history';

interface IRedirectProps {
  history: H.History<any>;
}

export const goBack = ({ history }: IRedirectProps) => {
  const params = new URLSearchParams(window.location.search);
  // 返回首页作为兜底策略
  let redirectUrl: string = params.get('redirect') || '/';
  if (
    redirectUrl.startsWith('/admin') ||
    redirectUrl.startsWith('/qz-admin') ||
    redirectUrl.startsWith('/dashboard')
  ) {
    window.location.href = redirectUrl;
    return;
  }

  const { REACT_APP_SUB_DIRECTORY } = process.env;
  redirectUrl = redirectUrl.replace(REACT_APP_SUB_DIRECTORY || '', '');
  history.push(redirectUrl);
};
