import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '@/atoms/button';
import { Markdown } from '@/components/markdown';
import { ReactComponent as BackSvg } from '@/icons/back/icon.svg';
import { UserExam, UserExamPaper } from '@/typings/exam';
import { GreenCircleCheck } from '@/icons/circle-check';
import { Loading } from '@/atoms/loading';

import styles from './index.module.less';
import remote from '@/utils/remote';
import { Modal } from '@/atoms/modal';
import { getExamRule } from '@/utils/constants';

type Props = {};

const CertExamSession = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [leftRight, setLeftRight] = useState<boolean>(true);
  const [exam, setExam] = useState<UserExam>();
  const [userExamPaper, setUserExamPaper] = useState<UserExamPaper>();
  const initDate = useCallback(async () => {
    const exam: UserExam = await remote.$get(`/user/exams/${id}`);
    const examPaper: UserExamPaper = await remote.$get(
      `/user/exams/${id}/exam-paper`,
    );
    setExam(exam);
    setUserExamPaper(examPaper);
    if (examPaper?.parts && examPaper.parts.length > 2) {
      setLeftRight(false);
    }
  }, [id]);
  useEffect(() => {
    initDate();
  }, [initDate]);
  useEffect(() => {
    if (exam && exam.userExam.status !== 'ongoing') {
      history.replace(`/cert-exam/${id}`);
    }
  }, [history, id, exam]);
  const examMeta = useMemo(() => {
    if (!userExamPaper) {
      return {
        questionNumber: 0,
        totalScore: 0,
        timeLimit: 0,
        allSubmitted: true,
      };
    }
    const meta = userExamPaper.parts.reduce(
      (pre, part) => {
        const {
          questionNumber = 0,
          totalScore = 0,
          timeLimit = 0,
          status = 'unstarted',
        } = part;
        return {
          questionNumber: pre.questionNumber + Number(questionNumber),
          totalScore: pre.totalScore + Number(totalScore),
          timeLimit: pre.timeLimit + Number(timeLimit),
          allSubmitted: pre.allSubmitted && status === 'submitted',
        };
      },
      {
        questionNumber: 0,
        totalScore: 0,
        timeLimit: 0,
        allSubmitted: true,
      },
    );
    return meta;
  }, [userExamPaper]);

  const examId = exam?.id;
  const [submitting, setSubmitting] = useState(false);
  const [confirmOpen, setconfirmOpen] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (examId) {
      setSubmitting(true);
      await remote.$patch(`/user/exams/${examId}/exam-paper/status`);
      history.replace(`/cert-exam/${examId}`);
    }
  }, [examId, history]);
  if (!exam || !userExamPaper) {
    return <Loading />;
  }

  return (
    <div>
      {submitting && (
        <div className={styles.loading}>
          <Loading />
          试卷提交中
        </div>
      )}
      <div className={styles.topBar}>
        <div
          className={styles.back}
          onClick={() => {
            history.push(`/cert-exam/${id}`);
          }}
        >
          <BackSvg />
        </div>
        <div>{exam.title}</div>
        <div className={styles.extra}></div>
      </div>
      <div className={`${styles.main} ${!leftRight ? styles.topBottom : ''}`}>
        <div className={styles.title}>{exam.title}</div>
        <div className={styles.metaInfo}>
          共{examMeta.questionNumber}道题 满分{examMeta.totalScore}分 时长
          {examMeta.timeLimit}分钟
        </div>
        <div className={styles.body}>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <div></div>
              {userExamPaper.parts.map((p, idx) => (
                <div key={p.id} className={styles.infoRowTitle}>{`第${
                  idx + 1
                }部分`}</div>
              ))}
            </div>
            {/* <div className={styles.infoRow}>
              <div>标题</div>
              {userExamPaper.parts.map((p) => (
                <div key={p.id}>{p.title}</div>
              ))}
            </div> */}
            <div className={styles.infoRow}>
              <div>题目类型</div>
              {userExamPaper.parts.map((p) => (
                <div key={p.id}>{p.questionTypes}</div>
              ))}
            </div>
            <div className={styles.infoRow}>
              <div>题目数量</div>
              {userExamPaper.parts.map((p) => (
                <div key={p.id}>{p.questionNumber}</div>
              ))}
            </div>
            {exam.partsPassScore && (
              <div className={styles.infoRow}>
                <div>通过得分</div>
                {userExamPaper.parts.map((p) => {
                  const partPassScore = exam.partsPassScore?.parts.find(
                    (ps) => p.id === ps.id,
                  )?.passScore;
                  return <div key={p.id}>{partPassScore || 0}</div>;
                })}
              </div>
            )}
            <div className={styles.infoRow}>
              <div>总分</div>
              {userExamPaper.parts.map((p) => (
                <div key={p.id}>{p.totalScore}</div>
              ))}
            </div>
            <div className={styles.infoRow}>
              <div>时间限制</div>
              {userExamPaper.parts.map((p) => (
                <div key={p.id}>限制{p.timeLimit}分钟</div>
              ))}
            </div>
            <div className={styles.infoRow}>
              <div>操作</div>
              {userExamPaper.parts.map((p, idx) => {
                const enterPart = async () => {
                  history.push({
                    pathname: `/cert-exam/${id}/session/part/${p.id}`,
                    state: { title: exam.title, status: p.status },
                  });
                };
                let button;
                if (p.status === 'unstarted') {
                  if (
                    idx === 0 ||
                    userExamPaper.parts[idx - 1].status === 'submitted'
                  ) {
                    button = (
                      <Button size="small" onClick={enterPart}>
                        进入答题
                      </Button>
                    );
                  } else {
                    button = (
                      <Button
                        size="small"
                        disabled
                        title="提交之前的部分以解锁"
                      >
                        未解锁
                      </Button>
                    );
                  }
                } else if (p.status === 'ongoing') {
                  button = (
                    <Button size="small" onClick={enterPart}>
                      继续答题
                    </Button>
                  );
                } else {
                  button = (
                    <Button size="small" variant="sub">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <GreenCircleCheck style={{ marginRight: 5 }} />
                        已提交
                      </div>
                    </Button>
                  );
                }
                return <div key={p.id}>{button}</div>;
              })}
            </div>
          </div>
          <div className={styles.examRule}>
            {exam?.examRule ? (
              <Markdown>
                {exam.examRule?.notices ? exam.examRule.notices : ''}
              </Markdown>
            ) : (
              <Markdown>{getExamRule(exam)}</Markdown>
            )}
          </div>
        </div>

        <div className={styles.submitButton}>
          <Modal
            title="提交试卷"
            open={confirmOpen}
            className={styles.confirmModal}
            onOpenChange={(open) => setconfirmOpen(open)}
          >
            你已完成全部部分，确认要提交试卷吗？
            <div className={styles.confirmButtons}>
              <Button variant="sub" onClick={() => setconfirmOpen(false)}>
                取消
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                  setconfirmOpen(false);
                }}
              >
                提交
              </Button>
            </div>
          </Modal>
          {examMeta.allSubmitted ? (
            <Button outline onClick={() => setconfirmOpen(true)}>
              提交试卷
            </Button>
          ) : (
            <Button disabled title="完成所有部分才可以提交试卷">
              提交试卷
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertExamSession;
