import { Button } from '@/atoms/button';
import styles from './index.module.less';
import { useCallback, useEffect, useState, useRef } from 'react';
import {
  exerciseHistory,
  questionType,
  exerciseType,
} from '@/typings/exercise';
import remote from '@/utils/remote';
import { InputSearch } from '@/atoms/input';
import { Separator } from '@/atoms/separator';
import { throttle } from 'lodash';
import dayjs from 'dayjs';

type Props = {};

const ExerciseHistory = (props: Props) => {
  const [search, setSearch] = useState<string>('');
  const searchRef = useRef('');
  const [exerciseData, setExerciseData] = useState<exerciseType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const hasMoreRef = useRef(false);
  const exerciseRef = useRef<exerciseType[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const limit = 5;

  const getExercise = useCallback(async () => {
    const offsetNum = exerciseRef.current.length;
    const res: exerciseHistory = await remote.$get(
      `/assessment-session-record/history?offset=${offsetNum}&limit=${limit}&content=${searchRef.current}`,
    );

    const newArr = exerciseRef.current.concat(res.exercise);
    exerciseRef.current = newArr;
    setExerciseData(newArr);
    hasMoreRef.current = res.hasMore;
    setHasMore(res.hasMore);
  }, []);

  const handleScroll = async (fu: any) => {
    if (!ref.current) return;
    const scrollTop = document?.documentElement?.scrollTop;
    const windowHeight = window.innerHeight;
    const clientHeight = ref.current.clientHeight;
    if (scrollTop + windowHeight >= clientHeight + 40) {
      if (hasMoreRef.current) {
        await fu();
      }
    }
  };

  const useFn = throttle(() => {
    // 此处调用 加载更多函数
    handleScroll(getExercise);
  }, 500);

  useEffect(() => {
    getExercise();
    window.addEventListener('scroll', useFn);
    return () => {
      window.removeEventListener('scroll', useFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.page} ref={ref}>
      <div className={styles.header}>练习历史</div>
      <div className={styles.exercise}>
        <div>
          <div className={styles['input-search']}>
            <InputSearch
              fullWidth={true}
              placeholder="搜索题干"
              value={search}
              onChange={(e) => {
                searchRef.current = e.target.value;
                setSearch(e.target.value);
              }}
              enterOrButton={() => {
                exerciseRef.current = [];
                getExercise();
              }}
              onDelete={() => {
                searchRef.current = '';
                setSearch('');
                exerciseRef.current = [];
                getExercise();
              }}
              buttonText="搜索历史"
            />
          </div>
          {exerciseData?.length === 0 ? (
            <div
              className={styles.exerciseRow}
              style={{ justifyContent: 'center' }}
            >
              暂无练习记录
            </div>
          ) : (
            <>
              <div className={styles.history}>
                {exerciseData?.map((item) => {
                  return (
                    <div className={styles.item}>
                      <div className={styles['time-tags']}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: 31,
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>{item.title}</span>
                          <span className={styles.time}>
                            {dayjs(item.createdAt).format('YYYY-MM-DD')}
                          </span>
                        </div>
                        {item.type === 'multi_exercise' ? (
                          <div className={styles.tags}>
                            {item.concept.map((tag: string) => {
                              return <span className={styles.tag}>{tag}</span>;
                            })}
                          </div>
                        ) : null}
                      </div>
                      <Separator />
                      <div className={styles.questions}>
                        {item.question.map((question: questionType) => {
                          return (
                            <div className={styles.question}>
                              <span>
                                {dayjs(question.createdAt).format('HH:mm')}
                              </span>
                              <span>
                                {question.type === 'oj-problem'
                                  ? '代码题'
                                  : '选择题'}
                              </span>
                              <div>{question.title}</div>
                              <Button
                                variant="text"
                                onClick={() => {
                                  window.open(
                                    `/exercise/history/${question.assessmentSessionId}/${question.recordId}`,
                                  );
                                }}
                              >
                                查看详情
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.footer}>
                {hasMore ? '加载中...' : '没有更多了...'}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExerciseHistory;
