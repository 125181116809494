/**
 * 为了简化开发，在前后端共享类型之前，所有组件直接用接口返回值（所以需要确保接口返回值足够好用）
 */
import remote from '@/utils/remote';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loading } from '@/atoms/loading';
import { useQuery } from '@/hooks';
import { CoursePanel } from './course-panel';
import { Toc } from './toc';
import defaultBanner from './default-banner.svg';
import Animation from './animation';
import styles from './index.module.less';
import { Exercise } from '@/components/exercise';
import { Dummy } from './dummy';
import { AppContext } from '../../App';
import { Button } from '@/atoms/button';
import { TooltipRemark } from '@/atoms/tooltip/remark';
import { ReactComponent as ServiceSvg } from '@/components/icons/feedback/service.svg';
import { ReactComponent as TextSvg } from '@/components/icons/feedback/text.svg';
import CertExamMark from './cert-exam-mark';

export const LearningPath: React.FC = () => {
  const { id: learningPathId } = useParams<{ id: string }>();
  const [current, setCurrent] = useState({
    courseId: 0,
    unitId: '',
    lessonId: 0,
  });
  const [course, setCourse] = useState<any>({
    detail: null,
    course2Units: {},
    unit2Lessons: {},
    nextLesson: {},
    multiExercises: [],
    unitAndExercises: [],
  });
  // path 接口会省略无用数据和字段，但结构和 course 接口一致
  const [learningPath, setLearningPath] = useState<any>({
    courses: [],
    course2Units: {},
    unit2Lessons: {},
    nextLesson: {},
  });
  const [ui, setUI] = useState({
    loading: false,
    showError: false,
  });

  const [exerciseInfo, setExerciseInfo] = useState<any>({
    globalStatus: '',
    intro: '',
  });

  const [fullState, setFullState] = useState<boolean>(false);
  const history = useHistory();
  const handleFullChange = useCallback((value: boolean) => {
    setFullState(value);
  }, []);
  const { allPath, resetPath, hasPermissionPathList } = useContext(AppContext);

  useEffect(() => {
    (async function () {
      if (hasPermissionPathList?.length && allPath && allPath?.length > 0) {
        const learningData = allPath.filter((item) => {
          return item.userLearning;
        });
        const learningIds = learningData.map((item) => {
          return item.id;
        });
        const currentPath = allPath.find((item) => {
          return String(item.id) === learningPathId;
        }) as any;

        const { isProduced, userLearning } = currentPath || {};
        if (isProduced && !userLearning) {
          remote
            .$post(`/learning-path/${learningPathId}/skippedSurvery`)
            .then(() => {
              if (resetPath) resetPath();
            });
        }
        // 记录上次本次访问的路径地址
        if (
          learningPathId &&
          learningIds.indexOf(Number(learningPathId)) !== -1
        ) {
          localStorage.setItem('learningPathId', learningPathId);
          return;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningPathId, history, allPath]);

  const [pathMeta, setPathMeta] = useState<{
    type: 'adaptive' | 'full';
    canSelect: boolean;
  }>();
  // 根据用户权限显示路径，优先级高于 url query
  useEffect(() => {
    // if (Number(learningPathId) === rpaMeta?.learningPathId) {
    //   setPathMeta({
    //     type: 'full',
    //     canSelect: false,
    //   });
    // } else {
    //   remote.$get(`/learning-path/${learningPathId}/meta`).then((data) => {
    //     setPathMeta(data);
    //   });
    // }
    remote
      .$get(
        `/learning-path/${learningPathId}/meta`,
        {},
        { onServerError: () => {} },
      )
      .then((data) => {
        setPathMeta(data);
      })
      .catch((e) => {
        history.push({
          pathname: '/404',
          state: {
            status: e.response?.status,
            method: e.response?.config.method,
            url: e.response?.config.url,
            requestId: e.response?.headers['x-boyu-request-id'],
            message: e.response?.data.message,
          },
        });
      });
  }, [learningPathId, history]);

  //反馈调研
  useEffect(() => {
    if (!localStorage.getItem('feedback')) {
      const random = Math.round(Math.random());
      if (!random) {
        localStorage.setItem('feedback', '1');
      } else {
        localStorage.setItem('feedback', '2');
      }
    }
  }, []);

  let full = pathMeta?.type === 'full';
  if (pathMeta?.canSelect) {
    full = fullState;
  }

  const getLearningPath = useCallback(() => {
    if (!remote.isLogin()) {
      return;
    }
    return remote
      .$get(`/learning-path/${learningPathId}?full=${full}`, undefined, {
        onServerError: (_, __, error) => {
          if (error.response?.status === 403) {
            setUI({
              loading: false,
              showError: true,
            });
          }
        },
      })
      .then((data: any) => {
        setUI((prev) => ({ ...prev, showError: false }));
        setLearningPath(data);
        return data;
      });
  }, [learningPathId, full]);
  // 用户登录后可访问该页面，获取个性化学习路径
  useEffect(() => {
    setUI((prev) => ({ ...prev, loading: true }));
    getLearningPath()?.then((data) => {
      // 默认打开 nextLesson 的 course
      if (data.nextLesson && data.nextLesson.course) {
        const courseId = data.nextLesson.course.id;
        const unitId = String(data.course2Units[courseId][0]?.id);
        const lessonId = data.unit2Lessons[unitId][0]?.id;
        const pathAndCourseId = localStorage.getItem('pathAndCourseId');
        let pathAndCourse: string[] = [];
        if (pathAndCourseId) pathAndCourse = pathAndCourseId.split('_');
        setCurrent({
          courseId:
            pathAndCourse[0] === learningPathId
              ? Number(pathAndCourse[1])
              : courseId,
          unitId,
          lessonId,
        });
      }
      setUI((prev) => ({ ...prev, loading: false }));
    });
  }, [getLearningPath, history, learningPathId]);

  const getCourse = useCallback(() => {
    if (!current.courseId) return;
    setExerciseInfo({ globalStatus: '', intro: '' });
    const findUnitIndex = (id: string, exercises: any) => {
      const index = exercises.findIndex((item: any) => {
        return id === item.publicId;
      });
      return index;
    };
    let unitAndExercises = JSON.parse(
      JSON.stringify(learningPath.course2Units[current.courseId]),
    ).map((item: any) => {
      return { ...item, publicId: String(item.id) };
    });
    if (learningPath.course2MultiExercises[current.courseId]) {
      learningPath.course2MultiExercises[current.courseId].forEach(
        (item: any) => {
          const index = findUnitIndex(
            String(item.afterUnitId),
            unitAndExercises,
          );
          if (index !== -1) {
            unitAndExercises.splice(index + 1, 0, {
              ...item,
              publicId: `${item.afterUnitId}-${item.id}`,
            });
          }
        },
      );
    }
    const select = learningPath.courses.find(
      (item: any) => item.id === current.courseId,
    );
    setCourse({
      ...learningPath,
      detail: select,
      unitAndExercises: unitAndExercises,
    });
  }, [current.courseId, learningPath]);

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const [exercise, setExercise] = useState<{
    lessonContentId: number;
    title: string;
    learningPathId: number;
    sessionUuid?: string;
    type?: string;
  }>();

  const firstTime = useQuery().get('first-time');
  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    if (firstTime) {
      setAnimation(true);
      setTimeout(() => {
        history.replace({ search: '' });
        setAnimation(false);
      }, 2100);
    }
  }, [firstTime, history]);
  if (animation) {
    return <Animation />;
  }
  if (!pathMeta) {
    return (
      <div className={styles['learning-path-page']}>
        <div className={styles['page-inner']}>
          <Loading />
        </div>
      </div>
    );
  }
  if (ui.showError) {
    return (
      <div className={styles['learning-path-page']}>
        <div className={styles['page-inner']}>
          <div className={styles['error-page']}>
            <p>你没有当前课程的权限。</p>
            <Button
              outline
              onClick={() => {
                history.replace('/');
              }}
            >
              返回首页
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles['learning-path-page']}>
      {/* 练习页面尚未独立，所以把页面埋点区分一下 */}
      {exercise ? (
        <Exercise
          {...exercise}
          exerciseInfo={exerciseInfo}
          learnMode={learningPath.learnMode}
          onClose={() => {
            setExercise(undefined);
            getCourse();
            getLearningPath();
          }}
        />
      ) : (
        <Dummy />
      )}
      <div className={styles['page-inner']}>
        <div
          className={styles.banner}
          style={{ backgroundImage: `url(${defaultBanner})` }}
        >
          <div className={styles['course-title']}>{learningPath.title}</div>
        </div>
        {!ui.loading ? (
          <>
            <div className={styles.content}>
              <Toc
                full={full}
                learningPathId={Number(learningPathId)}
                fullSwitchable={pathMeta.canSelect}
                onFullChange={handleFullChange}
                // selectedCourseId={
                //   current.courseId || learningPath.nextLesson.course?.id
                // }
                // currentUnitId={2}
                // currentLessonId={1}
                courses={learningPath.courses}
                course2Units={learningPath.course2Units}
                unitAndExercises={course.unitAndExercises}
                unit2Lessons={learningPath.unit2Lessons}
                learnMode={learningPath.learnMode}
                current={current}
                onCurrentChange={(courseId, unitId, lessonId) =>
                  setCurrent({
                    courseId,
                    unitId: unitId || '',
                    lessonId: lessonId || 0,
                  })
                }
              />
              <CoursePanel
                full={full}
                learningPathId={Number(learningPathId)}
                learningPath={learningPath}
                course={course.detail}
                course2Units={course.course2Units}
                unitAndExercises={course.unitAndExercises}
                unit2Lessons={course.unit2Lessons}
                lesson2LessonContents={course.lesson2LessonContents}
                learnMode={learningPath.learnMode}
                setExerciseInfo={setExerciseInfo}
                onCurrentChange={(courseId, unitId, lessonId) =>
                  setCurrent({
                    courseId,
                    unitId,
                    lessonId,
                  })
                }
                onStartExercise={(contentId, title, sessionUuid, type) => {
                  // TODO: 改成进入练习页面
                  setExercise({
                    learningPathId: Number(learningPathId),
                    lessonContentId: contentId,
                    title,
                    sessionUuid,
                    type,
                  });
                }}
              />
            </div>
            <div className={styles.rightIcons}>
              <TooltipRemark content="填写平台使用反馈" location="left">
                <TextSvg
                  onClick={() => {
                    if (localStorage.getItem('feedback') === '1') {
                      window.open('https://www.wjx.cn/vj/Q2HFoBA.aspx');
                    } else {
                      window.open('https://www.wjx.cn/vj/PpuZzvr.aspx');
                    }
                  }}
                />
              </TooltipRemark>
              <TooltipRemark
                location="left"
                content={
                  <>
                    <img
                      src="https://staticcdn.boyuai.com/user-assets/14410/A1ABgmM852Q3K6Ut4HQEP9/qqQRcode.png!png"
                      alt="qq二维码"
                    />
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                      扫码咨询
                    </div>
                  </>
                }
              >
                <ServiceSvg />
              </TooltipRemark>
              <CertExamMark />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
