import React, { forwardRef, useState } from 'react';
import './index.less';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'sub' | 'error' | 'text';
  size?: 'small' | 'default';
  outline?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClickCallback?: () => void;
}

export const Button = forwardRef<any, IButtonProps>(
  (
    {
      variant = 'default',
      size = 'default',
      outline = false,
      disabled = false,
      loading = false,
      className = '',
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={'qz-button' + (className ? ` ${className}` : '')}
        data-variant={variant}
        data-size={size}
        data-outline={outline}
        data-disabled={disabled}
        data-loading={loading}
        disabled={disabled}
        {...props}
      />
    );
  },
);

export interface IButtonCallbackProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'sub' | 'error' | 'text';
  size?: 'small' | 'default';
  outline?: boolean;
  disabled?: boolean;
  onClickCallback: () => void;
}

export const ButtonCallback = forwardRef<any, IButtonCallbackProps>(
  (
    {
      variant = 'default',
      size = 'default',
      outline = false,
      disabled = false,
      className = '',
      onClickCallback,
      ...props
    },
    ref,
  ) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
      <button
        ref={ref}
        className={'qz-button' + (className ? ` ${className}` : '')}
        data-variant={variant}
        data-size={size}
        data-outline={outline}
        data-disabled={disabled}
        data-loading={loading}
        disabled={disabled}
        {...props}
        onClick={async () => {
          setLoading(true);
          if (!loading) {
            await onClickCallback();
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }}
      />
    );
  },
);
