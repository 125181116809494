import { useHistory } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { ReactComponent as BackSvg } from '@/icons/back/icon.svg';
import { Button } from '@/atoms/button';
import styles from './webcam.module.less';
import { ReactComponent as PhotoSvg } from '@/icons/webcam/photo.svg';
import { ReactComponent as ConsentSvg } from '@/icons/webcam/consent.svg';
import { ReactComponent as RejectSvg } from '@/icons/webcam/reject.svg';
import { ReactComponent as UnauthorizedSvg } from '@/icons/webcam/unauthorized.svg';

type Props = {
  setWebcamPage: (v: boolean) => void;
  examTitle: string;
  examId: number;
  webcamStatus: string;
  screenStatus: string;
  startRecord: () => void;
  stopRecord: () => void;
  getMediaStream: () => MediaStream | undefined;
};

const Webcam = ({
  setWebcamPage,
  examTitle,
  examId,
  webcamStatus,
  screenStatus,
  startRecord,
  stopRecord,
  getMediaStream,
}: Props) => {
  const previewVideo = useRef<HTMLVideoElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (webcamStatus === 'consent' && previewVideo.current) {
      previewVideo.current.srcObject = getMediaStream() || null;
    }
  }, [webcamStatus, getMediaStream]);

  const fullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  };

  const startExam = async () => {
    setWebcamPage(false);
    fullScreen();
  };

  return (
    <>
      <div className={styles.topBar}>
        <div
          className={styles.back}
          onClick={() => {
            stopRecord();
            history.push(`/cert-exam/${examId}/session`);
          }}
        >
          <BackSvg />
        </div>
        <div>{examTitle}</div>
        <div className={styles.extra}></div>
      </div>
      <div className={styles.main}>
        <h2>摄像头和录屏检查</h2>
        <div className={styles.remark}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保障考试成绩的真实性，我们需要请你授权激活摄像头和屏幕录屏的权限，如果无法开启、不开启摄像头和录屏也可以答题，但可能会影响你最终成绩的判定。
          <br />
          <br />
          MacOS的电脑需要在 系统设置-&gt;安全-&gt;隐私
          中从系统层允许浏览器使用屏幕录制、摄像头、麦克风权限。
        </div>
        <div className={styles.video}>
          {webcamStatus === 'consent' ? (
            <video width="320" height="240" ref={previewVideo} autoPlay />
          ) : (
            <div className={styles.photo}>
              <PhotoSvg />
            </div>
          )}
        </div>
        <div className={styles.status}>
          <div className={styles.webcam}>
            <label>摄像头权限状态：</label>
            {webcamStatus === 'consent' && (
              <div>
                <ConsentSvg />
                <span>正常</span>
              </div>
            )}
            {webcamStatus === 'reject' && (
              <div>
                <RejectSvg />
                <span>授权失败</span>
              </div>
            )}
            {webcamStatus === 'unauthorized' && (
              <div>
                <UnauthorizedSvg />
                <span>未授权</span>
              </div>
            )}
          </div>
          <div>
            <label>录屏权限状态：</label>
            {screenStatus === 'consent' && (
              <div>
                <ConsentSvg />
                <span>正常</span>
              </div>
            )}
            {screenStatus === 'reject' && (
              <div>
                <RejectSvg />
                <span>授权失败</span>
              </div>
            )}
            {screenStatus === 'unauthorized' && (
              <div>
                <UnauthorizedSvg />
                <span>未授权</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.buttons}>
          {webcamStatus === 'consent' && screenStatus === 'consent' ? (
            <Button onClick={startExam}>检查完成，开始答题</Button>
          ) : (
            <Button
              onClick={async () => {
                await startRecord();
              }}
            >
              申请授权
            </Button>
          )}
          <Button
            variant="text"
            className={styles.textButton}
            onClick={startExam}
          >
            跳过检查，继续答题
          </Button>
        </div>
      </div>
    </>
  );
};

export default Webcam;
