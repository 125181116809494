// import { shuffleArray } from '@/utils';
import { loggerMiddleware } from '@/utils/logger';
import {
  AnswerStatus,
  ExerciseRecordType,
  ExerciseResultType,
  ExerciseType,
  LoadingExercise,
  OJExplanationMode,
  OJTab,
  QuizTab,
  RecordOJSubmission,
  RecordStatus,
} from './types';
import React, { useContext, useMemo, useReducer } from 'react';
import { OJConsoleTab, OJFailedTestcase, OJRunSubmission } from '@/typings/oj';

const initExerciseState: LoadingExercise = {
  status: 'loading',
};

export type ExerciseAction =
  | { type: 'init'; data: ExerciseType }
  | {
      type: 'start';
      data: {
        uuid: string;
        firstRecord: ExerciseRecordType;
        customQuestionNum?: number;
      };
    }
  | { type: 'answerChange'; data: { newAnswers?: string[] } }
  | { type: 'resetAnswerStatus' }
  | { type: 'setTab'; data: { tab: QuizTab | OJTab } }
  | {
      type: 'updateQuizAnswers';
      data: string[];
    }
  | {
      type: 'updateViewStatus';
      data: {
        newStatus: 'only-question' | 'show-resources' | 'show-solution';
        solution?: string;
        answers?: string[];
      };
    }
  | { type: 'addNewRecord'; data: { newRecord: ExerciseRecordType } }
  | {
      type: 'submit';
      data: {
        answerStatus: AnswerStatus;
        status: RecordStatus;
        submission?: RecordOJSubmission;
      };
    }
  | { type: 'finishExercise'; data: ExerciseResultType }
  | { type: 'toggleConsole'; data: boolean }
  | { type: 'setConsoleTab'; data: OJConsoleTab }
  | { type: 'setConsoleInput'; data: string }
  | { type: 'setConsoleSubmission'; data: OJRunSubmission }
  | { type: 'setOJExplanationMode'; data?: OJExplanationMode }
  | { type: 'setOJExplanation'; data?: string }
  | { type: 'setConsoleExplanation'; data?: string }
  | { type: 'setOJFailedTestcase'; data?: OJFailedTestcase };

// function shuffleOptionsAndAnswer(record: ExerciseRecordType) {
//   if (record.questionType !== 'quiz-question') {
//     return;
//   }
//   const { question } = record;
//   if (!question.shuffledOptions) {
//     // quiz的选项进行乱序
//     let shuffledOptions = question.options.map((o, idx) => ({
//       ...o,
//       value: String(idx),
//       mark: '',
//     }));
//     shuffleArray(shuffledOptions);
//     shuffledOptions = shuffledOptions.map((o, idx) => ({
//       ...o,
//       mark: String.fromCharCode(65 + idx), // ABCD...
//     }));
//     question.shuffledOptions = shuffledOptions;
//   }

//   if (
//     !question.shuffledAnswers &&
//     question.answers &&
//     question.shuffledOptions
//   ) {
//     question.shuffledAnswers = question.answers.map((a) => {
//       return question.shuffledOptions?.find((so) => so.value === a)!.mark || '';
//     });
//   }
// }

function exerciseReducer(
  state: ExerciseType,
  action: ExerciseAction,
): ExerciseType {
  switch (action.type) {
    case 'init':
      // if (action.data.status === 'ongoing') {
      //   shuffleOptionsAndAnswer(action.data.records[0]);
      // }
      return action.data;

    case 'start':
      if (state.status !== 'unstarted' && state.status !== 'finished') {
        throw new Error('[start] 练习状态错误');
      }
      // shuffleOptionsAndAnswer(action.data.firstRecord);
      const questionNum = action.data.customQuestionNum || state.questionNum;
      return {
        ...state,
        questionNum,
        status: 'ongoing',
        sessionUuid: action.data.uuid,
        current: 0,
        mastery: new Array(questionNum).fill(0),
        records: [action.data.firstRecord],
      };

    case 'answerChange':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          const newAnswers = action.data.newAnswers;
          if (newAnswers) {
            return { ...r, userAnswers: newAnswers };
          } else {
            // 重置
            return { ...r, userAnswers: [], answerStatus: 'unsubmitted' };
          }
        }),
      };

    case 'resetAnswerStatus':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          return {
            ...r,
            answerStatus: 'unsubmitted',
          };
        }),
      };

    case 'setTab':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType === 'oj-problem') {
            return {
              ...r,
              tab: action.data.tab as OJTab,
            };
          }
          return {
            ...r,
            tab: action.data.tab as QuizTab,
          };
        }),
      };

    case 'updateQuizAnswers':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.questionType !== 'quiz-question') {
            return r;
          }
          return {
            ...r,
            question: {
              ...r.question,
              answers: action.data,
            },
          };
        }),
      };

    case 'updateViewStatus':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType === 'quiz-question') {
            const record = {
              ...r,
              viewStatus: action.data.newStatus,
              question: {
                ...r.question,
                answers: action.data.answers,
                solution: action.data.solution,
              },
            };
            // shuffleOptionsAndAnswer(record);
            return record;
          }
          if (r.questionType === 'oj-problem') {
            return {
              ...r,
              viewStatus: action.data.newStatus,
              question: {
                ...r.question,
                answers: action.data.answers,
                solution: action.data.solution,
              },
            };
          }
          return r;
        }),
      };

    case 'addNewRecord':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }

      // shuffleOptionsAndAnswer(action.data.newRecord);
      return {
        ...state,
        current: state.current + 1,
        records: [...state.records, action.data.newRecord],
      };

    case 'submit':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        mastery: state.mastery.map((m, idx) => {
          if (idx !== state.current) return m;
          // 如果本题掌握了，更新 mastery 的值
          return action.data.status === 'mastered' ? 1 : 0;
        }),
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType === 'oj-problem') {
            return {
              ...r,
              answerStatus: action.data.answerStatus,
              status: action.data.status,
              lastSubmission: action.data.submission,
            };
          }
          return {
            ...r,
            answerStatus: action.data.answerStatus,
            status: action.data.status,
          };
        }),
      };

    case 'finishExercise':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        status: 'finished',
        result: action.data,
      };

    case 'toggleConsole':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            consoleVisible: action.data,
          };
        }),
      };

    case 'setConsoleTab':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            consoleTab: action.data,
          };
        }),
      };

    case 'setConsoleInput':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            consoleInput: action.data,
          };
        }),
      };

    case 'setConsoleSubmission':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            consoleSubmission: action.data,
          };
        }),
      };

    case 'setOJExplanation':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            explanation: action.data,
          };
        }),
      };

    case 'setConsoleExplanation':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            consoleExplanation: action.data,
          };
        }),
      };

    case 'setOJExplanationMode':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            explanationMode: action.data,
          };
        }),
      };

    case 'setOJFailedTestcase':
      if (state.status !== 'ongoing') {
        throw new Error('练习不在进行中');
      }
      return {
        ...state,
        records: state.records.map((r) => {
          if (r.index !== state.current) return r;
          if (r.questionType !== 'oj-problem') return r;
          return {
            ...r,
            failedTestcase: action.data,
          };
        }),
      };

    default:
      throw new Error(`[exerciseReducer] action.type ${action} 没有处理`);
  }
}

const exerciseReducerInLogger = loggerMiddleware(
  'exerciseReducer',
  exerciseReducer,
);

const ExerciseContext = React.createContext<{
  state: ExerciseType;
  dispatch: React.Dispatch<ExerciseAction>;
}>({ state: initExerciseState, dispatch: () => {} });

export const ExerciseContextContainer: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    exerciseReducerInLogger,
    initExerciseState,
  );
  return (
    <ExerciseContext.Provider value={{ state, dispatch }}>
      {children}
    </ExerciseContext.Provider>
  );
};

export const useExerciseContext = () => {
  const { state, dispatch } = useContext(ExerciseContext);
  const currentRecord = useMemo(() => {
    if (state.status !== 'ongoing') return null;
    const currentRecord = state.records.find((r) => r.index === state.current);
    return currentRecord;
  }, [state]);

  return { state, dispatch, currentRecord };
};

export const useExerciseContextWithCurrent = () => {
  const { state, dispatch, currentRecord } = useExerciseContext();
  if (!currentRecord) {
    throw new Error('useSolidExerciseContext 必须要有 currentRecord');
  }
  return { state, dispatch, currentRecord };
};
