import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/atoms/button';
import Quiz from '@/components/quiz';
import { PaperQuizPart } from '@/typings/exam';
import { Loading } from '@/atoms/loading';
import remote from '@/utils/remote';

import styles from './random-quiz-part.module.less';
import baseStyles from './base.module.less';

import TopBar from './top-bar';
import SubmitButton from './submit-button';
import dayjs from 'dayjs';
import { limitMaxCountPromise } from '@/utils';
import useReportEvent from '@/hooks/report-event';

type QuizPartStateSection = {
  id: number;
  type: 'SingleChoice' | 'MultiChoice' | 'Judge';
  questionNumber: number;
  scorePerQuestion: number;
  questions: {
    wcsId: number;
    userAnswer?: string[] | null;
    idx: number;
    type: 'select'; // 单选、多选
    title: string;
    hasMultiAnswers?: boolean;
    options: { text: string }[];
  }[];
};

type QuizPartState = {
  current: {
    sectionIdx: number;
    questionIdx: number;
  };
  questionNumber: number;
  typeSections: QuizPartStateSection[];
};

type QuizPartAction =
  | {
      type: 'selectCurrent';
      questionIdx: number;
      sectionIdx: number;
    }
  | {
      type: 'userAnswerChange';
      newUserAnswer: string[];
    };

const initialState: QuizPartState = {
  current: {
    sectionIdx: 0,
    questionIdx: 1,
  },
  questionNumber: 0,
  typeSections: [],
};

function reducer(state: QuizPartState, action: QuizPartAction): QuizPartState {
  switch (action.type) {
    case 'selectCurrent':
      return {
        ...state,
        current: {
          sectionIdx: action.sectionIdx,
          questionIdx: action.questionIdx,
        },
      };
    case 'userAnswerChange':
      return {
        ...state,
        typeSections: state.typeSections.map((ts, tsIdx) => {
          if (tsIdx !== state.current.sectionIdx) {
            return ts;
          }
          return {
            ...ts,
            questions: ts.questions.map((q) => {
              if (q.idx !== state.current.questionIdx) {
                return q;
              }
              return { ...q, userAnswer: action.newUserAnswer };
            }),
          };
        }),
      };
    default:
      throw new Error();
  }
}

type Props = {
  part: PaperQuizPart;
  examId: number;
  examTitle: string;
  webcamStatus: string;
  screenStatus: string;
};

type CertExamQuizPoint = {
  examId: number;
  partId: number;
  typeSectionId: number;
  wcsId: number;
  duration: number;
};

const typeSectionsTitleMap: Record<
  'SingleChoice' | 'MultiChoice' | 'Judge',
  string
> = {
  SingleChoice: '单选题',
  MultiChoice: '多选题',
  Judge: '判断题',
};

const QuizPart = ({
  part,
  examId,
  examTitle,
  webcamStatus,
  screenStatus,
}: Props) => {
  const { ready, reportEvent } = useReportEvent();
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const typeSections: QuizPartStateSection[] = [];
    let questionNumber = 0;
    part.typeSections.forEach((ts) => {
      typeSections.push({
        ...ts,
        questions: ts.questions.map((q, idx) => {
          return { ...q, idx: idx + questionNumber + 1, type: 'select' };
        }),
      });
      questionNumber += ts.questions.length;
    });
    return {
      current: {
        sectionIdx: 0,
        questionIdx: 1,
      },
      questionNumber,
      typeSections,
    };
  });
  const currentSection = state.typeSections[state.current.sectionIdx];
  const currentQuestion = currentSection.questions.find(
    (q) => q.idx === state.current.questionIdx,
  );
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const partId = part.id;

  // 埋点相关
  const currentQuestionStartTime = useRef(new Date());
  useEffect(() => {
    currentQuestionStartTime.current = new Date(); // 进入页面后记录当前时间
  }, []);
  const recordAnswerTime = useCallback(() => {
    const duration = dayjs().diff(currentQuestionStartTime.current, 'seconds');
    const answerTimeList: CertExamQuizPoint[] = JSON.parse(
      localStorage.getItem(`answerTimeList-${examId}-${partId}`) || '[]',
    );
    const current = answerTimeList.find(
      (v: CertExamQuizPoint) => v.wcsId === currentQuestion?.wcsId,
    );
    if (current) {
      // 如果之前答过本题则时间累加
      current.duration += duration;
    } else {
      answerTimeList.push({
        examId,
        partId,
        typeSectionId: currentSection.id,
        wcsId: currentQuestion!.wcsId,
        duration,
      });
    }
    localStorage.setItem(
      `answerTimeList-${examId}-${partId}`,
      JSON.stringify(answerTimeList),
    );
  }, [
    currentQuestion,
    currentQuestionStartTime,
    currentSection.id,
    examId,
    partId,
  ]);
  const submitAnswerTime = useCallback(async () => {
    if (!ready) return;
    const answerTimeList: CertExamQuizPoint[] = JSON.parse(
      localStorage.getItem(`answerTimeList-${examId}-${partId}`) || '[]',
    );
    if (!answerTimeList.length) return;
    await limitMaxCountPromise(
      5,
      answerTimeList.map((v) => reportEvent('quizSubmission', v)),
    );
    localStorage.removeItem(`answerTimeList-${examId}-${partId}`);
  }, [ready, examId, partId, reportEvent]);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);

    recordAnswerTime();
    await submitAnswerTime();
    await remote.$patch(
      `/user/exams/${examId}/exam-paper-parts/${partId}/typeSections/userAnswer`,
      {
        typeSections: state.typeSections.map((ts) => ({
          id: ts.id,
          questions: ts.questions.map((q) => ({
            wcsId: q.wcsId,
            userAnswer: q.userAnswer,
          })),
        })),
      },
    );
    await remote.$patch(
      `/user/exams/${examId}/exam-paper-parts/${partId}/status`,
      {
        status: 'submitted',
      },
    );
    history.replace(`/cert-exam/${examId}/session`);
  }, [
    history,
    examId,
    partId,
    state.typeSections,
    recordAnswerTime,
    submitAnswerTime,
  ]);
  return (
    <div className={baseStyles.wrapper}>
      {submitting && (
        <div className={baseStyles.loading}>
          <Loading />
          试卷提交中
        </div>
      )}
      <TopBar
        examId={examId}
        examTitle={examTitle}
        partTitle={part.title + ` | 部分总分：${part.totalScore}`}
        startedAt={part.startedAt}
        timeLimit={part.timeLimit}
        onTimeUp={handleSubmit}
        webcamStatus={webcamStatus}
        screenStatus={screenStatus}
      />
      <div className={styles.leftNav}>
        <div className={styles.labels}>
          <div className={styles.label}>
            <div className={styles.todoBox}></div>
            未做
          </div>
          <div className={styles.label}>
            <div className={styles.doneBox}></div>已做
          </div>
          <div className={styles.label}>
            <div className={styles.currentBox}></div>当前
          </div>
        </div>
        <div className={styles.navItems}>
          {state.typeSections.map((ts, tsIdx) => {
            return (
              <div key={ts.id}>
                <div className={styles.navSectionTitle}>
                  {typeSectionsTitleMap[ts.type]}
                  <span
                    style={{ fontSize: 12, marginLeft: 5, fontWeight: 500 }}
                  >
                    每题{ts.scorePerQuestion}分
                  </span>
                </div>
                <div className={styles.navSectionItems}>
                  {ts.questions.map((t) => {
                    let className;
                    if (t.userAnswer && t.userAnswer.length > 0) {
                      className = styles.doneBox;
                    } else {
                      className = styles.todoBox;
                    }

                    if (state.current.questionIdx === t.idx) {
                      className = `${className} ${styles.currentBox}`;
                    }

                    return (
                      <div
                        className={className}
                        key={t.wcsId}
                        onClick={() => {
                          recordAnswerTime();
                          currentQuestionStartTime.current = new Date();
                          dispatch({
                            type: 'selectCurrent',
                            sectionIdx: tsIdx,
                            questionIdx: t.idx,
                          });
                        }}
                      >
                        {t.idx}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.body}>
        {currentQuestion && (
          <Quiz
            score={currentSection.scorePerQuestion}
            quiz={currentQuestion}
            title={`题目${currentQuestion.idx}：${
              typeSectionsTitleMap[
                state.typeSections[state.current.sectionIdx].type
              ]
            }`}
            userAnswer={currentQuestion.userAnswer || []}
            onUserAnswersChange={async (newUserAnswer) => {
              await remote.$patch(
                `/user/exams/${examId}/exam-paper-parts/${partId}/typeSections/${currentSection.id}/questions/${currentQuestion.wcsId}/userAnswer`,
                {
                  userAnswer: newUserAnswer,
                },
              );
              dispatch({ type: 'userAnswerChange', newUserAnswer });
            }}
          />
        )}
      </div>
      <div className={baseStyles.footer}>
        <div className={baseStyles.footerSide} style={{ fontWeight: 600 }}>
          {state.current.questionIdx} / {state.questionNumber}
        </div>
        <div className={baseStyles.footerButton}>
          <Button
            outline
            onClick={() => {
              let newCurrent = state.current.questionIdx - 1;
              if (newCurrent >= 1) {
                const newSectionIdx = state.typeSections.findIndex((ts) =>
                  ts.questions.some((q) => q.idx === newCurrent),
                );
                recordAnswerTime();
                currentQuestionStartTime.current = new Date();
                dispatch({
                  type: 'selectCurrent',
                  questionIdx: newCurrent,
                  sectionIdx: newSectionIdx,
                });
              }
            }}
          >
            上一题
          </Button>
          {state.current.questionIdx < state.questionNumber ? (
            <Button
              onClick={() => {
                let newCurrent = state.current.questionIdx + 1;
                if (newCurrent <= state.questionNumber) {
                  const newSectionIdx = state.typeSections.findIndex((ts) =>
                    ts.questions.some((q) => q.idx === newCurrent),
                  );
                  recordAnswerTime();
                  currentQuestionStartTime.current = new Date();
                  dispatch({
                    type: 'selectCurrent',
                    questionIdx: newCurrent,
                    sectionIdx: newSectionIdx,
                  });
                }
              }}
            >
              下一题
            </Button>
          ) : (
            <SubmitButton handleSubmit={handleSubmit} />
          )}
        </div>
        <div className={baseStyles.footerSide}></div>
      </div>
    </div>
  );
};

export default QuizPart;
