import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/atoms/button';
import { Loading } from '@/atoms/loading';
import { Tab, Tabs } from '@/atoms/tabs';

import { PaperOJPart } from '@/typings/exam';

import { RestartSvg } from '@/icons/restart';
import { ReactComponent as CurrentSvg } from './current.svg';

import SubmitButton from './submit-button';
import TopBar from './top-bar';
import SplitterLayout from 'react-splitter-layout';

import baseStyles from './base.module.less';
import styles from './adaptive-oj-part.module.less';
import { Modal } from '@/atoms/modal';
import MonacoEditor, {
  ChangeHandler,
  MonacoEditorProps,
} from 'react-monaco-editor';
import {
  OJConsoleTab,
  OJLanguage,
  OJRunSubmission,
  OJStatus,
  OJStatusText,
  OJQuestionSubmission,
} from '@/typings/oj';
import { OJConsole, OJRunButton } from './adaptive-oj-console';
import { Markdown } from '@/components/markdown';
import { isEmpty, throttle } from 'lodash';
import remote from '@/utils/remote';
import { formatBytes, limitMaxCountPromise, sleep } from '@/utils';
import dayjs from 'dayjs';
import useReportEvent from '@/hooks/report-event';
import { useTypingSequence } from '@/hooks/typing-sequence';
import { AppContext } from '../../../App';

export type OjPartStateSection = {
  id: number;
  type: 'FillOJ' | 'NormalOJ';
  questionNumber: number;
  scorePerQuestion: number;
  questions: {
    problemId: number;
    idx: number;
    title: string;
    description: string;
    inputDescription: string;
    outputDescription: string;
    userAnswer: {
      code: string;
      totalTestPoints: number;
      maxPassedTestPoints: number;
      passedTestPoints: number;
    };
    template?: Record<string, string>;
    samples?: {
      input: string;
      output: string;
    }[];
  }[];
};

type OjPartState = {
  current: {
    sectionIdx: number;
    questionIdx: number;
  };
  questionNumber: number;
  typeSections: OjPartStateSection[];
  ui: {
    tab: 'PROBLEM' | 'RESULT';
    language: OJLanguage;
    consoleVisible?: boolean;
    consoleTab?: OJConsoleTab;
    consoleInput?: string;
    consoleSubmission?: OJRunSubmission; // 运行结果
    lastSubmission?: {
      status: OJStatus;
      timeCost: number; // in ms
      memoryCost: number; // in bytes
      score: number;
      errorInfo: string;
      totalTestPoints: number;
      passedTestPoints: number;
      maxPassedTestPoints: number;
    }; // 提交结果
  };
};

type OjPartAction =
  | {
      type: 'selectCurrent';
      questionIdx: number;
      sectionIdx: number;
    }
  | {
      type: 'userAnswerChange';
      newUserAnswer: string[];
    }
  | { type: 'setTab'; data: 'PROBLEM' | 'RESULT' }
  | { type: 'toggleConsole'; data: boolean }
  | { type: 'setConsoleTab'; data: OJConsoleTab }
  | { type: 'setConsoleInput'; data: string }
  | { type: 'setConsoleSubmission'; data: OJRunSubmission | undefined }
  | { type: 'setCurrentQuestionSubmission'; data: OJQuestionSubmission };

type CertExamOJPoint = {
  examId: number;
  partId: number;
  typeSectionId: number;
  problemId: number;
  duration: number;
};

const initialState: OjPartState = {
  current: {
    sectionIdx: 0,
    questionIdx: 1,
  },
  questionNumber: 0,
  typeSections: [],
  ui: {
    tab: 'PROBLEM',
    language: 'CPP',
  },
};

const cppDefaultTemplate = `#include <bits/stdc++.h>
using namespace std; 

int main() {
    // 请补全代码，实现题目功能

    return 0;
}
`;

const options: MonacoEditorProps['options'] = {
  overviewRulerBorder: false, // 避免拖拽时一直抖动，实在想要可以写个假的固定住
  minimap: {
    enabled: false,
  },
};

const stripOJErrorInfo = (info: string) => {
  const regx = new RegExp('/judger/run/[\\w\\d]+/main.cpp', 'g');
  return info.replace(regx, '/main.cpp');
};

async function* wait4OJSubmitResult(
  examId: number,
  partId: number,
  sectionId: number,
  questionId: number,
  submissionId: string,
) {
  let count = 30;
  while (count >= 0) {
    count -= 1;
    const submission: OJQuestionSubmission = await remote.$get(
      `/user/exams/${examId}/exam-paper-parts/${partId}/typeSections/${sectionId}/questions/${questionId}/submission/${submissionId}`,
    );
    if (submission.errorInfo) {
      submission.errorInfo = stripOJErrorInfo(submission.errorInfo);
    }
    yield submission;
    if (submission.status === OJStatus.JUDGING) {
      await sleep(777);
      continue;
    }
  }
  return null;
}

function reducer(state: OjPartState, action: OjPartAction): OjPartState {
  switch (action.type) {
    case 'selectCurrent':
      return {
        ...state,
        current: {
          sectionIdx: action.sectionIdx,
          questionIdx: action.questionIdx,
        },
        ui: {
          ...state.ui,
          lastSubmission: undefined,
        },
      };
    case 'userAnswerChange':
      return {
        ...state,
        typeSections: state.typeSections.map((ts, tsIdx) => {
          if (tsIdx !== state.current.sectionIdx) {
            return ts;
          }
          return {
            ...ts,
            questions: ts.questions.map((q) => {
              if (q.idx !== state.current.questionIdx) {
                return q;
              }
              return {
                ...q,
                userAnswer: { ...q.userAnswer, code: action.newUserAnswer[0] },
              };
            }),
          };
        }),
      };

    case 'setTab':
      return {
        ...state,
        ui: {
          ...state.ui,
          tab: action.data,
        },
      };
    case 'toggleConsole':
      return {
        ...state,
        ui: {
          ...state.ui,
          consoleVisible: action.data,
        },
      };

    case 'setConsoleTab':
      return {
        ...state,
        ui: {
          ...state.ui,
          consoleTab: action.data,
        },
      };

    case 'setConsoleInput':
      return {
        ...state,
        ui: {
          ...state.ui,
          consoleInput: action.data,
        },
      };

    case 'setConsoleSubmission':
      return {
        ...state,
        ui: {
          ...state.ui,
          consoleSubmission: action.data,
        },
      };

    case 'setCurrentQuestionSubmission':
      return {
        ...state,
        ui: {
          ...state.ui,
          lastSubmission: action.data,
        },
        typeSections: state.typeSections.map((ts, tsIdx) => {
          if (tsIdx !== state.current.sectionIdx) {
            return ts;
          }
          return {
            ...ts,
            questions: ts.questions.map((q) => {
              if (q.idx !== state.current.questionIdx) {
                return q;
              }
              return {
                ...q,
                userAnswer: {
                  code: q.userAnswer?.code || '',
                  totalTestPoints: action.data.totalTestPoints,
                  maxPassedTestPoints: action.data.maxPassedTestPoints,
                  passedTestPoints: action.data.passedTestPoints,
                },
              };
            }),
          };
        }),
      };

    default:
      throw new Error();
  }
}

type Props = {
  part: PaperOJPart;
  examId: number;
  examTitle: string;
  webcamStatus: string;
  screenStatus: string;
};

const OjPart = ({
  part,
  examId,
  examTitle,
  webcamStatus,
  screenStatus,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const typeSections: OjPartStateSection[] = [];
    let questionNumber = 0;
    part.typeSections.forEach((ts) => {
      typeSections.push({
        ...ts,
        questions: ts.questions.map((q, idx) => {
          return { ...q, idx: idx + questionNumber + 1 };
        }),
      });
      questionNumber += ts.questions.length;
    });
    return {
      current: {
        sectionIdx: 0,
        questionIdx: 1,
      },
      questionNumber,
      typeSections,
      ui: {
        tab: 'PROBLEM',
        language: 'CPP',
      } as any,
    };
  });
  const editorRef = useRef<MonacoEditor>(null);
  const [restartModalOpen, toggleRestartModalOpen] = useState(false);

  const history = useHistory();

  const partId = part.id;
  const currentSection = state.typeSections[state.current.sectionIdx];
  const currentQuestion = currentSection?.questions.find(
    (q) => q.idx === state.current.questionIdx,
  );

  // TypingSequence相关
  const { user } = useContext(AppContext);
  const userId = user?.id ?? 0;
  const {
    startCurrentTypingSequence,
    pauseCurrentTypingSequence,
    addTypingRecord,
    submitTypingSequence,
  } = useTypingSequence({ examId, userId, partId });
  const typingRecord = useRef({
    startTime: new Date(),
    endTime: new Date(),
    isChanging: false,
  });
  const answerChange = useCallback(() => {
    if (!typingRecord.current.isChanging) {
      typingRecord.current.isChanging = true;
      typingRecord.current.startTime = new Date();
    }
  }, []);
  // eslint-disable-next-line
  const answerChangeEnd = useCallback(
    throttle((code: string, typeSectionId, questionId) => {
      if (typingRecord.current.isChanging) {
        typingRecord.current.isChanging = false;
        typingRecord.current.endTime = new Date();
        addTypingRecord(
          {
            type: 'UPDATE_ANSWER',
            userAnswer: code,
          },
          {
            typeSectionId,
            questionId,
          },
        );
      }
    }, 500),
    [],
  );

  useEffect(() => {
    startCurrentTypingSequence({
      typeSectionId: currentSection.id,
      questionId: currentQuestion!.problemId,
      defaultCode,
    });
    // eslint-disable-next-line
  }, []);

  // 埋点相关
  const { ready, reportEvent } = useReportEvent();
  const currentQuestionStartTime = useRef(new Date());
  useEffect(() => {
    currentQuestionStartTime.current = new Date(); // 进入页面后记录当前时间
  }, []);
  const recordAnswerTime = useCallback(() => {
    const duration = dayjs().diff(currentQuestionStartTime.current, 'seconds');
    const answerTimeList: CertExamOJPoint[] = JSON.parse(
      localStorage.getItem(`answerTimeList-${examId}-${partId}`) || '[]',
    );
    const current = answerTimeList.find(
      (v: CertExamOJPoint) => v.problemId === currentQuestion?.problemId,
    );
    if (current) {
      // 如果之前答过本题则时间累加
      current.duration += duration;
    } else {
      answerTimeList.push({
        examId,
        partId,
        typeSectionId: currentSection.id,
        problemId: currentQuestion!.problemId,
        duration,
      });
    }
    localStorage.setItem(
      `answerTimeList-${examId}-${partId}`,
      JSON.stringify(answerTimeList),
    );
  }, [
    currentQuestion,
    currentQuestionStartTime,
    currentSection.id,
    examId,
    partId,
  ]);
  const submitAnswerTime = useCallback(async () => {
    if (!ready) return;
    const answerTimeList: CertExamOJPoint[] = JSON.parse(
      localStorage.getItem(`answerTimeList-${examId}-${partId}`) || '[]',
    );
    if (!answerTimeList.length) return;
    await limitMaxCountPromise(
      5,
      answerTimeList.map((v) => reportEvent('ojSubmission', v)),
    );
    localStorage.removeItem(`answerTimeList-${examId}-${partId}`);
  }, [ready, examId, partId, reportEvent]);

  const defaultCode = currentQuestion?.template?.['CPP'] || cppDefaultTemplate;
  let code = defaultCode;
  if (currentQuestion?.userAnswer?.code !== undefined) {
    code = currentQuestion?.userAnswer?.code;
  }

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    recordAnswerTime();
    await submitAnswerTime();
    pauseCurrentTypingSequence({
      typeSectionId: currentSection.id,
      questionId: currentQuestion!.problemId,
    });
    await submitTypingSequence();
    await remote.$patch(
      `/user/exams/${examId}/exam-paper-parts/${partId}/status`,
      {
        status: 'submitted',
      },
    );
    history.replace(`/cert-exam/${examId}/session`);
  }, [
    examId,
    partId,
    currentQuestion,
    currentSection.id,
    history,
    recordAnswerTime,
    pauseCurrentTypingSequence,
    submitAnswerTime,
    submitTypingSequence,
  ]);

  useEffect(() => {
    dispatch({
      type: 'setConsoleInput',
      data: currentQuestion ? currentQuestion.samples![0].input : '',
    });
  }, [currentQuestion]);

  const setTabKey = useCallback(
    (tab: 'PROBLEM' | 'RESULT') => {
      dispatch({ type: 'setTab', data: tab });
    },
    [dispatch],
  );

  const [submitQuestionDisabled, setSubmitQuestionDisabled] = useState(false);
  const submitCurrentQuestion = useCallback(async () => {
    if (!code || !currentSection.id || !currentQuestion!.problemId) {
      return;
    }
    try {
      setSubmitQuestionDisabled(true);
      dispatch({ type: 'userAnswerChange', newUserAnswer: [code] });
      const { id: submissionId }: { id: string } = await remote.$patch(
        `/user/exams/${examId}/exam-paper-parts/${partId}/typeSections/${currentSection.id}/questions/${currentQuestion?.problemId}/userAnswer`,
        {
          language: 'CPP', // TODO: 切换语言
          userAnswer: [code],
        },
      );

      const oj = wait4OJSubmitResult(
        examId,
        partId,
        currentSection.id,
        currentQuestion!.problemId,
        submissionId,
      );
      let submission: OJQuestionSubmission | null = null;
      while (!submission || submission.status === OJStatus.JUDGING) {
        const query = oj.next();
        submission = (await query).value;
        if (submission) {
          if (submission.status === OJStatus.WRONG_ANSWER) {
            if (submission.passedTestPoints > 0) {
              submission.status = OJStatus.PARTRAIL_ACCEPTED;
            }
          }
          dispatch({ type: 'setCurrentQuestionSubmission', data: submission });
          if (submission.status !== OJStatus.JUDGING) {
            addTypingRecord(
              {
                type: 'SUBMIT',
                submitResult: JSON.stringify(submission),
              },
              {
                typeSectionId: currentSection.id,
                questionId: currentQuestion!.problemId,
              },
            );
          }
          setTabKey('RESULT');
        } else {
          dispatch({
            type: 'setCurrentQuestionSubmission',
            data: {
              status: OJStatus.SYSTEM_ERROR,
              timeCost: -1,
              memoryCost: -1,
              score: -1,
              totalTestPoints: -1,
              maxPassedTestPoints: -1,
              passedTestPoints: -1,
              errorInfo: '',
            },
          });
          addTypingRecord(
            {
              type: 'SUBMIT',
              submitResult: '',
            },
            {
              typeSectionId: currentSection.id,
              questionId: currentQuestion!.problemId,
            },
          );
          break;
        }
      }
    } finally {
      setSubmitQuestionDisabled(false);
    }
  }, [
    examId,
    partId,
    currentSection,
    currentQuestion,
    addTypingRecord,
    code,
    setTabKey,
  ]);

  const clearConsoleSubmmison = useCallback(() => {
    dispatch({ type: 'setConsoleTab', data: 'INPUT' });
    dispatch({ type: 'setConsoleSubmission', data: undefined });
  }, []);

  const tabs: Tab<'PROBLEM' | 'RESULT'>[] = [
    {
      key: 'PROBLEM',
      title: '题目',
      content: (
        <div className={styles.tabContent}>
          <div className={styles.recordTitle}>
            题目{currentQuestion?.idx}·
            {currentSection.type === 'NormalOJ' ? '代码题' : '代码填空'}
          </div>
          <div className={styles.title}>{currentQuestion?.title}</div>
          <Markdown className={styles.desc}>
            {currentQuestion?.description || ''}
          </Markdown>
          {!isEmpty(currentQuestion?.inputDescription) && (
            <Markdown className={styles.inputDesc}>
              {`输入描述：${currentQuestion?.inputDescription}`}
            </Markdown>
          )}
          {!isEmpty(currentQuestion?.outputDescription) && (
            <Markdown className={styles.outputDesc}>
              {`输出描述：${currentQuestion?.outputDescription}`}
            </Markdown>
          )}
          {currentQuestion?.samples?.map((s, idx) => (
            <div key={idx} className={styles.samples}>
              <div className={styles.label}>示例 {idx + 1}：</div>
              <div className={styles.sample}>
                <div className={styles.input}>
                  输入：<pre>{isEmpty(s.input) ? '无输入' : s.input}</pre>
                </div>
                <div className={styles.output}>
                  输出：<pre>{s.output}</pre>
                </div>
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const {
    maxPassedTestPoints = 0,
    totalTestPoints = 0,
    passedTestPoints = 0,
  } = currentQuestion?.userAnswer ?? {};
  const historyMaxPassingRate = totalTestPoints
    ? Number((maxPassedTestPoints / totalTestPoints).toFixed(2))
    : 0;

  const lastSubmission = state.ui.lastSubmission;
  let currentPercent: number;
  if (
    lastSubmission?.status === OJStatus.ACCEPTED ||
    lastSubmission?.status === OJStatus.WRONG_ANSWER
  ) {
    currentPercent = Number((passedTestPoints / totalTestPoints).toFixed(2));
  } else {
    currentPercent = 0;
  }
  tabs.push({
    key: 'RESULT',
    title: '提交结果',
    content: (
      <div className={styles.tabContent}>
        {currentQuestion?.userAnswer ? (
          <>
            <div className={styles.resultWord}>
              <div>
                <div>历史最高通过率：</div>
                <div className={styles.resultDesc}>
                  （通过率用于得分的计算）
                </div>
              </div>
              <div className={styles.passRate}>
                {/* {historyMaxPassingRate < 0
                  ? `计算中`
                  : `${historyMaxPassingRate * 100}%`} */}
                {historyMaxPassingRate < 0
                  ? ``
                  : `${historyMaxPassingRate * 100}%`}
              </div>
            </div>
            {lastSubmission && (
              <div className={styles.submissionItem}>
                运行结果：
                <span
                  className={styles.submissionStatus}
                  data-status={lastSubmission.status}
                >
                  {OJStatusText[lastSubmission.status]}
                </span>
              </div>
            )}
            {lastSubmission &&
              (lastSubmission?.status === OJStatus.ACCEPTED ||
                lastSubmission.status === OJStatus.WRONG_ANSWER) && (
                <div
                  className={`${styles.circleChartWrap} ${styles.submissionItem}`}
                >
                  <div>
                    <div>本次运行通过率：</div>
                    <div className={styles.circleChartPassRate}>
                      {currentPercent * 100}%
                    </div>
                  </div>
                  <div>
                    <svg
                      className={styles.suffix}
                      height="80"
                      width="80"
                      viewBox="0 0 20 20"
                    >
                      <circle
                        r="8"
                        cx="10"
                        cy="10"
                        fill="transparent"
                        stroke="var(--system-background)"
                        stroke-width="2"
                      />
                      <circle
                        r="8"
                        cx="10"
                        cy="10"
                        fill="transparent"
                        stroke="var(--primary-blue)"
                        stroke-width="2"
                        stroke-dasharray={`calc(${currentPercent} * 50.272) 50.272`}
                        transform="rotate(-90) translate(-20)"
                      />
                    </svg>
                  </div>
                </div>
              )}
            {lastSubmission && lastSubmission.timeCost > 0 && (
              <div className={styles.submissionItem}>
                运行用时：{lastSubmission.timeCost} ms
              </div>
            )}
            {lastSubmission && lastSubmission.memoryCost > 0 && (
              <div className={styles.submissionItem}>
                内存消耗：{formatBytes(lastSubmission.memoryCost)}
              </div>
            )}
            {lastSubmission && lastSubmission.totalTestPoints > 0 && (
              <div className={styles.submissionItem}>
                通过测试用例：{lastSubmission.passedTestPoints} /{' '}
                {lastSubmission.totalTestPoints}
              </div>
            )}
            {/* 其它错误 */}
            {lastSubmission && lastSubmission.errorInfo && (
              <div className={styles.submissionFailed}>
                <div className={styles.submissionTestCase}>
                  <div className={styles.label}>输出</div>
                  <pre className={styles.data} data-error="true">
                    {lastSubmission.errorInfo}
                  </pre>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.noSubmission}>你还没有提交代码</div>
        )}
      </div>
    ),
  });

  const handleChange: ChangeHandler = useCallback(
    (code, _event) => {
      dispatch({ type: 'userAnswerChange', newUserAnswer: [code] });
      answerChange();
      answerChangeEnd(code, currentSection.id, currentQuestion?.problemId);
    },
    [
      dispatch,
      currentSection.id,
      currentQuestion?.problemId,
      answerChange,
      answerChangeEnd,
    ],
  );

  return (
    <div className={baseStyles.wrapper}>
      {submitting && (
        <div className={baseStyles.loading}>
          <Loading />
          试卷提交中
        </div>
      )}
      <TopBar
        examId={examId}
        examTitle={examTitle}
        partTitle={part.title + ` | 部分总分：${part.totalScore}`}
        startedAt={part.startedAt}
        timeLimit={part.timeLimit}
        onTimeUp={handleSubmit}
        webcamStatus={webcamStatus}
        screenStatus={screenStatus}
      />
      <div className={styles.leftNav}>
        <div className={styles.leftNavTitle}>代码题</div>
        <div className={styles.navItems}>
          {state.typeSections.map((ts, tsIdx) => {
            return (
              <div key={ts.id}>
                <div className={styles.navSectionItems}>
                  {ts.questions.map((t) => {
                    let className;
                    let indicator;
                    let suffix;
                    const { totalTestPoints, maxPassedTestPoints } =
                      t.userAnswer ?? {};
                    if (totalTestPoints >= 0) {
                      if (totalTestPoints === 0) {
                        className = styles.errorBox;
                      } else if (totalTestPoints === maxPassedTestPoints) {
                        className = styles.doneBox;
                      } else {
                        if (maxPassedTestPoints === 0) {
                          className = styles.errorBox;
                        } else {
                          className = styles.warnBox;
                          const percent = maxPassedTestPoints / totalTestPoints;
                          suffix = (
                            <svg
                              className={styles.suffix}
                              height="15"
                              width="15"
                              viewBox="0 0 20 20"
                            >
                              <circle
                                r="10"
                                cx="10"
                                cy="10"
                                fill="var(--system-background)"
                              />
                              <circle
                                r="5"
                                cx="10"
                                cy="10"
                                fill="transparent"
                                stroke="var(--secondary-yellow)"
                                stroke-width="10"
                                stroke-dasharray={`calc(${percent} * 31.42) 31.42`}
                                transform="rotate(-90) translate(-20)"
                              />
                            </svg>
                          );
                        }
                      }
                    } else {
                      className = styles.todoBox;
                    }
                    if (submitQuestionDisabled) {
                      className += ` ${styles.disabledPointer}`;
                    }
                    if (state.current.questionIdx === t.idx) {
                      indicator = <CurrentSvg className={styles.indicator} />;
                    }
                    return (
                      <div
                        className={`${styles.navBox} ${className}`}
                        key={t.problemId}
                        onClick={() => {
                          if (submitQuestionDisabled) return; // 提交过程中禁止切题
                          recordAnswerTime();
                          currentQuestionStartTime.current = new Date();
                          pauseCurrentTypingSequence({
                            typeSectionId: currentSection.id,
                            questionId: currentQuestion!.problemId,
                          });
                          dispatch({
                            type: 'selectCurrent',
                            sectionIdx: tsIdx,
                            questionIdx: t.idx,
                          });
                          startCurrentTypingSequence({
                            typeSectionId: state.typeSections.find(
                              (ts, idx) => idx === tsIdx,
                            )!.id,
                            questionId: state.typeSections
                              .find((ts, idx) => idx === tsIdx)!
                              .questions.find((v) => v.idx === t.idx)!
                              .problemId,
                            defaultCode,
                          });
                          clearConsoleSubmmison();
                          setTabKey('PROBLEM');
                        }}
                      >
                        {t.idx}
                        {indicator}
                        {suffix}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.body}>
        <SplitterLayout
          secondaryInitialSize={(window.innerWidth - 16) / 2}
          customClassName="code-main"
          onSecondaryPaneSizeChange={() => {
            if (!editorRef.current?.editor) return;
            editorRef.current.editor.layout();
          }}
        >
          <div className={styles.leftPanel}>
            <Tabs
              current={state.ui.tab}
              onChange={(key) => {
                setTabKey(key as any);
              }}
              tabs={tabs}
            />
          </div>
          <div className={styles.rightPanel}>
            <div className={styles.editorToolbar}>
              {/* TODO: 由于缺少选择组件，暂时不支持切换语言 */}
              <div></div>
              <div className={styles.rightArea}>
                <RestartSvg
                  className={styles.icon}
                  onClick={() => toggleRestartModalOpen(true)}
                />
                <Modal
                  className={styles.restartModal}
                  title="重置代码"
                  open={restartModalOpen}
                  onOpenChange={(open) => toggleRestartModalOpen(open)}
                >
                  你确认要重置吗？
                  <div className={styles.restartModalBtns}>
                    <Button
                      variant="sub"
                      onClick={() => toggleRestartModalOpen(false)}
                    >
                      取消
                    </Button>
                    <Button
                      onClick={() => {
                        dispatch({
                          type: 'userAnswerChange',
                          newUserAnswer: [defaultCode],
                        });
                        addTypingRecord(
                          {
                            type: 'RESET',
                            userAnswer: defaultCode,
                          },
                          {
                            typeSectionId: currentSection.id,
                            questionId: currentQuestion!.problemId,
                          },
                        );
                        toggleRestartModalOpen(false);
                      }}
                    >
                      确认
                    </Button>
                  </div>
                </Modal>
                <OJRunButton
                  dispatch={dispatch}
                  userAnswer={code}
                  examId={examId}
                  partId={partId}
                  sectionId={currentSection.id}
                  questionId={currentQuestion?.problemId || 0}
                  language={state.ui.language}
                  consoleInput={state.ui.consoleInput || ''}
                  onRunEnd={(submission) => {
                    addTypingRecord(
                      {
                        type: 'TEST',
                        testResult: JSON.stringify(submission),
                      },
                      {
                        typeSectionId: currentSection.id,
                        questionId: currentQuestion?.problemId || 0,
                      },
                    );
                  }}
                />
              </div>
            </div>
            <div className={styles.editorWrapper}>
              <MonacoEditor
                ref={editorRef}
                language="cpp" // TODO: 支持选择
                value={code}
                options={{
                  ...options,
                }}
                onChange={handleChange}
              />
              <OJConsole
                consoleVisible={state.ui.consoleVisible || false}
                consoleInput={state.ui.consoleInput || ''}
                consoleTab={state.ui.consoleTab || ''}
                dispatch={dispatch}
                consoleSubmission={state.ui.consoleSubmission}
              />
            </div>
          </div>
        </SplitterLayout>
      </div>
      <div className={baseStyles.footer}>
        <div className={baseStyles.footerSide} style={{ fontWeight: 600 }}>
          {state.current.questionIdx} / {state.questionNumber}
        </div>
        <div className={baseStyles.footerButton}>
          <Button
            className={submitQuestionDisabled ? styles.disabledPointer : ''}
            outline
            onClick={() => {
              if (submitQuestionDisabled) return;
              let newCurrent = state.current.questionIdx - 1;
              if (newCurrent >= 1) {
                const newSectionIdx = state.typeSections.findIndex((ts) =>
                  ts.questions.some((q) => q.idx === newCurrent),
                );
                recordAnswerTime();
                currentQuestionStartTime.current = new Date();
                pauseCurrentTypingSequence({
                  typeSectionId: currentSection.id,
                  questionId: currentQuestion!.problemId,
                });
                dispatch({
                  type: 'selectCurrent',
                  questionIdx: newCurrent,
                  sectionIdx: newSectionIdx,
                });
                startCurrentTypingSequence({
                  typeSectionId: state.typeSections.find(
                    (ts, idx) => idx === newSectionIdx,
                  )!.id,
                  questionId: state.typeSections
                    .find((ts, idx) => idx === newSectionIdx)!
                    .questions.find((v) => v.idx === newCurrent)!.problemId,
                  defaultCode,
                });
                clearConsoleSubmmison();
                setTabKey('PROBLEM');
              }
            }}
          >
            上一题
          </Button>
          {state.current.questionIdx < state.questionNumber ? (
            <Button
              className={submitQuestionDisabled ? styles.disabledPointer : ''}
              onClick={() => {
                if (submitQuestionDisabled) return;
                let newCurrent = state.current.questionIdx + 1;
                if (newCurrent <= state.questionNumber) {
                  const newSectionIdx = state.typeSections.findIndex((ts) =>
                    ts.questions.some((q) => q.idx === newCurrent),
                  );
                  recordAnswerTime();
                  currentQuestionStartTime.current = new Date();
                  pauseCurrentTypingSequence({
                    typeSectionId: currentSection.id,
                    questionId: currentQuestion!.problemId,
                  });
                  dispatch({
                    type: 'selectCurrent',
                    questionIdx: newCurrent,
                    sectionIdx: newSectionIdx,
                  });
                  startCurrentTypingSequence({
                    typeSectionId: state.typeSections.find(
                      (ts, idx) => idx === newSectionIdx,
                    )!.id,
                    questionId: state.typeSections
                      .find((ts, idx) => idx === newSectionIdx)!
                      .questions.find((v) => v.idx === newCurrent)!.problemId,
                    defaultCode,
                  });
                  clearConsoleSubmmison();
                  setTabKey('PROBLEM');
                }
              }}
            >
              下一题
            </Button>
          ) : (
            <SubmitButton handleSubmit={handleSubmit} />
          )}
        </div>
        <Button
          disabled={submitQuestionDisabled}
          outline
          onClick={submitCurrentQuestion}
        >
          提交本题
        </Button>
      </div>
    </div>
  );
};

export default OjPart;
