import styles from './cert-exam-mark.module.less';
import { Cross } from '@/icons/cross';
import { Link } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';

// dw2期参加考试的用户组
const CertExamGroupIds = [18, 19];
// dw2期考试截至日期
const CertExamEndDate = +new Date('2022-08-08 23:59:59');

if (process.env.NODE_ENV !== 'production') {
  CertExamGroupIds.push(1);
}

const CertExamMark = () => {
  const { user } = useContext(AppContext);

  const needShowMark =
    +new Date() <= CertExamEndDate &&
    CertExamGroupIds.some((id) => user?.groups?.includes(id));
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    const hasShownBanner = localStorage.getItem('hasShownCertExamMarkBanner');
    if (!hasShownBanner) {
      setShowBanner(true);
    }
  }, []);

  const handleClose = () => {
    setShowBanner(false);
    localStorage.setItem('hasShownCertExamMarkBanner', 'true');
  };

  return needShowMark ? (
    <>
      <Link to="/account/exams" target="_blank">
        <div className={styles.wrapper} />
      </Link>
      {showBanner && (
        <div className={styles['banner-wrapper']}>
          <div className={styles.banner}>
            <div onClick={handleClose} className={styles['close-btn']}>
              <Cross />
            </div>
            <Link
              to="/account/exams"
              target="_blank"
              className={styles['link']}
              onClick={handleClose}
            >
              立即前往
            </Link>
          </div>
          <div className={styles['banner-mask']} />
        </div>
      )}
    </>
  ) : null;
};

export default CertExamMark;
