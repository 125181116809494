import { Separator } from '@/atoms/separator';
import { ReactComponent as CheckSvg } from '@/icons/check/icon.svg';
import { ReactComponent as HollowCircleSvg } from '@/icons/hollow-circle/icon.svg';
import { Jump } from '@/icons/jump';
import { ReactComponent as Lock } from '@/icons/lock/icon.svg';
import { ReactComponent as UserPathSvg } from '@/icons/path/icon.a.svg';
import { ReactComponent as FullPathSvg } from '@/icons/path/icon.b.svg';
import { useCallback, useState } from 'react';
import { Tooltip } from '@/atoms/tooltip';

import { LearningStatus } from './status';

import styles from './toc.module.less';

// Table of Content
export const Toc: React.FC<{
  full: boolean;
  learningPathId: number;
  fullSwitchable: boolean;
  onFullChange: (value: boolean) => void;
  unit2Lessons: any;
  unitAndExercises: any;
  courses: any[];
  course2Units: any;
  current: { courseId: number; unitId: string; lessonId: number };
  onCurrentChange: (
    courseId: number,
    unitId?: string,
    lessonId?: number,
  ) => void;
  learnMode: string;
}> = ({
  full,
  learningPathId,
  fullSwitchable,
  onFullChange,
  courses,
  course2Units,
  unit2Lessons,
  unitAndExercises,
  current,
  onCurrentChange,
  learnMode,
}) => {
  const scrollToItem = useCallback(
    (courseId: number, unitId: string, lessonId?: number, type?: string) => {
      let ele;
      let yOffset;
      if (lessonId) {
        yOffset = -80;
        ele = document.getElementById(`lesson-${lessonId}`);
      } else {
        if (type === 'multiExercise') {
          yOffset = -90;
        } else {
          yOffset = -140;
        }
        ele = document.getElementById(`unit-${unitId}`);
      }

      if (ele) {
        const y =
          ele.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y });
      }
      onCurrentChange(courseId, unitId, lessonId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [openCourse, setOpenCourse] = useState<number>(current.courseId);
  const [contentMaxHeight, setContentMaxHeight] = useState<number>(
    window.innerHeight - 400,
  );
  window.addEventListener('resize', () => {
    setContentMaxHeight(window.innerHeight - 400);
  });
  return (
    <div className={styles['toc-wrapper']}>
      {fullSwitchable && (
        <div className={styles['full-switch']}>
          <div className={styles.text}>
            {full ? (
              <FullPathSvg fill="#5A4BFF" />
            ) : (
              <UserPathSvg fill="#2DCED6" />
            )}
            <span>{full ? '完整路径' : '我的路径'}</span>
          </div>
          <span className={styles.switch} onClick={() => onFullChange(!full)}>
            显示{full ? '我的路径' : '完整路径'}
          </span>
        </div>
      )}
      <div className={styles.toc}>
        {courses.map((course, index) => {
          const isCurrent = current.courseId === course.id;
          const units = unitAndExercises || [];
          const finishedUnits = units.filter(
            (unit: any) =>
              unit.status === 'finished' || unit.status === 'skipped',
          );
          const coursePercent = (finishedUnits.length * 100) / units.length;

          let courseStatus = course.status;
          if (learnMode === 'sequential' && courseStatus === 'unstarted') {
            courseStatus = 'ongoing';
          }
          if (!isCurrent || !openCourse) {
            return (
              <div key={course.id}>
                {index > 0 && <Separator />}
                <div
                  className={styles['course-trigger']}
                  onClick={() => {
                    if (course.id !== current.courseId)
                      onCurrentChange(course.id);
                    setOpenCourse(course.id);
                    localStorage.setItem(
                      'pathAndCourseId',
                      `${learningPathId}_${course.id}`,
                    );
                  }}
                >
                  <span className={styles['course-title']}>{course.title}</span>
                  <LearningStatus
                    status={courseStatus}
                    mode={isCurrent ? 'text' : 'icon'}
                    percent={coursePercent}
                  />
                </div>
              </div>
            );
          }
          const formatStringLength = (str: string) => {
            if (str.length > 12) {
              const data = str.slice(0, 12);
              return `${data}...`;
            }
            return '';
          };
          return (
            <div key={course.id}>
              {index > 0 && <Separator />}
              <div className={styles['course-trigger']}>
                {formatStringLength(course.title) ? (
                  <Tooltip content={course.title}>
                    <span
                      className={styles['course-title']}
                      onClick={() => {
                        if (course.id !== current.courseId)
                          onCurrentChange(course.id);
                      }}
                    >
                      {formatStringLength(course.title)}
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    className={styles['course-title']}
                    onClick={() => {
                      if (course.id !== current.courseId)
                        onCurrentChange(course.id);
                    }}
                  >
                    {course.title}
                  </span>
                )}
                <LearningStatus
                  status={courseStatus}
                  mode={isCurrent ? 'text' : 'icon'}
                  percent={coursePercent}
                />
              </div>
              <Separator />
              <div
                className={styles['course-content']}
                style={{ maxHeight: contentMaxHeight }}
              >
                {unitAndExercises?.map((unit: any, index: number) => {
                  const lessons = unit2Lessons[unit.id] || [];
                  const finishedLessons = lessons.filter(
                    (lesson: any) =>
                      lesson.status === 'finished' ||
                      lesson.status === 'skipped',
                  );
                  const isCurrent = unit.publicId === String(current.unitId);
                  const isLast = index === unitAndExercises.length - 1;
                  const withLine = !(!isCurrent && isLast);
                  let unitPercent =
                    (finishedLessons.length * 100) / lessons.length;
                  if (unit.type !== 'unit') unitPercent = 0;

                  return (
                    <div key={unit.publicId}>
                      <div
                        className={styles['unit-title-wrapper']}
                        data-current={isCurrent}
                      >
                        <LearningStatus
                          status={
                            unit.type === 'multiExercise'
                              ? unit.globalStatus
                              : unit.status
                          }
                          mode="icon"
                          percent={
                            unit.globalStatus === 'ongoing' ? 100 : unitPercent
                          }
                          withLine={withLine}
                        />
                        <div
                          className={styles['unit-title']}
                          onClick={() => {
                            scrollToItem(
                              course.id,
                              unit.publicId,
                              undefined,
                              unit.type,
                            );
                          }}
                        >
                          {unit.title}
                        </div>
                      </div>
                      {unit.publicId === String(current.unitId) && (
                        <div className={styles['unit-content']}>
                          {lessons.map((lesson: any) => {
                            let icon;
                            if (lesson.status === 'finished') {
                              icon = <CheckSvg title="已完成" />;
                            } else if (lesson.status === 'skipped') {
                              icon = (
                                <div
                                  style={{
                                    width: 24,
                                    height: 24,
                                    padding: '3px 6px',
                                  }}
                                  title="未解锁"
                                >
                                  <Jump title="已跳过" />
                                </div>
                              );
                            } else if (lesson.status === 'locked') {
                              icon = (
                                <div
                                  style={{
                                    width: 24,
                                    height: 24,
                                    padding: '3px 6px',
                                  }}
                                  title="未解锁"
                                >
                                  <Lock width={12} />
                                </div>
                              );
                            } else {
                              icon = <HollowCircleSvg title="进行中" />;
                            }
                            return (
                              <div
                                key={lesson.id}
                                className={styles['lesson-title-wrapper']}
                                data-current={lesson.id === current.lessonId}
                                onClick={() =>
                                  scrollToItem(
                                    course.id,
                                    unit.publicId,
                                    lesson.id,
                                  )
                                }
                              >
                                {icon}
                                <div className={styles['lesson-title']}>
                                  {lesson.title}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* 用于定位导航重叠的root，开发时如果需要可以解除注释 */}
      {/* <div
    style={{
      position: 'fixed',
      top: 90,
      bottom: 'calc(100vh - 140px)',
      width: '100vw',
      left: 0,
      right: 0,
      zIndex: 99999,
      background: 'red',
      opacity: 0.5,
    }}
  >
    IntersectionObserver root
  </div> */}
    </div>
  );
};
