import React, { FC } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { DialogContentProps } from '@radix-ui/react-dialog';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Cross } from '@/icons/cross';
import './index.less';

export interface ModalProps extends Record<string, any>, DialogContentProps {
  modal?: boolean;
  overlayClassName?: string;
  title?: string;
  desc?: React.ReactNode;
  trigger?: React.ReactNode;
  hideCloseIcon?: boolean;
  open?: boolean;
  width?: number;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
  preventESC?: boolean;
  preventClickOutside?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  forceMount,
  modal = true,
  className,
  overlayClassName,
  trigger,
  title,
  desc,
  hideCloseIcon,
  open,
  width,
  onOpenChange,
  onClose,
  preventESC,
  preventClickOutside,
  ...props
}) => {
  return (
    <Dialog.Root
      modal={modal}
      open={open}
      onOpenChange={(newOpen) => {
        if (onOpenChange) onOpenChange(newOpen);
        if (!newOpen && onClose) onClose();
      }}
    >
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Overlay
        forceMount={forceMount}
        className={`qz-modal-overlay${
          overlayClassName ? ` ${overlayClassName}` : ''
        }`}
      />
      <Dialog.Content
        {...props}
        forceMount={forceMount}
        className={`qz-modal-content${className ? ` ${className}` : ''}`}
        style={{ width }}
        onEscapeKeyDown={(e) => {
          if (preventESC) e.preventDefault();
        }}
        onInteractOutside={(e) => {
          if (preventClickOutside) e.preventDefault();
        }}
      >
        {title && <Dialog.Title>{title}</Dialog.Title>}
        {desc && <Dialog.Description>{desc}</Dialog.Description>}
        {children}
        {!hideCloseIcon && (
          <Dialog.Close className="qz-modal-close">
            <Cross />
          </Dialog.Close>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export const QzNiceModal = NiceModal.create<ModalProps>((props) => {
  const modal = useModal();
  return (
    <Modal
      {...props}
      open={modal.visible}
      onOpenChange={(open) => {
        props.onOpenChange?.(open);
        if (!open) {
          modal.remove();
        }
      }}
    />
  );
});
