import React, { FC } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { DialogContentProps } from '@radix-ui/react-dialog';

import './index.less';

export interface ModalProps extends Record<string, any>, DialogContentProps {
  modal?: boolean;
  overlayClassName?: string;
  title?: string;
  desc?: React.ReactNode;
  trigger?: React.ReactNode;
  hideCloseIcon?: boolean;
  open?: boolean;
  width?: number;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
  preventESC?: boolean;
  preventClickOutside?: boolean;
}

export const Drawer: FC<ModalProps> = ({
  children,
  forceMount,
  modal = true,
  className,
  overlayClassName,
  trigger,
  title,
  desc,
  hideCloseIcon,
  open,
  width,
  onOpenChange,
  onClose,
  preventESC,
  preventClickOutside,
  ...props
}) => {
  return (
    <Dialog.Root
      modal={modal}
      open={open}
      onOpenChange={(newOpen) => {
        if (onOpenChange) onOpenChange(newOpen);
        if (!newOpen && onClose) onClose();
      }}
    >
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Overlay
        forceMount={forceMount}
        className={`qz-drawer-overlay${
          overlayClassName ? ` ${overlayClassName}` : ''
        }`}
      />
      <Dialog.Content
        {...props}
        forceMount={forceMount}
        className={`qz-drawer-content${className ? ` ${className}` : ''}`}
        onEscapeKeyDown={(e) => {
          if (preventESC) e.preventDefault();
        }}
        onInteractOutside={(e) => {
          if (preventClickOutside) e.preventDefault();
        }}
      >
        {children}
      </Dialog.Content>
    </Dialog.Root>
  );
};
