import { ReactComponent as ChecvronLeftSvg } from './icon.svg';

export const ChevronLeft: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return <ChecvronLeftSvg {...props} />;
};

export const ChevronRight: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return <ChecvronLeftSvg transform="rotate(180)" {...props} />;
};

export const ChevronUp: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return <ChevronLeft transform="rotate(90)" {...props} />;
};

export const ChevronDown: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return <ChevronLeft transform="rotate(-90)" {...props} />;
};

export const CircleChevronLeft: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.99992" cy="8.48796" r="6.66667" fill="#B0B2B7" />
      <path
        d="M9.05474 5.42198C9.23327 5.60051 9.23327 5.88995 9.05474 6.06848L6.63513 8.48809L9.05474 10.9077C9.23327 11.0862 9.23327 11.3757 9.05474 11.5542C8.87622 11.7327 8.58677 11.7327 8.40825 11.5542L5.66539 8.81134C5.48686 8.63281 5.48686 8.34336 5.66539 8.16484L8.40825 5.42198C8.58677 5.24345 8.87622 5.24345 9.05474 5.42198Z"
        fill="white"
      />
    </svg>
  );
};
