import TextareaAutosize from 'react-textarea-autosize';
import { SearchIcon, DeleteIcon } from '@/icons/qingzhou';
import { ButtonCallback } from '@/atoms/button';
import { useEffect, useState } from 'react';
import './index.less';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  hasBorder?: boolean;
}

export const Input = ({
  fullWidth,
  hasBorder = true,
  ...props
}: InputProps) => {
  return (
    <div
      className="qz-input"
      data-full-width={fullWidth}
      data-border={hasBorder}
    >
      <input {...props} />
    </div>
  );
};

interface InputSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  hasBorder?: boolean;
  enterOrButton: () => void;
  onDelete: () => void;
  buttonText: string;
}

export const InputSearch = ({
  fullWidth,
  hasBorder = true,
  enterOrButton,
  onDelete,
  buttonText,
  ...props
}: InputSearchProps) => {
  const [deleteShow, setDeleteShow] = useState<boolean>(false);

  useEffect(() => {
    if (props.value) {
      setDeleteShow(true);
    } else {
      setDeleteShow(false);
    }
  }, [props.value]);

  return (
    <div
      className="qz-input qz-input-search"
      data-full-width={fullWidth}
      data-border={hasBorder}
    >
      <span className="qz-input-search-searchSvg">
        <SearchIcon />
      </span>
      <input
        {...props}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            enterOrButton();
          }
        }}
      />
      {deleteShow && (
        <span className="qz-input-search-deleteSvg" onClick={onDelete}>
          <DeleteIcon />
        </span>
      )}
      <ButtonCallback
        onClickCallback={() => {
          enterOrButton();
        }}
      >
        {buttonText}
      </ButtonCallback>
    </div>
  );
};

interface TextareaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  fullWidth?: boolean;
  hasBorder?: boolean;
}

export const Textarea: React.FC<TextareaProps> = ({
  fullWidth,
  hasBorder = true,
  ...props
}) => {
  return (
    <div
      className="qz-input"
      data-full-width={fullWidth}
      data-border={hasBorder}
    >
      <TextareaAutosize
        className="qz-text-area"
        {...(props as any)}
        minRows={3}
        maxRows={10}
      />
    </div>
  );
};
