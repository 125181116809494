import { Tab, Tabs } from '@/atoms/tabs';
import { Markdown } from '@/components/markdown';
import { isEmpty } from 'lodash';
import { Separator } from '@/atoms/separator';
import { ExerciseOJProblem, OJTab } from '../types';
import { useState, useRef } from 'react';
import styles from './oj-viewer.module.less';
import SplitterLayout from 'react-splitter-layout';
import MonacoEditor from 'react-monaco-editor';
import { Loading } from '@/atoms/loading';

interface Props {
  question: ExerciseOJProblem | undefined;
}

const OjViewer = ({ question }: Props) => {
  const editorRef = useRef<MonacoEditor>(null);
  const [tabKey, setTabKey] = useState('PROBLEM');
  if (!question) {
    return <Loading />;
  }
  const cppDefaultTemplate = `#include <bits/stdc++.h>
  using namespace std; 

  int main() {
      // 请补全代码，实现题目功能

      return 0;
  }
  `;
  const code = question.template['CPP'] || cppDefaultTemplate;
  const tabs: Tab<OJTab>[] = [
    {
      key: 'PROBLEM',
      title: '题目',
      // title: OJTabEnum.PROBLEM,
      content: (
        <div className={styles.tabContent}>
          <div className={styles.recordTitle}>代码题</div>
          <div className={styles.title}>{question.title}</div>
          <Markdown className={styles.desc}>{question.description}</Markdown>
          {!isEmpty(question.inputDescription) && (
            <Markdown className={styles.inputDesc}>
              {`输入描述：${question.inputDescription}`}
            </Markdown>
          )}
          {!isEmpty(question.outputDescription) && (
            <Markdown className={styles.outputDesc}>
              {`输出描述：${question.outputDescription}`}
            </Markdown>
          )}
          {question.samples.map((s, idx) => (
            <div key={idx} className={styles.samples}>
              <div className={styles.label}>示例 {idx + 1}：</div>
              <div className={styles.sample}>
                <div className={styles.input}>
                  输入：<pre>{isEmpty(s.input) ? '无输入' : s.input}</pre>
                </div>
                <div className={styles.output}>
                  输出：<pre>{s.output}</pre>
                </div>
              </div>
            </div>
          ))}
          <Separator style={{ marginTop: 32, marginBottom: 16 }} />
        </div>
      ),
    },
  ];

  return (
    <div className={styles.ojPage}>
      <SplitterLayout
        customClassName="code-main"
        onSecondaryPaneSizeChange={() => {
          if (!editorRef.current?.editor) return;
          editorRef.current.editor.layout();
        }}
      >
        <div className={styles.leftPanel}>
          <Tabs
            current={tabKey}
            onChange={(key) => {
              setTabKey(key as OJTab);
            }}
            tabs={tabs}
          />
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.editorToolbar}>
            {/* TODO: 由于缺少选择组件，暂时不支持切换语言 */}
            <div></div>
            <div className={styles.rightArea}></div>
          </div>
          <div className={styles.editorWrapper}>
            <MonacoEditor
              ref={editorRef}
              language="cpp" // TODO: 支持选择
              value={code}
            />
          </div>
        </div>
      </SplitterLayout>
    </div>
  );
};

export default OjViewer;
