import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import NiceModal from '@ebay/nice-modal-react';
import remote, { isLogin } from '@/utils/remote';
import { saveSessionUuid } from '@/services/assessment';
import { isDev } from '@/utils/env';
import { AppContext } from '../../App';
import { Modal, ModalProps, QzNiceModal } from '@/atoms/modal';
import { Button } from '@/atoms/button';
import { RadioGroup } from '@/atoms/radio-group';
import { Checkbox } from '@/atoms/checkbox';
import { Input, Textarea } from '@/atoms/input';
import { pathType, questionsType } from '@/typings/path-exam';

import './index.less';

interface labelProps {
  [name: string]: string;
}
interface answerProps {
  [name: string]: string[];
}
interface Profile {
  stage?:
    | ''
    | 'student'
    | 'coder'
    | 'tech-non-coder'
    | 'other-industry'
    | 'naive'
    | 'other';
  otherStage: string;
  path?: string;
  otherLanguage?: string;
  language: string[];
  cppLevel?: number;
  rpaLevel?: number;
  hrlLevel?: number;
  algorithmLevel?: number;
  school?: string;
  major?: string;
  company?: string;
  job?: string;
  onlineLearningExp?: string;
  assessmentId?: string;
  answer?: labelProps;
}

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  profile: Profile;
  setProfile: React.Dispatch<React.SetStateAction<Profile>>;
  pathData: pathType[];
  resetPath: (() => void) | undefined;
  setPathQuestions: React.Dispatch<React.SetStateAction<questionsType[]>>;
  step: number;
  pathQuestions: questionsType[];
}

const Step1 = ({ setStep }: Props) => {
  return (
    <div className="init-survey" style={{ width: 830 }}>
      <h2>欢迎使用青舟智学</h2>
      <div>
        通过AI算法来衡量你的知识掌握水平，不断优化学习路径，结合优秀的课程与AI老师辅导的练习题，获得更高效的学习体验。
        <br />
        <br />
        帮助实现你设定的目标，为你的职业发展助力！
        {/* 为了能够定制一套属于你的专属学习方案，我们需要对你的情况有一些了解。接下来，你需要简单地介绍一下你自己，同时完成一份学习基础能力测评，之后，平台就会为你生成专属学习路径。下面，我们开始吧~ */}
      </div>
      <div className="welcome-img" />
      <div className="actions">
        <Button
          size="small"
          onClick={async () => {
            if (!isLogin()) {
              sessionStorage.setItem('pannel', 'step2');
              await remote.$get('/learning-path/getAllPathForUser');
            } else {
              setStep(2);
            }
          }}
        >
          下一步
        </Button>
      </div>
    </div>
  );
};

const Step2 = ({
  setStep,
  setProfile,
  profile,
  pathData,
  resetPath,
  setPathQuestions,
}: Props) => {
  const [surveyStatus, setSurveyStatus] = useState<string>('');
  return (
    <div className="init-survey" style={{ width: 1002 }}>
      <h2>请选择你希望的职业技能学习路径</h2>
      <div>
        <RadioGroup
          bordered
          onValueChange={(value) => {
            if (value) {
              const select = pathData.find((item) => String(item.id) === value);
              setSurveyStatus(select?.surveyStatus || '');
              if (select?.assessmentId) {
                setProfile((pre) => ({
                  ...pre,
                  path: value as any,
                  assessmentId: String(select.assessmentId),
                }));
              }
            }
          }}
          className="two-radio-group"
          value={profile.path}
          variant="cards"
          options={pathData.map((o) => {
            return {
              label: (
                <div
                  className={`path-option-wrap ${
                    !o.isProduced || o.userLearning
                      ? 'path-option-disableGray'
                      : ''
                  } ${o.userLearning ? 'path-option-userLearning' : ''}`}
                >
                  <div
                    className="path-option"
                    data-disabled={!o.isProduced || o.userLearning}
                  >
                    <div className="img">
                      <img src={o.bannerUrl} alt={o.title} />
                    </div>
                    <span className="path-option-name">{o.title}</span>
                    <span>{o.intro}</span>
                  </div>
                  <div className="rightTop" />
                </div>
              ),
              disabled: !o.isProduced || o.userLearning,
              value: String(o.id),
            };
          })}
        />
      </div>
      <div className="actions">
        <Button
          size="small"
          onClick={async () => {
            const assessmentId: string = profile.assessmentId || '-1';
            const questions: questionsType[] = await remote.$get(
              `/learning-path/${profile.path}/getSuveryQuestions`,
            );
            if (setPathQuestions) {
              if (questions) {
                setPathQuestions(questions);
                let obj: labelProps = {};
                questions.forEach((item, index) => {
                  obj[index + 1] = '';
                });
                setProfile((pre) => ({ ...pre, answer: obj }));
              } else {
                setPathQuestions(questions);
                setProfile((pre) => ({ ...pre, answer: {} }));
              }
            }
            if (
              !pathData.find((v) => v.id === Number(profile.path))
                ?.hasPermission
            ) {
              NiceModal.show(QzNiceModal, {
                width: 400,
                title: '',
                desc: (
                  <div>
                    <p
                      style={{
                        lineHeight: '24px',
                        textIndent: '2em',
                        marginTop: '26px',
                      }}
                    >
                      目前青舟智学平台开放限时免费体验课程活动，未购买课程的学员可使用微信扫描下方二维码，添加客服领取体验课程。
                    </p>
                    <div style={{ display: 'flex', marginBottom: 16 }}>
                      <div style={{ flex: 1 }}>
                        <Input
                          id="testCodeInput"
                          placeholder="请输入课程激活码"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <Button
                        style={{
                          width: 75,
                          marginLeft: 16,
                          height: 36,
                          lineHeight: '18px',
                        }}
                        onClick={async () => {
                          const inputEl = document.getElementById(
                            'testCodeInput',
                          ) as HTMLInputElement;
                          if (!inputEl.value) {
                            return;
                          }
                          const handleError = () => {
                            inputEl.style.borderColor = 'var(--secondary-red)';
                            document.getElementById(
                              'code-error',
                            )!.style.display = 'block';
                          };
                          remote
                            .$post(
                              '/group-user-relation/addUserByStrode',
                              { code: inputEl.value },
                              {
                                onServerError(_, msg) {
                                  if (msg === '字符码错误') handleError();
                                },
                              },
                            )
                            .then((data: number[]) => {
                              resetPath && resetPath();
                              if (
                                !data.find((v) => v === Number(profile.path))
                              ) {
                                handleError();
                                return;
                              }
                              document.getElementById(
                                'code-error',
                              )!.style.display = 'none';
                              // 加入白名单后继续走流程
                              remote
                                .$post(
                                  '/assessment-session',
                                  { assessmentId },
                                  {
                                    onServerError: () => {},
                                  },
                                )
                                .then((data: any) => {
                                  const { uuid } = data;
                                  setStep(3);
                                  saveSessionUuid(assessmentId, uuid);
                                  NiceModal.hide(QzNiceModal);
                                });
                            });
                        }}
                      >
                        确定
                      </Button>
                    </div>
                    <p id="code-error">激活码错误</p>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        style={{ width: 220 }}
                        alt="qrcode"
                        src="https://staticcdn.boyuai.com/user-assets/14398/WnoBZeBvCNeyeCt4HTvNUu/20220401-182623.png!png"
                      />
                    </div>
                  </div>
                ),
              } as ModalProps);
            } else {
              remote
                .$post(
                  '/assessment-session',
                  {
                    assessmentId,
                  },
                  {
                    onServerError: () => {},
                  },
                )
                .then((data: any) => {
                  const { uuid } = data;
                  remote
                    .$get(`/learning-path/genericSurveyStatus`)
                    .then((res: any) => {
                      // 进行了通用属性调研
                      if (!res?.needGenericSurvey) {
                        // 没有问题,  或没有进行过问题调研
                        if (!questions || surveyStatus === 'finished') {
                          setStep(0);
                        } else {
                          setStep(6);
                        }
                      } else {
                        setStep(3);
                      }
                    });
                  saveSessionUuid(assessmentId, uuid);
                });
            }
          }}
          disabled={!profile.path}
        >
          下一步
        </Button>
      </div>
    </div>
  );
};

const Step3 = ({ profile, setProfile, setStep }: Props) => {
  return (
    <div className="init-survey" style={{ width: 830 }}>
      <h2>你的背景</h2>
      <div>
        <div style={{ marginBottom: 18 }}>你现在是职业生涯的哪个阶段？</div>
        <RadioGroup
          bordered
          onValueChange={(value) =>
            setProfile((pre) => ({
              ...pre,
              stage: value as any,
            }))
          }
          options={[
            {
              label: '我是一名计算机专业在校生，或刚毕业不久',
              value: 'student-cs-major',
            },
            {
              label: '我是一名其他（非计算机）专业在校生，或刚毕业不久',
              value: 'student-non-cs-major',
            },
            {
              label:
                '我从事编程相关岗位的工作，如：软件工程师、算法工程师、数据分析师等',
              value: 'coder-professional',
            },
            {
              label:
                '我学过编程，但从事编程无关岗位的工作，如：管理、财务、行政等',
              value: 'coder-non-professional',
            },
            {
              label: '我对于编程技术不了解，想学习了解一些基本知识',
              value: 'naive-learn-for-fun',
            },
            {
              label: '我对于编程技术不了解，想学习编程解决本职工作的问题',
              value: 'naive-learn-to-use',
            },
            {
              label: (
                <Input
                  placeholder="其他"
                  fullWidth
                  hasBorder={false}
                  value={profile.otherStage}
                  onChange={(e) =>
                    setProfile((pre) => ({
                      ...pre,
                      otherStage: e.target.value,
                    }))
                  }
                />
              ),
              value: 'other',
            },
          ]}
        />
      </div>
      <div className="actions">
        <Button
          size="small"
          variant="text"
          style={{ marginRight: 20, color: 'var(--text-3)' }}
          onClick={() => {
            setProfile((pre) => ({ ...pre, stage: '' }));
            setStep(4);
          }}
        >
          跳过
        </Button>
        <Button
          size="small"
          onClick={() => {
            setStep(4);
          }}
          disabled={!profile?.stage}
        >
          下一步
        </Button>
      </div>
    </div>
  );
};

const Step4Language = ['C++', 'Java', 'Python', 'Go', 'JavaScript'];
const Step4 = ({ profile, setProfile, setStep }: Props) => {
  const onCheckedChange = useCallback(
    (value, checked) => {
      const hasLanguage = profile && profile?.language.indexOf(value) >= 0;
      if (checked) {
        if (!hasLanguage) {
          setProfile((pre) => ({ ...pre, language: [...pre.language, value] }));
        }
      } else {
        if (hasLanguage) {
          setProfile((pre) => ({
            ...pre,
            language: pre.language.filter((l) => l !== value),
          }));
        }
      }
    },
    [setProfile, profile],
  );

  const [hasOther, setHasOther] = useState(profile && !!profile.otherLanguage);
  return (
    <div className="init-survey" style={{ width: 530 }}>
      <h2>编程语言技能</h2>
      <div>
        <div style={{ marginBottom: 18 }}>你是否学习过一些编程语言？</div>
        <div className="lang-grid">
          {Step4Language.map((l) => {
            return (
              <div className="lang-row" key={l}>
                <Checkbox
                  value={l}
                  checked={profile && profile?.language.indexOf(l) >= 0}
                  onCheckedChange={(checked) => onCheckedChange(l, checked)}
                />
              </div>
            );
          })}
        </div>
        <div className="lang-row">
          <Checkbox
            label={'其他'}
            value={'other'}
            checked={hasOther}
            onCheckedChange={(checked) => {
              if (checked) {
                setHasOther(true);
              } else {
                setHasOther(false);
              }
            }}
          />
          {hasOther && (
            <div className="lang-row-input">
              <Input
                type="text"
                name="otherLanguage"
                id="otherLanguage"
                autoComplete="off"
                fullWidth
                placeholder="请输入你学过的其他编程语言，可输入多个"
                value={(profile && profile.otherLanguage) || ''}
                onChange={(e) => {
                  setProfile((pre) => ({
                    ...pre,
                    otherLanguage: e.target.value,
                  }));
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="actions">
        <Button
          size="small"
          variant="text"
          style={{ marginRight: 20, color: 'var(--text-3)' }}
          onClick={() => {
            setProfile((pre) => ({
              ...pre,
              language: [],
            }));
            setStep(5);
          }}
        >
          未学过，跳过
        </Button>
        <Button
          size="small"
          onClick={() => {
            setStep(5);
          }}
          disabled={hasOther && !profile.otherLanguage}
        >
          下一步
        </Button>
      </div>
    </div>
  );
};

const Step5 = ({ profile, setProfile, setStep, pathQuestions }: Props) => {
  const isNaiveForm = (profile?.stage?.indexOf('naive') ?? -1) >= 0;
  const isSchoolForm = (profile?.stage?.indexOf('student') ?? -1) >= 0;
  const isOther = (profile?.stage?.indexOf('other') ?? -1) >= 0;
  let title = isSchoolForm ? '介绍一下你的学习经历' : '介绍一下你的工作经历';
  if (isNaiveForm || isOther) title = '介绍一下你的经历';

  const handleSubmit = async () => {
    const profileData = {
      stage: profile.stage ?? '',
      otherStage: profile.otherStage ?? '',
      language: profile.language ?? '',
      otherLanguage: profile.otherLanguage ?? '',
      school: profile.school ?? '',
      major: profile.major ?? '',
      company: profile.company ?? '',
      job: profile.job ?? '',
      onlineLearningExp: profile.onlineLearningExp ?? '',
    };

    remote
      .$post(`/learning-path/genericSurvey`, {
        profile: profileData,
      })
      .then(() => {
        if (pathQuestions && pathQuestions.length > 0) {
          setStep(6);
        } else {
          setStep(0);
        }
      });
  };
  return (
    <div className="init-survey" style={{ width: 530 }}>
      <h2>{title}</h2>
      <div style={{ marginBottom: 18 }}>
        填写如下信息，能够帮助我们为你提供更好的学习体验。
      </div>
      <div style={{ margin: '5px 0' }}>
        {(isSchoolForm || isNaiveForm || isOther) && (
          <>
            <div className="form-row">
              <h4>学校</h4>
              <Input
                fullWidth
                placeholder="请输入学校中文全称"
                onChange={(e) => {
                  setProfile((pre) => ({ ...pre, school: e.target.value }));
                }}
              ></Input>
            </div>
            <div className="form-row">
              <h4>专业/研究方向</h4>
              <Input
                fullWidth
                placeholder="请输入专业/研究方向"
                onChange={(e) => {
                  setProfile((pre) => ({ ...pre, major: e.target.value }));
                }}
              ></Input>
            </div>
          </>
        )}
        {(!isSchoolForm || isNaiveForm) && (
          <>
            <div className="form-row">
              <h4>公司</h4>
              <Input
                fullWidth
                placeholder="请输入当前所在公司的名称"
                onChange={(e) => {
                  setProfile((pre) => ({ ...pre, company: e.target.value }));
                }}
              ></Input>
            </div>
            <div className="form-row">
              <h4>职务</h4>
              <Input
                fullWidth
                placeholder="请输入当前的职务"
                onChange={(e) => {
                  setProfile((pre) => ({
                    ...pre,
                    job: e.target.value,
                  }));
                }}
              ></Input>
            </div>
          </>
        )}

        {!isNaiveForm && (
          <div className="form-row">
            <h4>在线课程学习经历</h4>
            <Textarea
              fullWidth
              placeholder="请输入你学习过的编程相关课程名称（可填多项）"
              onChange={(e) => {
                setProfile((pre) => ({
                  ...pre,
                  onlineLearningExp: e.target.value,
                }));
              }}
            ></Textarea>
          </div>
        )}
      </div>
      <div className="actions">
        <Button
          size="small"
          variant="text"
          style={{ marginRight: 20, color: 'var(--text-3)' }}
          onClick={() => {
            if (pathQuestions && pathQuestions.length > 0) {
              setStep(6);
            } else {
              setStep(0);
            }
          }}
        >
          跳过
        </Button>
        <Button size="small" onClick={handleSubmit}>
          下一步
        </Button>
      </div>
    </div>
  );
};

const Step0 = ({ profile, setStep }: Props) => {
  const history = useHistory();
  return (
    <div className="init-survey" style={{ width: 570 }}>
      <h2>现在让我们开始吧！</h2>
      <div style={{ marginBottom: 18 }}>
        青舟智学将为你准备一个简单的学前测评，并根据测评的结果为你定制学习内容。
      </div>
      <div className="step-7-title">青舟智学如何为你定制学习内容？</div>
      <div className="step-7-desc">
        当测试完成，青舟智学将计算出在课程大纲中你已经掌握的知识点和仍需学习的知识点，并据此为你生成定制的学习内容。
      </div>

      <div className="actions">
        <Button
          size="small"
          onClick={() => {
            setStep(-1);
            history.push(`/exam/${profile.assessmentId}`);
          }}
        >
          开始测试
        </Button>
      </div>
    </div>
  );
};

const Step6 = ({
  profile,
  setProfile,
  step = 6,
  setStep,
  pathQuestions,
}: Props) => {
  const arrData = pathQuestions || [];
  let select = profile.answer || { id: '' };
  const data = arrData[step - 6] || [];
  return (
    <div className="init-survey" style={{ width: 530 }}>
      <h2>{data.title}</h2>
      <div style={{ marginBottom: 18 }}>{data.desc}</div>
      <div>
        <RadioGroup
          bordered
          value={select[step - 5]}
          onValueChange={(value) => {
            select[step - 5] = value;
            setProfile((pre) => ({ ...pre, answer: select }));
          }}
          options={data?.options || []}
        />
      </div>
      <div className="actions">
        <Button
          size="small"
          variant="text"
          style={{ marginRight: 20, color: 'var(--text-3)' }}
          onClick={() => {
            if (step === arrData?.length + 5) {
              setStep(0);
            } else {
              setStep(step + 1);
            }
          }}
        >
          跳过
        </Button>
        <Button
          size="small"
          onClick={() => {
            if (step === arrData?.length + 5) {
              let obj: answerProps = {};
              for (let key in profile.answer) {
                obj[key] = [profile.answer[key]];
              }
              remote
                .$post(
                  `/learning-path/${profile.path}/submitLearningPathSurvey`,
                  {
                    userAnswer: obj,
                  },
                )
                .then(() => {
                  setStep(0);
                });
            } else {
              setStep(step + 1);
            }
          }}
        >
          {step === arrData?.length + 5 ? '提交' : '下一步'}
        </Button>
      </div>
    </div>
  );
};

export const useInitSurvey: () => [
  JSX.Element,
  (args?: {
    defaultPath?: string;
    defaultStep?: number;
    defaultType?: boolean;
  }) => void,
] = () => {
  const [pathData, setPathData] = useState<pathType[]>([]);
  const [pathQuestions, setPathQuestions] = useState<questionsType[]>([]);
  const [step, setStep] = useState(-1);
  const [profile, setProfile] = useState<Profile>({
    language: [],
    otherStage: '',
  });
  const { allPath, resetPath } = useContext(AppContext);

  useEffect(() => {
    if (!allPath) return;
    const noLearning = allPath.filter(
      (item) => !item.userLearning && item.isProduced,
    );
    const userLearning = allPath.filter((item) => item.userLearning);
    const noProduced = allPath.filter((item) => !item.isProduced);
    const newData = noLearning.concat(userLearning, noProduced);
    setPathData(newData);
    // setPathData(newData.splice(0, 5));
  }, [allPath]);

  const allProps = {
    setStep,
    profile,
    setProfile,
    pathData,
    resetPath,
    setPathQuestions,
    step,
    pathQuestions,
  };

  let content;
  if (step === 1) {
    content = <Step1 {...allProps} />;
  } else if (step === 2) {
    content = <Step2 {...allProps} />;
  } else if (step === 3) {
    content = <Step3 {...allProps} />;
  } else if (step === 4) {
    content = <Step4 {...allProps} />;
  } else if (step === 5) {
    content = <Step5 {...allProps} />;
  } else if (step > 5) {
    content = <Step6 {...allProps} />;
  } else if (step === 0) {
    content = <Step0 {...allProps} />;
  }

  const startInitSurvey = useCallback(
    (args?: { defaultPath?: string; defaultStep?: number }) => {
      const { defaultPath, defaultStep } = args || {};
      setStep(defaultStep || 1);
      if (defaultPath) {
        setProfile((pre) => ({ ...pre, path: defaultPath }));
      }
      // remote
      //   .$post(
      //     '/assessment-session',
      //     {
      //       // assessmentId,
      //     },
      //     {
      //       onServerError: () => {},
      //     },
      //   )
      //   .then((data: any) => {
      //     const { uuid } = data;
      //     saveSessionUuid(assessmentId, uuid);
      //   })
      //   .catch((e) => {
      //     const err = e as RemoteError;
      //     if (!err.isAxiosError) throw e;
      //     if (err.response?.status === 403) {
      //       NiceModal.show(QzNiceModal, {
      //         width: 300,
      //         title: '青舟正在内测中',
      //         desc: (
      //           <div>
      //             <p>目前仅供部分用户使用，如有使用需要请联系小助手。</p>
      //             <img
      //               style={{ width: 220 }}
      //               alt="qrcode"
      //               // src="https://staticcdn.boyuai.com/user-assets/6074/fKFS5CCWzqmyCPVCFCRrEd/screenshot-20211228-160545.png"
      //               src="https://staticcdn.boyuai.com/user-assets/6074/ba8kmwjxDpuhqJFyGVFDU6/sjtu.png"
      //             />
      //           </div>
      //         ),
      //       } as ModalProps);
      //     }
      //   });
    },
    [],
  );

  const initSurvey = (
    <Modal
      open={step > -1}
      onClose={() => {
        setStep(-1);
        setProfile({ language: [], otherStage: '' });
      }}
      preventClickOutside={true}
    >
      {content}
      {isDev && (
        <div>
          <Button
            size="small"
            variant="sub"
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log(profile);
            }}
          >
            Print
          </Button>
          <Button
            size="small"
            variant="sub"
            onClick={() => {
              setStep((pre) => Math.round(pre - 1));
            }}
          >
            pre
          </Button>
          <Button
            size="small"
            variant="sub"
            onClick={() => {
              setStep((pre) => Math.round(pre + 1));
            }}
          >
            nxt
          </Button>
        </div>
      )}
    </Modal>
  );

  return [initSurvey, startInitSurvey];
};
