export const Info = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1847_31569)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 19.6934C12.3206 19.6934 14.5462 18.7715 16.1872 17.1305C17.8281 15.4896 18.75 13.264 18.75 10.9434C18.75 8.62271 17.8281 6.39712 16.1872 4.75618C14.5462 3.11523 12.3206 2.19336 10 2.19336C7.67936 2.19336 5.45376 3.11523 3.81282 4.75618C2.17187 6.39712 1.25 8.62271 1.25 10.9434C1.25 13.264 2.17187 15.4896 3.81282 17.1305C5.45376 18.7715 7.67936 19.6934 10 19.6934ZM10 20.9434C12.6522 20.9434 15.1957 19.8898 17.0711 18.0144C18.9464 16.1391 20 13.5955 20 10.9434C20 8.29119 18.9464 5.74766 17.0711 3.87229C15.1957 1.99693 12.6522 0.943359 10 0.943359C7.34784 0.943359 4.8043 1.99693 2.92893 3.87229C1.05357 5.74766 0 8.29119 0 10.9434C0 13.5955 1.05357 16.1391 2.92893 18.0144C4.8043 19.8898 7.34784 20.9434 10 20.9434Z"
          fill="#B0B2B7"
        />
        <path
          d="M11.1624 9.17834L8.29994 9.5371L8.19744 10.0121L8.75994 10.1158C9.12744 10.2033 9.19994 10.3358 9.11994 10.7021L8.19744 15.0371C7.95494 16.1583 8.32869 16.6858 9.20744 16.6858C9.88869 16.6858 10.6799 16.3708 11.0387 15.9383L11.1487 15.4183C10.8987 15.6383 10.5337 15.7258 10.2912 15.7258C9.94744 15.7258 9.82244 15.4846 9.91119 15.0596L11.1624 9.17834Z"
          fill="#B0B2B7"
        />
        <path
          d="M10 7.81836C10.6904 7.81836 11.25 7.25872 11.25 6.56836C11.25 5.878 10.6904 5.31836 10 5.31836C9.30964 5.31836 8.75 5.878 8.75 6.56836C8.75 7.25872 9.30964 7.81836 10 7.81836Z"
          fill="#B0B2B7"
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.943359)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
