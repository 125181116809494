import { useQuery } from '@/hooks';
import { User } from '@/typings';
import NiceModal from '@ebay/nice-modal-react';
import remote from '@/utils/remote';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import { ModalProps, QzNiceModal } from '@/atoms/modal';

export const AuthRedirectPage = () => {
  const query = useQuery();
  const authCode = query.get('authCode');
  const uri = query.get('uri');
  const history = useHistory();
  const { setUser } = useContext(AppContext);
  useEffect(() => {
    (async () => {
      if (authCode && uri) {
        try {
          localStorage.setItem('qz-jwt', decodeURIComponent(authCode!));
          localStorage.setItem('qz-jwt-issued-at', new Date().toISOString());
          const user: User = await remote.$get('/user');
          setUser(user);
          history.replace(decodeURIComponent(uri!));
        } catch (error) {
          NiceModal.show(QzNiceModal, {
            title: '登录失败',
            desc: '用户信息验证失败',
          } as ModalProps);
        }
      } else {
        NiceModal.show(QzNiceModal, {
          title: '登录失败',
          desc: '用户信息验证失败',
        } as ModalProps);
      }
    })();
  }, [authCode, uri, history, setUser]);
  return <div></div>;
};
