import { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import NiceModal from '@ebay/nice-modal-react';
import { ModalProps, QzNiceModal } from '@/atoms/modal';

import styles from './index.module.less';
import { AppContext } from '../../../App';
import remote from '@/utils/remote';

const FileUploader = ({
  onUploaded,
}: {
  onUploaded: (url: string) => void;
}) => {
  const { user } = useContext(AppContext);
  const userId = user?.id;

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const res = await remote.$upload(`/user/avatar/file`, {
        file: file,
      });
      onUploaded(res);
    },
    [onUploaded],
  );

  const onDropRejected = useCallback((fileRejections) => {
    const errorCode: string = fileRejections[0].errors[0].code;
    const errorMap: Record<string, string> = {
      'file-too-large': '文件太大了',
      'file-invalid-type': '文件类型错误',
    };
    let errorMessage = errorMap[errorCode];
    NiceModal.show(QzNiceModal, {
      width: 300,
      title: '无法上传',
      desc: errorMessage || '文件不符合规范',
    } as ModalProps);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
    accept: 'image/gif,image/jpeg,image/jpg,image/png',
    onDropAccepted,
    onDropRejected,
  });

  if (!userId) {
    return null;
  }
  return (
    <div className={styles.uploader}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <div className={styles.cameraShadow}>上传图片</div>
      </div>
    </div>
  );
};

export default FileUploader;
