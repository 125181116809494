import { Switch, Route, Redirect } from 'react-router-dom';
import { NavLayout } from '@/layouts';
import { NotFound } from './error-page/not-found';
import { Landing } from './landing';
import { ExamAssessment } from './exam';
import { CertExam } from './cert-exam';
import { ExamAssessmentSession } from './exam-session';
import AccountExams from './account/exams';
import ExerciseHistory from './exercise-history';
import { LearningPath } from './learning-path';
import { LoginPage } from './login';
import { RedirectPage } from './redirect';
import CertExamSession from './cert-exam-session';
import CertExamSessionPart from './cert-exam-session/part';
import QuestionPage from './question';
import { AuthRedirectPage } from './auth-redirect';

export default function AppRoutes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Landing />
      </Route>
      <Route path="/login">
        <NavLayout loginRequired={false}>
          <LoginPage />
        </NavLayout>
      </Route>
      <Route path="/auth/redirect">
        <AuthRedirectPage />
      </Route>
      <Route path="/exam/:id" exact>
        <NavLayout loginRequired={false}>
          <ExamAssessment />
        </NavLayout>
      </Route>
      <Route path="/exam/:id/session">
        <NavLayout loginRequired={false}>
          <ExamAssessmentSession />
        </NavLayout>
      </Route>
      <Route path="/cert-exam/:id" exact>
        <CertExam />
      </Route>
      <Route path="/cert-exam/:id/session" exact>
        <CertExamSession />
      </Route>
      <Route path="/cert-exam/:id/session/part/:partId" exact>
        <CertExamSessionPart />
      </Route>
      <Route path="/learning-path/:id" exact>
        <NavLayout>
          <LearningPath />
        </NavLayout>
      </Route>
      <Route path="/question/:id/:type" exact>
        <NavLayout>
          <QuestionPage />
          {/* TODO: 练习页面独立出来需要接口支持，保证页面刷新时数据足够 */}
        </NavLayout>
      </Route>
      <Route path="/exercise/history/:sessionId/:recordId" exact>
        <NavLayout>
          <QuestionPage />
          {/* TODO: 练习页面独立出来需要接口支持，保证页面刷新时数据足够 */}
        </NavLayout>
      </Route>
      <Route path="/account/exams" exact>
        <NavLayout>
          <AccountExams />
        </NavLayout>
      </Route>
      <Route path="/exercise-history" exact>
        <NavLayout>
          <ExerciseHistory />
        </NavLayout>
      </Route>
      <Route path="/redirect">
        <RedirectPage />
      </Route>
      <Route path="/404">
        <NotFound />
      </Route>
      <Redirect from="*" to="/404" />
    </Switch>
  );
}
