export const PlayWithoutCircle: React.FC<SVGProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.752 5.939L18.26 12.069C18.3353 12.1131 18.3978 12.1761 18.4412 12.2518C18.4846 12.3275 18.5074 12.4132 18.5074 12.5005C18.5074 12.5878 18.4846 12.6735 18.4412 12.7492C18.3978 12.8249 18.3353 12.8879 18.26 12.932L7.752 19.062C7.67587 19.1064 7.58935 19.1299 7.5012 19.1301C7.41305 19.1304 7.32642 19.1073 7.25007 19.0632C7.17373 19.0191 7.11039 18.9557 7.06647 18.8793C7.02256 18.8028 6.99963 18.7161 7 18.628V6.371C6.99998 6.28302 7.02317 6.19659 7.06723 6.12045C7.11129 6.0443 7.17466 5.98112 7.25094 5.9373C7.32723 5.89347 7.41373 5.87055 7.5017 5.87085C7.58968 5.87115 7.67602 5.89466 7.752 5.939Z"
        fill="#6A6D77"
      />
    </svg>
  );
};
