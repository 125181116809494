import { Button } from '@/atoms/button';
import { Star } from '@/icons/star';
import { useExerciseContext } from './exercise.context';
import { useStartExercise } from './use-start-exercise';
import { ReactComponent as UpSvg } from '@/components/icons/change/up.svg';
import { ReactComponent as DownSvg } from '@/components/icons/change/down.svg';
import { ReactComponent as EqualSvg } from '@/components/icons/change/equal.svg';

import styles from './result.module.less';

const Result = ({
  learningPathId,
  onClose,
  lessonContentId,
  learnMode,
  type,
  exerciseInfo,
}: {
  learningPathId: number;
  lessonContentId: number;
  onClose: () => void;
  learnMode: string;
  type: string;
  exerciseInfo?: { globalStatus: string; intro: string };
}) => {
  const { state, dispatch } = useExerciseContext();
  const { ready, startExercise } = useStartExercise(
    learningPathId,
    lessonContentId,
    type,
  );

  if (state.status !== 'finished') {
    throw new Error('练习尚未结束');
  }
  if (type === 'multi') {
    const promote = state.result?.scores.filter(
      (item) => item.degrees.curr > item.degrees.prev,
    ).length;
    const decline = state.result?.scores.filter(
      (item) => item.degrees.curr < item.degrees.prev,
    ).length;
    const invariant = state.result?.scores.filter(
      (item) => item.degrees.curr === item.degrees.prev,
    ).length;

    const pass = state.result?.currentScore >= 60;
    let message = '';
    if (
      !pass &&
      learnMode === 'sequential' &&
      exerciseInfo?.globalStatus !== 'finished'
    )
      message = '未通过练习，你需要达到60分才能完成综合练习';

    return (
      <div className={styles.multiWrap}>
        <div className={`${styles.result} ${styles.left}`} data-pass={pass}>
          <div className={styles.title} data-pass={pass}>
            <Star />
            <div>完成练习</div>
          </div>
          <div className={styles.data}>
            <div className={styles.dataLeft}>
              <div>得分提升：{promote}个知识点</div>
              <div>得分下降：{decline}个知识点</div>
              <div>得分不变：{invariant}个知识点</div>
              <div>
                <span
                  className={styles.boldText}
                >{`${state.result?.correctQuestions}/${state.result?.totalQuestions}`}</span>{' '}
                题正确
              </div>
            </div>
            <div className={styles.dataRight}>
              <div>
                知识点综合得分：
                <span
                  className={styles.boldText}
                >{`${state.result?.currentScore}/${state.result?.totalScore}`}</span>
              </div>
            </div>
          </div>
          {message && <div className={styles.message}>{message}</div>}

          {pass ? (
            <div>
              <Button
                variant="sub"
                style={{ marginRight: 12 }}
                disabled={!ready}
                onClick={async () => {
                  const { firstRecord, sessionUuid, customQuestionLength } =
                    await startExercise();
                  dispatch({
                    type: 'start',
                    data: {
                      uuid: sessionUuid,
                      firstRecord,
                      customQuestionNum: customQuestionLength,
                    },
                  });
                }}
              >
                再练一遍
              </Button>
              <Button onClick={onClose}>退出练习</Button>
            </div>
          ) : (
            <div>
              <Button
                variant="sub"
                style={{ marginRight: 12 }}
                onClick={onClose}
              >
                退出练习
              </Button>
              <Button
                disabled={!ready}
                onClick={async () => {
                  const { firstRecord, sessionUuid, customQuestionLength } =
                    await startExercise();
                  dispatch({
                    type: 'start',
                    data: {
                      uuid: sessionUuid,
                      firstRecord,
                      customQuestionNum: customQuestionLength,
                    },
                  });
                }}
              >
                再练一遍
              </Button>
            </div>
          )}
          <div className={styles.sub}>你可以通过多次练习来提升知识点得分</div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTitle}>知识点得分变化</div>
          <div className={styles.items}>
            {state.result?.scores.map((item) => {
              return (
                <div className={styles.item}>
                  <span>{item.degrees.name}</span>
                  <div>
                    <span>{item.degrees.prev}</span>
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.496995 0.334885C0.887519 -0.0556389 1.52068 -0.0556389 1.91121 0.334885L7.91121 6.33489C8.30173 6.72541 8.30173 7.35857 7.91121 7.7491L1.91121 13.7491C1.52068 14.1396 0.887519 14.1396 0.496995 13.7491C0.10647 13.3586 0.10647 12.7254 0.496995 12.3349L5.78989 7.04199L0.496995 1.7491C0.10647 1.35857 0.10647 0.72541 0.496995 0.334885Z"
                        fill="#B5B8CB"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.53606 0.334885C7.92658 -0.0556389 8.55975 -0.0556389 8.95027 0.334885L14.9503 6.33489C15.3408 6.72541 15.3408 7.35857 14.9503 7.7491L8.95027 13.7491C8.55975 14.1396 7.92658 14.1396 7.53606 13.7491C7.14553 13.3586 7.14553 12.7254 7.53606 12.3349L12.829 7.04199L7.53606 1.7491C7.14553 1.35857 7.14553 0.72541 7.53606 0.334885Z"
                        fill="#B5B8CB"
                      />
                    </svg>
                    <span>{item.degrees.curr}</span>
                    {item.degrees.curr > item.degrees.prev && <UpSvg />}
                    {item.degrees.curr < item.degrees.prev && <DownSvg />}
                    {item.degrees.curr === item.degrees.prev && <EqualSvg />}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    const firstDegree = state.result.degrees[0];
    const decrease = firstDegree.prev > firstDegree.curr;
    const pass = firstDegree.curr >= 60;
    let message = '恭喜你完成了练习，你的能力变化';
    if (decrease) message = '有点退步，你的能力变化';
    if (!pass && learnMode === 'sequential')
      message = '需要得到至少60分才能解锁下个知识点';
    return (
      <div className={styles.result} data-pass={pass}>
        <div className={styles.title} data-pass={pass}>
          <Star />
          <div>完成练习</div>
        </div>
        <div className={styles.message}>{message}</div>
        <div className={styles.scores}>
          <div className={styles.score}>{firstDegree.prev}</div>

          <svg
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6066 11.7534L5.34043 1.49477C5.08434 1.23868 4.78032 1.03554 4.44573 0.896945C4.11113 0.758349 3.75252 0.687012 3.39035 0.687012C3.02819 0.687012 2.66957 0.758349 2.33498 0.896945C2.00039 1.03554 1.69636 1.23868 1.44028 1.49477L1.40002 1.53503C0.883125 2.05238 0.592773 2.75379 0.592773 3.48511C0.592773 4.21643 0.883125 4.91783 1.40002 5.43518L8.8103 12.8442C8.87499 12.9095 8.91129 12.9976 8.91129 13.0895C8.91129 13.1814 8.87499 13.2696 8.8103 13.3349L1.40002 20.7439C0.883125 21.2612 0.592773 21.9626 0.592773 22.694C0.592773 23.4253 0.883125 24.1267 1.40002 24.644L1.44028 24.6831C1.69628 24.9393 2.00027 25.1426 2.33488 25.2813C2.66949 25.4199 3.02814 25.4913 3.39035 25.4913C3.75256 25.4913 4.11122 25.4199 4.44583 25.2813C4.78043 25.1426 5.08443 24.9393 5.34043 24.6831L15.6079 14.4156C15.9596 14.0618 16.1569 13.5831 16.1567 13.0842C16.1564 12.5854 15.9587 12.1069 15.6066 11.7534Z"
              fill="#D9D9D9"
            />
            <path
              d="M28.8791 11.7534L18.6129 1.49477C18.3569 1.23868 18.0528 1.03554 17.7182 0.896945C17.3837 0.758349 17.025 0.687012 16.6629 0.687012C16.3007 0.687012 15.9421 0.758349 15.6075 0.896945C15.2729 1.03554 14.9689 1.23868 14.7128 1.49477L14.6725 1.53503C14.4163 1.79103 14.213 2.09503 14.0743 2.42963C13.9356 2.76424 13.8643 3.1229 13.8643 3.48511C13.8643 3.84732 13.9356 4.20598 14.0743 4.54059C14.213 4.87519 14.4163 5.17918 14.6725 5.43518L22.0828 12.8442C22.1475 12.9095 22.1838 12.9976 22.1838 13.0895C22.1838 13.1814 22.1475 13.2696 22.0828 13.3349L14.6725 20.7439C14.4163 20.9999 14.213 21.3039 14.0743 21.6385C13.9356 21.9731 13.8643 22.3318 13.8643 22.694C13.8643 23.0562 13.9356 23.4148 14.0743 23.7494C14.213 24.0841 14.4163 24.388 14.6725 24.644L14.7128 24.6831C14.9688 24.9393 15.2728 25.1426 15.6074 25.2813C15.942 25.4199 16.3007 25.4913 16.6629 25.4913C17.0251 25.4913 17.3837 25.4199 17.7183 25.2813C18.053 25.1426 18.3569 24.9393 18.6129 24.6831L28.8804 14.4156C29.2321 14.0618 29.4294 13.5831 29.4292 13.0842C29.4289 12.5854 29.2312 12.1069 28.8791 11.7534Z"
              fill="#D9D9D9"
            />
          </svg>

          <div className={styles.score}>{firstDegree.curr}</div>
        </div>
        {pass ? (
          <div>
            <Button
              variant="sub"
              style={{ marginRight: 12 }}
              disabled={!ready}
              onClick={async () => {
                const { firstRecord, sessionUuid, customQuestionLength } =
                  await startExercise();
                dispatch({
                  type: 'start',
                  data: {
                    uuid: sessionUuid,
                    firstRecord,
                    customQuestionNum: customQuestionLength,
                  },
                });
              }}
            >
              再练一遍
            </Button>
            <Button onClick={onClose}>退出练习</Button>
          </div>
        ) : (
          <div>
            <Button variant="sub" style={{ marginRight: 12 }} onClick={onClose}>
              退出练习
            </Button>
            <Button
              disabled={!ready}
              onClick={async () => {
                const { firstRecord, sessionUuid, customQuestionLength } =
                  await startExercise();
                dispatch({
                  type: 'start',
                  data: {
                    uuid: sessionUuid,
                    firstRecord,
                    customQuestionNum: customQuestionLength,
                  },
                });
              }}
            >
              再练一遍
            </Button>
          </div>
        )}
        <div className={styles.sub}>你可以通过多次练习来提升知识点得分</div>
      </div>
    );
  }
};

export default Result;
