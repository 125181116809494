import remote from '@/utils/remote';
import { useCallback, useEffect, useState } from 'react';
import { startExercise } from './exercise.service';

// free: 自由推题
// sequential: 自适应推题
export type LearnMode = 'free' | 'sequential';
export type ExerciseQuestion = any; // TODO: 确认后端类型

export const useStartExercise = (
  learningPathId: number,
  lessonContentId: number,
  type: string,
) => {
  const [ready, setReady] = useState(false);
  const [learnMode, setLearnMode] = useState<LearnMode>('sequential');
  const [questions, setQuestions] = useState<ExerciseQuestion[] | null>(null);
  const [questionNum, setQuestionNum] = useState();

  useEffect(() => {
    (async function () {
      const type: {
        learnMode: LearnMode;
        canSelect: boolean; // 后续视情况向用户开放
      } = await remote.$get(`/lesson-content/${lessonContentId}/exercise-type`);
      if (type.learnMode === 'free') {
        const { customQuestions } = await remote.$get(
          `/lesson-content/${lessonContentId}/questionList`,
        );
        setQuestions(customQuestions);
      }
      setLearnMode(type.learnMode);
      setReady(true);
    })();
  }, [lessonContentId]);

  useEffect(() => {
    if (!questions || !questions.length) return;
    remote
      .$post(`/lesson-content/${lessonContentId}/question-number`, {
        customQuestions: questions,
      })
      .then((data) => {
        setQuestionNum(data);
      });
  }, [questions, lessonContentId]);

  const start = useCallback(() => {
    if (learnMode === 'free' && questions) {
      return startExercise(learningPathId, type, lessonContentId, questions);
    }
    return startExercise(learningPathId, type, lessonContentId);
  }, [learnMode, lessonContentId, questions, type, learningPathId]);

  return {
    ready,
    learnMode,
    questionNum,
    startExercise: start,
  };
};
