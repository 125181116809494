import * as RTooltip from '@radix-ui/react-tooltip';
import React from 'react';

import './index.less';

export const Tooltip: React.FC<{ content: React.ReactNode }> = ({
  children,
  content,
}) => (
  <RTooltip.Provider>
    <RTooltip.Root delayDuration={0}>
      <RTooltip.Trigger className="qz-tooltip-trigger">
        {children}
      </RTooltip.Trigger>
      <RTooltip.Content className="qz-tooltip-content">
        <RTooltip.Arrow offset={50} fill="var(--system-background)" />
        {content}
      </RTooltip.Content>
    </RTooltip.Root>
  </RTooltip.Provider>
);
