import { CSSProperties, useState } from 'react';
import { Button } from '@/atoms/button';

import styles from './hint.module.less';
import { Modal } from '@/atoms/modal';
import { Link as LinkIcon } from '@/icons/link';
import { Markdown } from '../markdown';
import { Separator } from '@/atoms/separator';
import { useExerciseContextWithCurrent } from './exercise.context';
import { getSolutionAndAnswer, updateViewStatus } from './exercise.service';
import { openExternalLink } from '../../pages/redirect';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

export const HintTrigger: React.FC<{ onTrigger?: () => void }> = ({
  onTrigger,
}) => {
  const { dispatch, currentRecord } = useExerciseContextWithCurrent();

  const answerText = currentRecord.question.hasSolution ? '题解' : '答案';
  const currentRecordStatus = currentRecord.status;
  let needToWarn = true;
  if (currentRecordStatus === 'correct' || currentRecordStatus === 'mastered') {
    needToWarn = false;
  }
  let showSolution = false;
  // 回答过 quiz 题点击直接出答案 || 回答 OJ 题正确后直接出答案
  if (
    (currentRecordStatus !== 'unsubmitted' &&
      currentRecord.questionType === 'quiz-question') ||
    (currentRecord.answerStatus === 'correct' &&
      currentRecord.questionType === 'oj-problem' &&
      currentRecord.viewStatus === 'only-question')
  ) {
    showSolution = true;
  }
  return (
    <Button
      variant="text"
      onClick={async () => {
        if (showSolution || currentRecord.viewStatus === 'show-resources') {
          await updateViewStatus(currentRecord.recordId, 'show-solution');
          const { solution, answers } = await getSolutionAndAnswer(
            currentRecord.recordId,
          );
          dispatch({
            type: 'updateViewStatus',
            data: { newStatus: 'show-solution', solution, answers },
          });
        } else if (currentRecord.viewStatus === 'only-question') {
          await updateViewStatus(currentRecord.recordId, 'show-resources');
          dispatch({
            type: 'updateViewStatus',
            data: { newStatus: 'show-resources' },
          });
        }
        onTrigger?.();
      }}
    >
      {needToWarn ? '不太会？' : ''}
      查看相关知识点或者
      {answerText}
    </Button>
  );
};

const AnswerTrigger = ({
  answerText,
  style,
}: {
  answerText: string;
  style?: CSSProperties;
}) => {
  const { dispatch, currentRecord } = useExerciseContextWithCurrent();
  const [modalOpen, setModalOpen] = useState(false);
  const helpText = `然而，如果你查看了${answerText}，即使之后做对也不会得分。`;

  return (
    <div style={style}>
      <div className={styles.hintTitle}>还是不会</div>
      <Button onClick={() => setModalOpen(true)}>查看{answerText}</Button>
      <div className={styles.warnMessage}>{helpText}</div>
      <Modal
        open={modalOpen}
        title={`查看${answerText}？`}
        onOpenChange={(open) => setModalOpen(open)}
      >
        <div>
          <div className={styles.modalMessage}>{helpText}</div>
          <div className={styles.modalActions}>
            <Button
              size="small"
              variant="sub"
              style={{ marginRight: 10 }}
              onClick={async () => {
                await updateViewStatus(currentRecord.recordId, 'show-solution');
                const { solution, answers } = await getSolutionAndAnswer(
                  currentRecord.recordId,
                );
                dispatch({
                  type: 'updateViewStatus',
                  data: { newStatus: 'show-solution', solution, answers },
                });
                setModalOpen(false);
                setTimeout(() => {
                  const ele = document.getElementById('exercise-answer');
                  if (ele) ele.scrollIntoView({ behavior: 'smooth' });
                }, 1);
              }}
            >
              查看{answerText}
            </Button>
            <Button size="small" onClick={() => setModalOpen(false)}>
              再想一想
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const RelatedConcept: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const { currentRecord } = useExerciseContextWithCurrent();

  return (
    <div style={style}>
      <div className={styles.hintTitle}>相关知识点</div>
      <div className={styles.relatedConcepts}>
        {currentRecord.learningResources &&
          currentRecord.learningResources.map((lr) => (
            <Link
              className={styles.relatedConcept}
              to="#"
              onClick={() => {
                openExternalLink(lr.url);
              }}
              key={lr.id}
            >
              <LinkIcon />
              <div>{lr.title}</div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export const OJHint = () => {
  const { currentRecord } = useExerciseContextWithCurrent();

  const answerText = currentRecord.question.hasSolution ? '题解' : '答案';
  let content;
  if (currentRecord.viewStatus === 'show-resources') {
    content = (
      <>
        <RelatedConcept />
        <AnswerTrigger style={{ marginTop: 48 }} answerText={answerText} />
      </>
    );
  } else if (currentRecord.viewStatus === 'show-solution') {
    // TODO: 支持多语言题解/答案
    const solutionOrAnswer =
      currentRecord.question.solution ||
      `\`\`\`cpp\n${currentRecord.question.answers?.[0]}\n\`\`\``;
    content = (
      <>
        <div>
          <div className={styles.hintTitle}>{answerText}</div>
          {isEmpty(solutionOrAnswer) ? (
            <div className={styles.ojSolution}>暂无{answerText}</div>
          ) : (
            <Markdown className={styles.ojSolution}>
              {solutionOrAnswer!}
            </Markdown>
          )}
        </div>
        <RelatedConcept style={{ marginTop: 16 }} />
      </>
    );
  }

  return <div>{content}</div>;
};

export const QuizHint = () => {
  const { currentRecord } = useExerciseContextWithCurrent();

  if (currentRecord.viewStatus === 'only-question') {
    return (
      <HintTrigger
        onTrigger={() => {
          setTimeout(() => {
            const ele = document.getElementById('exercise-hint');
            if (ele) ele.scrollIntoView({ behavior: 'smooth' });
          }, 1);
        }}
      />
    );
  }

  const answerText = currentRecord.question.hasSolution ? '题解' : '答案';
  let answer;
  if (currentRecord.viewStatus === 'show-solution') {
    answer = <QuizSolutionAndAnswer />;
  } else {
    answer = <AnswerTrigger answerText={answerText} />;
  }

  return (
    <div id="exercise-hint" /*  className={styles.quizHint} */>
      <RelatedConcept />
      <Separator style={{ margin: '20px 0' }} />
      {answer}
    </div>
  );
};

const QuizSolutionAndAnswer = () => {
  const { currentRecord } = useExerciseContextWithCurrent();

  if (
    currentRecord.questionType !== 'quiz-question' ||
    !currentRecord.question.answers
  ) {
    return null;
  }

  let solution = null;
  if (currentRecord.question.solution) {
    solution = (
      <>
        <h4>题解</h4>
        <Markdown>{currentRecord.question.solution}</Markdown>
        <Separator style={{ margin: '20px 0' }} />
      </>
    );
  }
  // TODO: 在 service 层自动处理？目前只有选择题还行，如果加了填空题这样处理就有问题
  const answers =
    /* currentRecord.question.shuffledAnswers || */ currentRecord.question.answers.map(
      (answer) => {
        const difference = parseInt(answer);
        return String.fromCharCode(65 + difference);
      },
    );

  return (
    <div id="exercise-answer">
      {solution}
      <h4>答案</h4>
      <Markdown>{(answers || []).join(' ')}</Markdown>
    </div>
  );
};
