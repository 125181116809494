import * as RadixProgress from '@radix-ui/react-progress';
import './index.less';

export interface IProgressProps extends RadixProgress.ProgressProps {}

export const Progress: React.FC<IProgressProps> = ({ value, style }) => {
  return (
    <RadixProgress.Root className="qz-progress" style={style} value={value}>
      <RadixProgress.Indicator
        className="qz-progress-indicator"
        style={{ width: `${value}%` }}
      />
    </RadixProgress.Root>
  );
};
