import { Loading } from '@/atoms/loading';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import dayjs from 'dayjs';
import { ConfigProvider as PhoneInputConfigProvider } from 'antd-country-phone-input/dist/lite';
import zh from 'world_countries_lists/data/zh/world.json';

import { ModalProps, QzNiceModal } from '@/atoms/modal';
import Routes from './pages/routes';
import remote, { config as configRemote } from './utils/remote';
import { User } from './typings';
import { pathTypes } from '@/typings/path-exam';
import { getAllPath } from './services/assessment';

import styles from './App.module.less';

configRemote({
  onServerError: (requestId, msg, err) => {
    NiceModal.show(QzNiceModal, {
      title: '服务中断了 :(',
      children: (
        <>
          <div>抱歉，我们的服务器出了点问题，请稍后重试或截图联系客服。</div>
          <div className={styles['error-msg']}>
            <div>Method: {err.config.method?.toUpperCase()}</div>
            <div>URL: {err.config.url}</div>
            <div>Request ID: {requestId}</div>
            <div>Message: {msg}</div>
          </div>
        </>
      ),
    } as ModalProps);
  },
  onRateLimiting: (retryAfter) => {
    NiceModal.show(QzNiceModal, {
      title: '忙不过来了！',
      desc: `你的手速太快了，请休息 ${retryAfter} 秒后再来。`,
    } as ModalProps);
  },
});

// 全局状态暂时采用 react context，后续根据场景分析是否采用更高级的技术
export const AppContext = React.createContext<{
  user?: User;
  setUser: (user: User) => void;
  resetUser: () => void;
  allPath?: pathTypes;
  hasPermissionPathList?: pathTypes;
  resetPath?: () => void;
}>({
  setUser: () => {},
  resetUser: () => {},
  resetPath: () => {},
});

// page uses the hook
function Page() {
  const [initialized, setInitialized] = useState(false);
  // const { t, i18n } = useTranslation();

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  const [user, setUser] = useState<User>();
  // 初始化用户
  useEffect(() => {
    (async function () {
      // 避免用户在学习过程中出现需要登录的情况
      // （后端 jwt 过期时间较长，前端在用户打开网站时检查，让 token 提前过期）
      const jwtIssuedAt = localStorage.getItem('qz-jwt-issued-at');
      const diffDays = dayjs().diff(dayjs(jwtIssuedAt), 'days');
      if (!jwtIssuedAt || diffDays >= 90) {
        remote.logout();
      }

      if (!remote.isLogin()) {
        setInitialized(true);
        return;
      }
      remote
        .$get('/user')
        .then(async (data: User) => {
          setUser(data);
        })
        .catch(() => {
          remote.logout();
        })
        .finally(() => {
          setInitialized(true);
        });
    })();
  }, []);

  // 1. 初始化
  // 2. 用户变化后需要刷新
  const [allPath, setAllPath] = useState<pathTypes>();
  const [hasPermissionPathList, setHasPermissionPathList] =
    useState<pathTypes>();
  const resetAllPath = async () => {
    const allPath = await getAllPath();
    setAllPath(allPath);
    setHasPermissionPathList(allPath.filter((v) => v.hasPermission));
  };

  useEffect(() => {
    const initMeta = async () => {
      if (user) {
        const allPath = await getAllPath();
        setAllPath(allPath);
        setHasPermissionPathList(allPath.filter((v) => v.hasPermission));
      }
    };
    initMeta();
  }, [user]);

  if (!initialized) {
    return <Loading />;
  }

  // basename 会多做一层解析，实际上仍然会解析原路由，定义路由时注意避免问题
  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        resetUser: () => setUser(undefined),
        resetPath: () => resetAllPath(),
        allPath,
        hasPermissionPathList,
      }}
    >
      <Router basename={process.env.REACT_APP_SUB_DIRECTORY}>
        <Routes />
      </Router>
    </AppContext.Provider>
  );
}

function getOS() {
  let userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    iosPlatforms = /(iphone|ipad|ipod)/i,
    os = null;

  if (macosPlatforms.test(userAgent)) {
    os = 'macos';
  } else if (iosPlatforms.test(userAgent)) {
    os = 'ios';
  } else if (windowsPlatforms.test(userAgent)) {
    os = 'windows';
  } else if (/android/.test(userAgent)) {
    os = 'android';
  } else if (!os && /linux/.test(userAgent)) {
    os = 'linux';
  }

  return os;
}

// here app catches the suspense from page in case translations are not yet loaded
function App() {
  useEffect(() => {
    if (getOS() === 'windows') {
      var style = document.createElement('style');
      style.innerHTML = `*::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: transparent;
    }
    
    *::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: transparent;
    }
    
    *::-webkit-scrollbar-thumb {
        background-color:rgba(197, 196, 196, 0.5);
    }
    
    *::-webkit-scrollbar-thumb:hover{
        background-color:rgba(197, 196, 196, 0.8);
    }
    
    .block-desc::-webkit-scrollbar {
        width: 20px;
    }
    .block-desc::-webkit-scrollbar-thumb {
        border-left: 10px solid transparent;
        background-clip: padding-box;
        background-color:rgba(197, 196, 196, 0.5);
    }
    `;
      document.head.appendChild(style);
    }
  });
  return (
    <Suspense fallback={<Loading />}>
      <PhoneInputConfigProvider
        locale={zh}
        areaMapper={(area) => {
          if (area.name?.includes('台湾')) {
            return {
              ...area,
              name: '中国台湾',
              emoji: '🇨🇳',
            };
          }
          return area;
        }}
      >
        <NiceModal.Provider>
          <Page />
        </NiceModal.Provider>
      </PhoneInputConfigProvider>
    </Suspense>
  );
}

export default App;
