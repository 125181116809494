import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from '../atoms/button';
import { Modal } from '../atoms/modal';
import { RadioGroup } from '../atoms/radio-group';
import { FeedbackIcon } from '../icons/feedback';
import styles from './index.module.less';
import remote from '@/utils/remote';
import dayjs from 'dayjs';

export const FeedbackButton: React.FC<IFeedbackModalProps> = (props) => {
  return (
    <Button
      variant="text"
      className={styles.feedbackTrigger}
      onClick={() => {
        NiceModal.show(FeedbackModal, props);
      }}
    >
      <FeedbackIcon />
      <span>问题反馈</span>
    </Button>
  );
};

const FeedbackTypes = [
  '内容有错误',
  '描述不清晰',
  '无法点提交',
  '难度较大',
  '功能问题',
  '其它问题',
];

export interface IFeedbackModalProps extends Record<string, any> {
  from: '首次测评' | '知识点练习' | '单元大纲';
  contentType: 'Quiz 题' | 'OJ 题' | '学习资源';
  feedbackTypeOptions?: string[];
  contentId: string | number;
  sessionUuid: string;
  phone?: string;
}

export const FeedbackModal = NiceModal.create<IFeedbackModalProps>(
  ({ feedbackTypeOptions = FeedbackTypes, ...props }) => {
    const modal = useModal();
    const [type, setType] = useState('');
    const [feedback, setFeedback] = useState('');

    const payload = {
      ...props,
      time: dayjs().format('YYYY-MM-DD HH:mm'),
      phone: props.phone ? props.phone : '未登录青舟用户',
      type,
      feedback,
    };

    return (
      <Modal
        title={`问题反馈（${props.contentId}）`}
        width={400}
        open={modal.visible}
        onClose={modal.remove}
      >
        {/* <div className={styles.warning}>
          如遇产品使用问题，可以先尝试刷新或重新打开页面
        </div> */}
        <RadioGroup
          className={styles.feedbackTypes}
          options={feedbackTypeOptions.map((type) => ({
            label: type,
            value: type,
          }))}
          onValueChange={(value) => setType(value)}
        />
        <textarea
          className={styles.feedbackInput}
          placeholder="对你反馈的内容进行补充说明，你的反馈能帮助我们做得更好。"
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Button
          className={styles.feedbackButton}
          onClick={() => {
            // eslint-disable-next-line no-console
            console.log(payload);
            if (payload.type || payload.feedback) {
              const fixedPayload = {
                ...payload,
                feedback: feedback ? feedback.slice(0, 500) : '-',
              };
              // 如果后端挂了直接发给飞书
              remote.$post('/feedback', fixedPayload).catch(() => {
                remote.$post(
                  'https://www.feishu.cn/flow/api/trigger-webhook/3c05fb82a9b094159f70c184e3dd66d7',
                  fixedPayload,
                );
              });
            }
            modal.remove();
            NiceModal.show(CustomerServiceModal);
          }}
        >
          提交反馈
        </Button>
      </Modal>
    );
  },
);

export const CustomerServiceModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal title="感谢你的反馈" open={modal.visible} onClose={modal.remove}>
      <div className={styles.customerService}>
        <img
          alt="qr-code"
          // src="https://staticcdn.boyuai.com/user-assets/6074/kW6z6DSMZoYRGTHdbb79Nj/青舟反馈.png"
          src="https://staticcdn.boyuai.com/user-assets/6074/ba8kmwjxDpuhqJFyGVFDU6/sjtu.png"
        />
        <p>
          若你提交的反馈需要及时解决或得到回复
          <br />
          请使用微信扫码添加客服进一步沟通
        </p>
      </div>
    </Modal>
  );
});
