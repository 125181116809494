import { Button } from '@/atoms/button';
import NiceModal from '@ebay/nice-modal-react';
import { RecordOJSubmission, RecordStatus } from './types';
import { OJLanguage, OJStatus } from '@/typings/oj';
import { useCallback, useContext, useState } from 'react';
import { useExerciseContextWithCurrent } from './exercise.context';
import {
  finishExercise,
  getFailedTestcase,
  getNextExerciseRecord,
  getSolutionAndAnswer,
  submitExerciseOJAnswer,
  submitExerciseQuizAnswer,
  updateViewStatus,
  wait4OJSubmission,
  finishMultiExercise,
} from './exercise.service';
import styles from './footer.module.less';
import {
  ResultNotification,
  ResultNotificationProps,
} from './result-notification';
// import { getOJExplanation } from './oj/explain';
import { Separator } from '@/atoms/separator';
import { FeedbackButton } from '../feedback';
import { needTestcase } from './oj/utils';
import { AppContext } from '../../App';

export const Footer = ({
  type,
  learningPathId,
}: {
  type: string;
  learningPathId: number;
}) => {
  const { user } = useContext(AppContext);
  const { state, dispatch, currentRecord } = useExerciseContextWithCurrent();
  if (state.status !== 'ongoing') {
    throw new Error('[Footer] 练习状态错误');
  }

  const current = state.current;
  const mastery = state.mastery;
  const answerStatus = currentRecord.answerStatus;
  const currentRecordStatus = currentRecord.status;

  const handleRetry = useCallback(async () => {
    if (currentRecord.questionType === 'quiz-question') {
      dispatch({
        type: 'answerChange',
        data: {},
      });
    } else if (currentRecord.questionType === 'oj-problem') {
      dispatch({
        type: 'resetAnswerStatus',
      });
    }
  }, [currentRecord.questionType, dispatch]);

  let ojLanguage: OJLanguage =
    currentRecord.questionType === 'oj-problem'
      ? currentRecord.language
      : 'CPP';
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (submitting) return;
    setSubmitting(true);
    try {
      let passed = false;
      let status: RecordStatus = 'unsubmitted';
      let submission: RecordOJSubmission | null = null;
      if (currentRecord.questionType === 'quiz-question') {
        const res = await submitExerciseQuizAnswer(
          currentRecord.recordId,
          currentRecord.userAnswers,
        );
        passed = res.passed;
        status = res.status;
      } else if (currentRecord.questionType === 'oj-problem') {
        const submissionId = await submitExerciseOJAnswer(
          currentRecord.recordId,
          currentRecord.userAnswers,
          ojLanguage,
        );

        // 关闭解析
        dispatch({ type: 'setOJExplanation', data: '' });
        dispatch({ type: 'setOJExplanationMode', data: undefined });
        if (currentRecord.tab === 'EXPLAINATION') {
          dispatch({ type: 'setTab', data: { tab: 'PROBLEM' } });
        }

        // 测评
        dispatch({
          type: 'submit',
          data: {
            answerStatus: 'unsubmitted',
            status: 'unsubmitted',
            submission: {
              recordStatus: 'unsubmitted',
              status: OJStatus.JUDGING,
              timeCost: -1,
              memoryCost: -1,
              score: -1,
              errorInfo: '',
              totalTestCases: -1,
              passedTestCases: -1,
            },
          },
        });
        dispatch({ type: 'setOJFailedTestcase', data: undefined });
        dispatch({ type: 'setTab', data: { tab: 'SUBMISSION' } });
        const oj = wait4OJSubmission(currentRecord.recordId, submissionId);
        while (!submission || submission.status === OJStatus.JUDGING) {
          const query = oj.next();
          submission = (await query).value;
          if (submission) {
            // 如果没通过，获取错误点详情再显示
            if (needTestcase(submission.status)) {
              const failedTestcase = await getFailedTestcase(
                currentRecord.recordId,
                submissionId,
              );
              dispatch({ type: 'setOJFailedTestcase', data: failedTestcase });
            }
            dispatch({
              type: 'submit',
              data: {
                answerStatus: 'unsubmitted',
                status: 'unsubmitted',
                submission,
              },
            });
          } else {
            dispatch({
              type: 'submit',
              data: {
                answerStatus: 'unsubmitted',
                status: 'unsubmitted',
                submission: {
                  recordStatus: 'unsubmitted',
                  status: OJStatus.SYSTEM_ERROR,
                  timeCost: -1,
                  memoryCost: -1,
                  score: -1,
                  errorInfo: '',
                  totalTestCases: -1,
                  passedTestCases: -1,
                },
              },
            });
            break;
          }
        }

        passed = submission?.status === OJStatus.ACCEPTED;
        status = submission?.recordStatus ?? 'unsubmitted';

        // 错误解析
        // if (submission?.errorInfo) {
        //   const explanation = await getOJExplanation({
        //     errorMessage: submission.errorInfo,
        //     code: currentRecord.userAnswers[0],
        //     language: ojLanguage,
        //   });
        //   dispatch({ type: 'setOJExplanation', data: explanation });
        // }
      }

      // 当用户答错多选题时，直接获取答案，用于显示正误情况
      if (
        currentRecord.viewStatus === 'only-question' &&
        currentRecord.questionType === 'quiz-question' &&
        currentRecord.question.hasMultiAnswers
      ) {
        const { answers } = await getSolutionAndAnswer(currentRecord.recordId);
        dispatch({
          type: 'updateQuizAnswers',
          data: answers,
        });
      }

      if (
        currentRecord.viewStatus !== 'only-question' &&
        (passed || currentRecord.questionType === 'quiz-question')
      ) {
        // 答对，或者是 quiz 题，直接展示答案
        await updateViewStatus(currentRecord.recordId, 'show-solution');
        const { solution, answers } = await getSolutionAndAnswer(
          currentRecord.recordId,
        );
        dispatch({
          type: 'updateViewStatus',
          data: { newStatus: 'show-solution', solution, answers },
        });
      }

      NiceModal.show(ResultNotification, {
        status: passed ? 'correct' : 'wrong',
        onClose: passed ? undefined : handleRetry,
        onSolutionShow: () => {
          if (currentRecord.questionType === 'oj-problem') {
            dispatch({ type: 'setTab', data: { tab: 'SOLUTION' } });
            dispatch({ type: 'resetAnswerStatus' });
          } else if (currentRecord.questionType === 'quiz-question') {
            dispatch({
              type: 'answerChange',
              data: {},
            });
          }
        },
      } as ResultNotificationProps);

      dispatch({
        type: 'submit',
        data: {
          answerStatus: passed ? 'correct' : 'wrong',
          status,
          submission: submission ? submission : undefined,
        },
      });
    } finally {
      setSubmitting(false);
    }
  }, [
    handleRetry,
    submitting,
    ojLanguage,
    currentRecord.tab,
    currentRecord.questionType,
    currentRecord.viewStatus,
    currentRecord.recordId,
    currentRecord.userAnswers,
    currentRecord.question,
    dispatch,
  ]);

  const [loadingNext, setLoadingNext] = useState(false);
  const handleNext = useCallback(async () => {
    if (loadingNext) return;
    setLoadingNext(true);
    try {
      NiceModal.hide(ResultNotification);
      const newRecord = await getNextExerciseRecord(
        state.sessionUuid,
        learningPathId,
      );
      dispatch({
        type: 'addNewRecord',
        data: { newRecord },
      });
      // OJ 题如果有 test case 样例，自动填入
      if (
        newRecord.questionType === 'oj-problem' &&
        newRecord.question.samples.length
      ) {
        dispatch({
          type: 'setConsoleInput',
          data: newRecord.question.samples[0].input,
        });
      }
    } finally {
      setLoadingNext(false);
    }
  }, [dispatch, loadingNext, state.sessionUuid, learningPathId]);

  const [finishing, setFinishing] = useState(false);
  const handleFinish = useCallback(async () => {
    if (finishing) return;
    setFinishing(true);
    try {
      NiceModal.hide(ResultNotification);
      let result;
      if (type === 'multi') {
        result = await finishMultiExercise(
          learningPathId,
          state.lessonContentId,
          state.sessionUuid,
        );
      } else {
        result = await finishExercise(
          learningPathId,
          state.lessonContentId,
          state.sessionUuid,
        );
      }
      dispatch({
        type: 'finishExercise',
        data: result,
      });
    } finally {
      setFinishing(false);
    }
  }, [
    finishing,
    state.lessonContentId,
    state.sessionUuid,
    dispatch,
    type,
    learningPathId,
  ]);

  let button;
  const btnStyle = { width: 158, height: 36, letterSpacing: 2 };
  if (answerStatus === 'unsubmitted') {
    button = (
      <Button
        style={btnStyle}
        onClick={handleSubmit}
        loading={submitting}
        disabled={!currentRecord.userAnswers[0]}
      >
        提交
      </Button>
    );
  } else if (answerStatus === 'correct') {
    if (current === mastery.length - 1) {
      button = (
        <Button style={btnStyle} onClick={handleFinish} loading={finishing}>
          完 成
        </Button>
      );
    } else {
      button = (
        <Button style={btnStyle} onClick={handleNext} loading={loadingNext}>
          下一题
        </Button>
      );
    }
  } else if (answerStatus === 'wrong') {
    button = (
      <Button
        style={btnStyle}
        onClick={() => NiceModal.hide(ResultNotification)}
      >
        再试一试
      </Button>
    );
  }
  return (
    <div className={styles.footer}>
      <div className={styles.count}>
        {current + 1}/{mastery.length}
      </div>
      <div className={styles.statusDots}>
        {mastery.map((m, idx) => {
          let status: IStatusDotProps['status'] = 'todo';
          if (idx < current) {
            // 已经通过的 step 根据 mastery 判定状态
            if (m === 1) {
              status = 'mastered';
            } else {
              status = 'not-mastered';
            }
          } else if (idx === current) {
            if (currentRecordStatus === 'mastered') {
              // 得分只信任服务端
              status = 'mastered';
            } else if (currentRecord.viewStatus === 'show-solution') {
              // 未得分的状态下查看过答案
              status = 'not-mastered';
            } else if (
              currentRecord.questionType === 'quiz-question' &&
              currentRecordStatus !== 'unsubmitted'
            ) {
              // quiz 答错一次就不得分
              status = 'not-mastered';
            } else if (
              currentRecord.answerStatus === 'correct' &&
              currentRecordStatus === 'correct'
            ) {
              // 通过后，若服务端未得分则更新前端
              status = 'not-mastered';
            }
          }
          return (
            <StatusDot status={status} isCurrent={idx === current} key={idx} />
          );
        })}
        <Separator
          style={{ height: 32, marginRight: 16 }}
          orientation="vertical"
        />
        <FeedbackButton
          from="知识点练习"
          contentType={
            currentRecord.questionType === 'oj-problem' ? 'OJ 题' : 'Quiz 题'
          }
          feedbackTypeOptions={[
            '内容有错误',
            '描述不清晰',
            '答案有问题',
            '功能问题',
            '其他问题',
          ]}
          contentId={
            currentRecord.questionType === 'oj-problem'
              ? currentRecord.question.id
              : // : `${currentRecord.question.id}/${currentRecord.question.platformId}`
                `${currentRecord.question.id}`
          }
          sessionUuid={state.sessionUuid}
          phone={user?.phone}
        />
      </div>
      {button}
    </div>
  );
};

interface IStatusDotProps {
  status: 'todo' | 'mastered' | 'not-mastered';
  isCurrent: boolean;
}
const StatusDot: React.FC<IStatusDotProps> = ({ status, isCurrent }) => {
  if (status === 'not-mastered') {
    let size = 10;
    if (isCurrent) {
      size = 14;
    }
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: 20 }}
      >
        <ellipse cx="5.07107" cy="5.13173" rx="4" ry="4" fill="#D9D9D9" />
        <rect
          x="8.07107"
          y="9.13173"
          width="10"
          height="1.5"
          rx="0.75"
          transform="rotate(-135 8.07107 9.13173)"
          fill="#E93C3C"
        />
      </svg>
    );
  }
  return (
    <div
      className={styles.statusDot}
      data-is-current={isCurrent}
      data-status={status}
    />
  );
};
