import * as RadixSeparator from '@radix-ui/react-separator';
import { CSSProperties } from 'react';
import './index.less';

export const Separator: React.FC<
  { style?: CSSProperties } & RadixSeparator.SeparatorProps
> = ({ style, ...props }) => {
  return (
    <RadixSeparator.Root className="qz-separator" style={style} {...props} />
  );
};
