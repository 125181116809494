export const FaqIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7279 10.8885H29.2727C30.0442 10.8885 30.7841 11.195 31.3297 11.7406C31.8753 12.2861 32.1818 13.0261 32.1818 13.7976V19.6158C32.1818 20.3873 31.8753 21.1273 31.3297 21.6728C30.7841 22.2184 30.0442 22.5249 29.2727 22.5249H27.7279C27.3733 25.3369 26.0047 27.9229 23.8789 29.7976C21.753 31.6722 19.0161 32.7066 16.1818 32.7067V29.7976C18.4964 29.7976 20.7162 28.8781 22.3529 27.2414C23.9896 25.6048 24.909 23.3849 24.909 21.0703V12.3431C24.909 10.0284 23.9896 7.80862 22.3529 6.17194C20.7162 4.53526 18.4964 3.61578 16.1818 3.61578C13.8671 3.61578 11.6473 4.53526 10.0106 6.17194C8.37397 7.80862 7.45449 10.0284 7.45449 12.3431V22.5249H3.09085C2.31931 22.5249 1.57938 22.2184 1.03382 21.6728C0.488255 21.1273 0.181763 20.3873 0.181763 19.6158V13.7976C0.181763 13.0261 0.488255 12.2861 1.03382 11.7406C1.57938 11.195 2.31931 10.8885 3.09085 10.8885H4.63558C4.99053 8.07672 6.35933 5.49108 8.48514 3.61676C10.611 1.74244 13.3477 0.708252 16.1818 0.708252C19.0159 0.708252 21.7526 1.74244 23.8784 3.61676C26.0042 5.49108 27.373 8.07672 27.7279 10.8885V10.8885ZM3.09085 13.7976V19.6158H4.5454V13.7976H3.09085ZM27.8181 13.7976V19.6158H29.2727V13.7976H27.8181ZM10.0145 22.2121L11.5563 19.7452C12.9426 20.6136 14.5459 21.073 16.1818 21.0703C17.8176 21.073 19.4209 20.6136 20.8072 19.7452L22.349 22.2121C20.5007 23.3702 18.3629 23.9828 16.1818 23.9794C14.0006 23.9828 11.8628 23.3702 10.0145 22.2121Z"
        fill="#7B6FFF"
      />
    </svg>
  );
};
