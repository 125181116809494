import { useExerciseContextWithCurrent } from './exercise.context';

import { Footer } from './footer';
import { Choice } from './quiz/choice';
import { OJProblem } from './oj/oj-problem';

import styles from './record.module.less';

/**
 * 练习题记录
 * TODO: 等所有题型实现并稳定后，设计“业务中间层”使用 IoC 重构
 */
export const ExerciseRecord = ({
  type,
  learningPathId,
}: {
  type: string;
  learningPathId: number;
}) => {
  const { currentRecord } = useExerciseContextWithCurrent();
  let body;
  if (currentRecord.questionType === 'quiz-question') {
    body = <Choice />;
  } else if (currentRecord.questionType === 'oj-problem') {
    body = <OJProblem />;
  }
  return (
    <>
      <div className={styles.body}>{body}</div>
      <Footer type={type} learningPathId={learningPathId} />
    </>
  );
};
