import React, { useMemo, useState, useEffect } from 'react';

import { Button } from '@/atoms/button';
import { Textarea } from '@/atoms/input';
import { Separator } from '@/atoms/separator';
import { GreenCircleCheck, GreyCircleCheck } from '@/icons/circle-check';
import { ReactComponent as ThanksSvg } from '@/icons/thanks/icon.svg';
import { ReactComponent as WarningSvg } from '@/icons/warning/icon.svg';
import { RestartSvg as PraiseSvg } from '@/icons/praise/index';
import remote from '@/utils/remote';

import styles from './summary.module.less';
import { Modal } from '@/atoms/modal';

type Course = {
  id?: number;
  title: string;
  concepts: Array<{
    id?: number;
    title: string;
    mastered: boolean;
  }>;
};
export type SummaryResp = {
  courses: Course[];
};

interface Props {
  summary: SummaryResp;
  canEnter: boolean; // 是否可以生成路径并进入
  onClickGeneratePath: () => void;
  isCert?: boolean;
  sessionUuid: string;
}

interface ReviewsProps {
  courseId: number | undefined;
  masterySys: (number | undefined)[];
  unmasterySys: (number | undefined)[];
  unmasteryUser: (number | undefined)[];
  masteryUser: (number | undefined)[];
}

const Summary = ({
  summary,
  canEnter,
  onClickGeneratePath,
  isCert,
  sessionUuid,
}: Props) => {
  const [stepNum, setStepNum] = useState<number>(0);
  const [totalRating, setTotalRating] = useState<number>();
  const [startTime, setStartTime] = useState<number>(0);
  const [reviews, setReviews] = useState<Array<ReviewsProps>>([]);
  let reviewInfo: string = '';

  useEffect(() => {
    const data = summary.courses.map((item) => {
      return {
        courseId: item.id,
        masterySys: item?.concepts.filter((i) => i.mastered).map((i) => i?.id),
        unmasterySys: item?.concepts
          .filter((i) => !i.mastered)
          .map((i) => i?.id),
        unmasteryUser: [],
        masteryUser: [],
      };
    });
    setReviews(data);
  }, [summary.courses]);

  const { mastered, notMastered } = useMemo(() => {
    const mastered: Course[] = [];
    const notMastered: Course[] = [];
    summary.courses.forEach((c) => {
      const courseMastered = c.concepts.filter((cc) => cc.mastered);
      const courseNotMastered = c.concepts.filter((cc) => !cc.mastered);
      if (courseMastered.length > 0) {
        mastered.push({
          title: c.title,
          concepts: courseMastered,
        });
      }
      if (courseNotMastered.length > 0) {
        notMastered.push({
          title: c.title,
          concepts: courseNotMastered,
        });
      }
    });
    return {
      mastered,
      notMastered,
    };
  }, [summary]);

  if (!canEnter) {
    return (
      <div className={styles.summary}>
        <h2>你已完成基础测评</h2>
        <p>请耐心等待特训营小助手联系你继续接下来的学习</p>
      </div>
    );
  }

  let passEle;
  if (isCert) {
    passEle =
      mastered.length / (mastered.length + notMastered.length) > 0.5
        ? '通过'
        : '未通过';
  }

  function Card({
    content,
    onSkip,
    onNext,
    nextText,
    num,
  }: {
    content: React.ReactNode;
    onSkip: () => void;
    onNext: () => void;
    nextText: string;
    num: number;
  }) {
    return (
      <div className={styles.card}>
        <div className={styles.triangle} />
        {content}
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button
              size="small"
              variant="text"
              style={{ marginRight: 20, color: 'var(--text-3)' }}
              onClick={onSkip}
            >
              {num === 1 ? '跳过' : '上一步'}
            </Button>
            <Button
              disabled={num === 1 && totalRating === undefined}
              style={{ width: 158 }}
              onClick={onNext}
            >
              {nextText}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const onFinish = async () => {
    const time = new Date().getTime();
    await remote.$post(`/assessment-session/${sessionUuid}/feedback`, {
      reviews,
      totalRating,
      reviewInfo,
      totalTime: time - startTime,
    });
    onClickGeneratePath();
  };

  const appraisal = (num: number) => {
    const subjectNum = summary.courses.length;
    switch (num) {
      case 1:
        return (
          <div className={styles.firstPage}>
            <div className={styles.warning}>
              <img
                width={114}
                height={102}
                alt="qr-code"
                src="https://staticcdn.boyuai.com/user-assets/14410/sHjhT4rvsgRaJduXzNPx35/study.png!png"
              />
              <div>在生成专属学习路径之前</div>
              <p>我们希望能获得你对于测评结果的反馈</p>
              <p>这可以帮助我们更好地优化算法的准确度</p>
            </div>
            <Card
              content={
                <>
                  <div className={styles.title}>整体评价</div>
                  <div className={styles.icons}>
                    <div>
                      <span
                        className={styles.iconLeft}
                        onClick={() => setTotalRating(1)}
                      >
                        <PraiseSvg
                          color={
                            totalRating === 1
                              ? 'var(--primary-blue)'
                              : undefined
                          }
                        />
                      </span>
                      <span
                        style={{
                          marginTop: '20px',
                          fontWeight: totalRating === 1 ? 600 : 400,
                        }}
                      >
                        测得很准
                      </span>
                    </div>
                    <div>
                      <span
                        className={styles.iconRight}
                        onClick={() => setTotalRating(0)}
                      >
                        <PraiseSvg
                          color={
                            totalRating === 0
                              ? 'var(--primary-blue)'
                              : undefined
                          }
                        />
                      </span>
                      <span
                        style={{
                          marginTop: '20px',
                          fontWeight: totalRating === 0 ? 600 : 400,
                        }}
                      >
                        测得不准
                      </span>
                    </div>
                  </div>
                </>
              }
              num={num}
              onSkip={onClickGeneratePath}
              onNext={() => {
                setStepNum(stepNum + 1);
                const time = new Date().getTime();
                setStartTime(time);
              }}
              nextText="下一步"
            />
          </div>
        );
      case subjectNum + 2:
        return (
          <div className={styles.lastPage}>
            <div className={styles.thanks}>
              <ThanksSvg />
              <div>感谢你的反馈</div>
            </div>
            <Card
              content={
                <>
                  <div className={styles.title}>更多建议</div>
                  <Textarea
                    className={styles.textarea}
                    placeholder="如果你有更多的建议，可以写在这里（可选填）。"
                    onChange={(e) => {
                      reviewInfo = e.target.value;
                    }}
                  />
                </>
              }
              onSkip={() => setStepNum(stepNum - 1)}
              onNext={onFinish}
              num={num}
              nextText="完成反馈"
            />
          </div>
        );
      default:
        const mastery = summary.courses[num - 2].concepts.filter(
          (item) => item.mastered,
        );
        const unmastery = summary.courses[num - 2].concepts.filter(
          (item) => !item.mastered,
        );
        return (
          <div className={styles.centerPage}>
            <Card
              content={
                <>
                  <div className={styles.title}>
                    <div>{summary.courses[num - 2].title}</div>
                    <span>请选出系统判断不准确的知识点</span>
                  </div>
                  <div className={styles.content}>
                    {reviews[num - 2].masterySys.length > 0 && (
                      <div className={styles.tagCard}>
                        <div className={styles.type}>系统判断已掌握</div>
                        <div className={styles.remind}>
                          请挑出你其实
                          <span className={styles.emphasize}>没掌握</span>
                          的知识点
                        </div>
                        <div className={styles.tags}>
                          {mastery.map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={
                                  reviews[num - 2].unmasteryUser.includes(
                                    item.id,
                                  )
                                    ? styles.checked
                                    : ''
                                }
                                onClick={() => {
                                  const copy = JSON.parse(
                                    JSON.stringify(reviews),
                                  );
                                  if (
                                    reviews[num - 2].unmasteryUser.includes(
                                      item.id,
                                    )
                                  ) {
                                    copy[num - 2].unmasteryUser = copy[
                                      num - 2
                                    ].unmasteryUser.filter(
                                      (i: number) => i !== item.id,
                                    );
                                  } else {
                                    copy[num - 2].unmasteryUser.push(item.id);
                                  }
                                  setReviews(copy);
                                }}
                              >
                                <span>{item.title}</span>
                                {reviews[num - 2].unmasteryUser.includes(
                                  item.id,
                                ) && <WarningSvg />}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {reviews[num - 2].unmasterySys.length > 0 && (
                      <div className={styles.tagCard}>
                        <div className={styles.type}>系统判断未掌握</div>
                        <div className={styles.remind}>
                          请挑出你其实
                          <span className={styles.emphasize}>已掌握</span>
                          的知识点
                        </div>
                        <div className={styles.tags}>
                          {unmastery.map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={
                                  reviews[num - 2].masteryUser.includes(item.id)
                                    ? styles.checked
                                    : ''
                                }
                                onClick={() => {
                                  const copy = JSON.parse(
                                    JSON.stringify(reviews),
                                  );
                                  if (
                                    reviews[num - 2].masteryUser.includes(
                                      item.id,
                                    )
                                  ) {
                                    copy[num - 2].masteryUser = copy[
                                      num - 2
                                    ].masteryUser.filter(
                                      (i: number) => i !== item.id,
                                    );
                                  } else {
                                    copy[num - 2].masteryUser.push(item.id);
                                  }
                                  setReviews(copy);
                                }}
                              >
                                <span>{item.title}</span>
                                {reviews[num - 2].masteryUser.includes(
                                  item.id,
                                ) && <WarningSvg />}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div></div>
                </>
              }
              num={num}
              onSkip={() => setStepNum(stepNum - 1)}
              onNext={() => setStepNum(stepNum + 1)}
              nextText="下一步"
            />
          </div>
        );
    }
  };

  return (
    <>
      {stepNum === 0 ? (
        <div className={styles.summary}>
          <h2>完成测评</h2>
          {passEle}
          {!isCert && (
            <Button style={{ width: 162 }} onClick={() => setStepNum(1)}>
              生成专属学习路径
            </Button>
          )}
          {mastered.length > 0 && (
            <div style={{ marginBottom: 50 }}>
              <h4>已掌握知识点</h4>
              {mastered.map((c, idx) => (
                <CourseConcept course={c} mastered key={idx} />
              ))}
            </div>
          )}
          {notMastered.length > 0 && (
            <div style={{ marginBottom: 50 }}>
              <h4>需学习知识点</h4>
              {notMastered.map((c, idx) => (
                <CourseConcept course={c} mastered={false} key={idx} />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.appraisal}>{appraisal(stepNum)}</div>
      )}
    </>
  );
};

function CourseConcept({
  course,
  mastered,
}: {
  course: Course;
  mastered: boolean;
}) {
  const { previewConcepts, hasMore, moreLength } = useMemo(() => {
    const hasMore = course.concepts.length > 6;
    const moreLength = course.concepts.length - 5;
    const previewConcepts = course.concepts.slice(0, hasMore ? 5 : 6);
    return { previewConcepts, hasMore, moreLength };
  }, [course]);

  const [modal, setModal] = useState({
    open: false,
    title: '',
    content: null as any,
  });

  const [moreModal, setMoreModal] = useState({
    open: false,
    title: '',
    desc: '',
    content: null as any,
  });
  const title = `《${course.title}》`;
  return (
    <div className={styles.course}>
      <h5>{title}</h5>
      <Separator style={{ marginTop: 8, marginBottom: 14 }} />
      <Modal
        open={modal.open}
        title={modal.title}
        onOpenChange={(open) => {
          if (!open)
            setModal({
              open: false,
              title: '',
              content: null,
            });
        }}
      >
        {modal.content}
      </Modal>
      <Modal
        open={moreModal.open}
        title={moreModal.title}
        onOpenChange={(open) => {
          if (!open)
            setMoreModal({
              open: false,
              title: '',
              desc: '',
              content: null,
            });
        }}
        desc={moreModal.desc}
        preventClickOutside={modal.open}
        preventESC={modal.open}
      >
        {moreModal.content}
      </Modal>
      <div className={styles.courseConcepts}>
        {previewConcepts.map((c, idx) => {
          return (
            <div
              className={styles.concept}
              key={idx}
              onClick={() =>
                setModal({
                  title: (mastered ? '已掌握' : '需学习') + '知识点',
                  content: c.title,
                  open: true,
                })
              }
            >
              <span title={c.title}>{c.title}</span>
              {mastered ? (
                <GreenCircleCheck width={16} height={16} />
              ) : (
                <GreyCircleCheck width={16} height={16} />
              )}
            </div>
          );
        })}
        {hasMore && (
          <Button
            variant="text"
            style={{ marginBottom: 8, marginLeft: 5 }}
            onClick={() => {
              setMoreModal({
                open: true,
                title,
                desc: (mastered ? '已掌握' : '需学习') + '知识点',
                content: (
                  <div className={styles.courseConcepts}>
                    {course.concepts.map((c, idx) => {
                      return (
                        <div
                          className={styles.concept}
                          key={idx}
                          onClick={() =>
                            setModal({
                              title:
                                (mastered ? '已掌握' : '需学习') + '知识点',
                              content: c.title,
                              open: true,
                            })
                          }
                        >
                          <span title={c.title}>{c.title}</span>
                          {mastered ? (
                            <GreenCircleCheck width={16} height={16} />
                          ) : (
                            <GreyCircleCheck width={16} height={16} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ),
              });
            }}
          >
            +{moreLength} 查看更多
          </Button>
        )}
      </div>
    </div>
  );
}

export default Summary;
