/**
 * 接口文档：https://dev.qingzhouzhixue.com/api/v1/swagger/#/%E7%BB%83%E4%B9%A0%E6%A8%A1%E5%9D%97
 * 设计文档：https://fe9m1yda6v.feishu.cn/docs/doccnU6YnnNMZF60eRXmG8IBRVg#PdeZPs
 */
import { sleep } from '@/utils';
import remote, { RemoteError } from '@/utils/remote';
import {
  AnswerStatus,
  ExerciseOJProblem,
  ExerciseQuestionType,
  ExerciseQuizQuestion,
  ExerciseRecordType,
  ExerciseResultType,
  ExerciseType,
  ExerciseViewStatus,
  LearningResource,
  RecordOJSubmission,
  RecordStatus,
} from './types';
import {
  OJFailedTestcase,
  OJLanguage,
  OJRunSubmission,
  OJStatus,
} from '@/typings/oj';
import { needTestcase } from './oj/utils';

const regx = new RegExp('/judger/run/[\\w\\d]+/main.cpp', 'g');
const stripOJErrorInfo = (info: string) => {
  return info.replace(regx, '/main.cpp');
};

const getSessionInfo = async (lessonContentId: number, sessionUuid: string) => {
  return remote.$get(
    `/lesson-content/${lessonContentId}/assessment-session/${sessionUuid}`,
  );
};

const getMultiSessionInfo = async (
  lessonContentId: number,
  sessionUuid: string,
) => {
  return remote.$get(
    `/assessment/${lessonContentId}/assessment-session/${sessionUuid}`,
  );
};

export const initExercise = async (
  learningPathId: number,
  lessonContentId: number,
  sessionUuid?: string,
  type?: string,
) => {
  let exercise: {
    questionNum: number;
    title: string;
  };
  if (type === 'multi') {
    exercise = await remote.$get(
      `/assessment/multiExercise/${lessonContentId}/${learningPathId}`,
    );
  } else {
    exercise = await remote.$get(
      `/lesson-content/${lessonContentId}/${learningPathId}`,
    );
  }

  if (!sessionUuid) {
    const result: ExerciseType = {
      ...exercise,
      status: 'unstarted',
      lessonContentId,
    };
    return result;
  }
  let session: {
    index: number;
    mastery: number[];
    status: RecordStatus;
    viewStatus: ExerciseViewStatus;
    recordId: number;
    questionType: ExerciseQuestionType;
    question: ExerciseOJProblem | ExerciseQuizQuestion;
    learningResources: LearningResource[];
    userAnswers: string[];
    lastSubmissionId?: number;
  };

  if (type === 'multi') {
    session = await getMultiSessionInfo(lessonContentId, sessionUuid).catch(
      async (err) => {
        const remoteErr = err as RemoteError;
        if (!remoteErr.isAxiosError) throw err;
        if (remoteErr.response?.status !== 404) throw err;
        // 如果系统异常导致 session 中不存在题目，调用推题接口
        await getNextExerciseRecord(sessionUuid, learningPathId);
        return await getMultiSessionInfo(lessonContentId, sessionUuid);
      },
    );
  } else {
    session = await getSessionInfo(lessonContentId, sessionUuid).catch(
      async (err) => {
        const remoteErr = err as RemoteError;
        if (!remoteErr.isAxiosError) throw err;
        if (remoteErr.response?.status !== 404) throw err;
        // 如果系统异常导致 session 中不存在题目，调用推题接口
        await getNextExerciseRecord(sessionUuid, learningPathId);
        return await getSessionInfo(lessonContentId, sessionUuid);
      },
    );
  }

  let answerStatus: AnswerStatus;
  if (session.status === 'unsubmitted') {
    answerStatus = 'unsubmitted';
  } else if (session.status === 'wrong') {
    answerStatus = 'unsubmitted'; // 如果用户之前答错了，初始化时应该允许用户直接重试
  } else {
    answerStatus = 'correct';
  }
  if (session.viewStatus === 'show-solution') {
    const { answers, solution } = await getSolutionAndAnswer(session.recordId);
    session.question.hasSolution = !!solution;
    session.question.answers = answers;
    session.question.solution = solution;
  }
  const data = {
    lessonContentId,
    sessionUuid,
    current: session.index,
    mastery: session.mastery,
  };
  if (session.questionType === 'oj-problem') {
    let lastSubmission;
    let failedTestcase;
    if (session.lastSubmissionId) {
      lastSubmission = await retrieveOJSubmission(
        session.recordId,
        session.lastSubmissionId,
      );
      if (needTestcase(lastSubmission.status)) {
        failedTestcase = await getFailedTestcase(
          session.recordId,
          session.lastSubmissionId,
        );
      }
    }
    const result: ExerciseType = {
      status: 'ongoing',
      ...exercise,
      ...data,
      records: [
        {
          ...session,
          questionType: 'oj-problem',
          question: session.question as ExerciseOJProblem,
          answerStatus,
          lastSubmission,
          failedTestcase,
          tab: 'PROBLEM',
          language: 'CPP',
        },
      ],
    };
    return result;
  } else if (session.questionType === 'quiz-question') {
    const result: ExerciseType = {
      status: 'ongoing',
      ...exercise,
      ...data,
      records: [
        {
          ...session,
          questionType: 'quiz-question',
          question: session.question as ExerciseQuizQuestion,
          answerStatus,
          tab: 'QUESTION',
        },
      ],
    };
    return result;
  }
  throw new Error('[initExercise] 类型错误');
};

export const startExercise = async (
  learningPathId: number,
  type?: string,
  lessonContentId?: number,
  customQuestions?: any[],
) => {
  let res: {
    sessionUuid: string;
    customQuestionLength?: number;
  };
  if (type === 'multi') {
    res = await remote.$post(
      `/assessment/${lessonContentId}/assessment-session`,
      { customQuestions: [] },
    );
  } else {
    res = await remote.$post(
      `/lesson-content/${lessonContentId}/assessment-session`,
      { customQuestions },
    );
  }

  const { sessionUuid, customQuestionLength } = res;

  const firstRecord = await getNextExerciseRecord(sessionUuid, learningPathId);
  return { sessionUuid, firstRecord, customQuestionLength };
};

export const updateViewStatus = async (
  recordId: number,
  newStatus: 'show-resources' | 'show-solution',
) => {
  return remote.$post(`/assessment-session-record/${recordId}/view-status/`, {
    viewStatus: newStatus,
  });
};
export const getSolutionAndAnswer = async (recordId: number) => {
  const { solution, answers }: { solution?: string; answers: string[] } =
    await remote.$get(`/assessment-session-record/${recordId}/solution`);
  return { solution, answers };
};

export const submitExerciseQuizAnswer = async (
  recordId: number,
  userAnswers: string[],
) => {
  const { status }: { status: 'correct' | 'wrong' | 'mastered' } =
    await remote.$post(`/assessment-session-record/${recordId}/submission`, {
      userAnswers: userAnswers,
    });
  const passed = status !== 'wrong';
  return { passed, status };
};

export const retrieveOJRunSubmission = (
  recordId: number,
  uuid: string,
): Promise<OJRunSubmission> => {
  return remote.$get(`/assessment-session-record/${recordId}/run-code/${uuid}`);
};

export const submitOJRunCode = async (
  recordId: number,
  code: string,
  language: OJLanguage,
  input: string,
) => {
  const { uuid }: { uuid: string } = await remote.$post(
    `/assessment-session-record/${recordId}/run-code`,
    {
      code,
      language,
      input,
    },
  );
  return uuid;
};

export async function* wait4OJRunCode(recordId: number, uuid: string) {
  let count = 30;
  while (count >= 0) {
    count -= 1;
    const submission = await retrieveOJRunSubmission(recordId, uuid);
    if (submission.userErrorInfo) {
      submission.userErrorInfo = stripOJErrorInfo(submission.userErrorInfo);
    }
    // 测试失败情况
    // submission.status = OJStatus.JUDGING
    yield submission;
    if (submission.status === OJStatus.JUDGING) {
      await sleep(777);
      continue;
    }
  }
  return null;
}

export const retrieveOJSubmission = (
  recordId: number,
  id: number,
): Promise<RecordOJSubmission> => {
  return remote.$get(`/assessment-session-record/${recordId}/submission/${id}`);
};

export const submitExerciseOJAnswer = async (
  recordId: number,
  userAnswers: string[],
  language: OJLanguage,
) => {
  const { id }: { id: number } = await remote.$post(
    `/assessment-session-record/${recordId}/submission`,
    {
      language,
      userAnswers,
    },
  );
  return id;
};

export async function* wait4OJSubmission(
  recordId: number,
  submissionId: number,
) {
  let count = 30;
  while (count >= 0) {
    count -= 1;
    const submission = await retrieveOJSubmission(recordId, submissionId);
    if (submission.errorInfo) {
      submission.errorInfo = stripOJErrorInfo(submission.errorInfo);
    }
    yield submission;
    if (submission.status === OJStatus.JUDGING) {
      await sleep(777);
      continue;
    }
  }
  return null;
}

export const getFailedTestcase = async (
  recordId: number,
  submissionId: number,
) => {
  const res: OJFailedTestcase = await remote.$get(
    `/assessment-session-record/${recordId}/submission/${submissionId}/download-testcase`,
  );
  return res;
};

export const getNextExerciseRecord = async (
  sessionUuid: string,
  learningPathId: number,
) => {
  const res: {
    index: number;
    recordId: number;
    questionRemaining: number;
    questionType: ExerciseQuestionType;
    question: ExerciseOJProblem | ExerciseQuizQuestion;
    learningResources: LearningResource[];
  } = await remote.$post(`/assessment-session-record`, {
    sessionUuid,
    pathId: learningPathId,
  });
  if (res.questionType === 'oj-problem') {
    const record: ExerciseRecordType = {
      ...res,
      questionType: 'oj-problem',
      question: res.question as ExerciseOJProblem,
      status: 'unsubmitted',
      viewStatus: 'only-question',
      answerStatus: 'unsubmitted',
      userAnswers: [],
      tab: 'PROBLEM',
      language: 'CPP',
    };
    return record;
  } else if (res.questionType === 'quiz-question') {
    const record: ExerciseRecordType = {
      ...res,
      questionType: 'quiz-question',
      question: res.question as ExerciseQuizQuestion,
      status: 'unsubmitted',
      viewStatus: 'only-question',
      answerStatus: 'unsubmitted',
      userAnswers: [],
      tab: 'QUESTION',
    };
    return record;
  }
  throw new Error('[getNextExerciseRecord] 类型错误');
};

export const finishExercise = async (
  learningPathId: number,
  lessonContentId: number,
  sessionUuid: string,
) => {
  const result: ExerciseResultType = await remote.$post(
    `/lesson-content/${lessonContentId}/assessment-session/${sessionUuid}/status`,
    {
      status: 'finished',
      pathId: learningPathId,
    },
  );
  return result;
};

export const finishMultiExercise = async (
  learningPathId: number,
  lessonContentId: number,
  sessionUuid: string,
) => {
  const result: ExerciseResultType = await remote.$post(
    `/assessment-session-record/${lessonContentId}/assessment-session/${sessionUuid}/status`,
    {
      pathId: learningPathId,
    },
  );
  return result;
};
