import { Markdown } from '@/components/markdown';
import { RadioGroup } from '@/atoms/radio-group';
import { Checkbox } from '@/atoms/checkbox';
import { Separator } from '@/atoms/separator';
import { Tabs } from '@/atoms/tabs';
import { ExerciseQuizQuestion } from '../types';
import styles from './quiz-viewer.module.less';
import { Loading } from '@/atoms/loading';

interface Props {
  question: ExerciseQuizQuestion | undefined;
}

const QuizViewer = ({ question }: Props) => {
  const optionsChange = (options: { text: string }[]) => {
    return options.map((o, index) => {
      const value = index.toString();
      return {
        value,
        label: (
          <div>
            {String.fromCharCode(65 + index)}:
            <br />
            <Markdown>{o.text}</Markdown>
          </div>
        ),
      };
    });
  };

  if (!question) {
    return <Loading />;
  }

  return (
    <Tabs
      current={'QUESTION'}
      tabs={[
        {
          key: 'QUESTION',
          // title: QuizTabEnum.QUESTION,
          title: '题目',
          content: (
            <div className={styles.body}>
              <Markdown>{`**题目：** ${question.title}`}</Markdown>
              <Separator style={{ margin: '20px 0' }} />
              {question.hasMultiAnswers ? (
                question.options.map((o, index) => {
                  const value = index.toString();
                  let status: 'correct' | 'wrong' | 'miss' | undefined;
                  return (
                    <>
                      <Checkbox
                        key={index}
                        label={
                          <div>
                            {String.fromCharCode(65 + index)}:
                            <br />
                            <Markdown>{o.text}</Markdown>
                          </div>
                        }
                        value={value}
                        status={status}
                        itemAlign="flex-start"
                      />
                      <Separator style={{ margin: '20px 0' }} />
                    </>
                  );
                })
              ) : (
                <RadioGroup
                  // value={userAnswer}
                  separator
                  onValueChange={() => {}}
                  itemAlign="flex-start"
                  options={optionsChange(question.options)}
                />
              )}
            </div>
          ),
        },
      ]}
    />
  );
};

export default QuizViewer;
