import remote from '@/utils/remote';
import { pathTypes } from '@/typings/path-exam';

export const saveSessionUuid = (
  assessmentId: number | string,
  uuid: string,
) => {
  return localStorage.setItem(`assessment-${assessmentId}-session`, uuid);
};

export const retrieveSessionUuid = (assessmentId: number | string) => {
  return localStorage.getItem(`assessment-${assessmentId}-session`);
};

export const removeSessionUuid = (assessmentId: number | string) => {
  return localStorage.removeItem(`assessment-${assessmentId}-session`);
};

export type CppMeta = {
  learningPathId: number;
  assessmentId: number;
  courses: { id: number; title: string }[];
};

export const getCppMeta = () => {
  return remote.$get('/learning-path/cpp-meta') as Promise<CppMeta>;
};

export type RpaMeta = {
  learningPathId: number;
  assessmentId: number;
  courses: { id: number; title: string }[];
};

export const getRpaMeta = () => {
  return remote.$get('/learning-path/rpa-meta') as Promise<RpaMeta>;
};

export type HrlMeta = {
  learningPathId: number;
  assessmentId: number;
  courses: { id: number; title: string }[];
};

export const getHrlMeta = () => {
  return remote.$get('/learning-path/hrl-meta') as Promise<HrlMeta>;
};

export const getAllPath = () => {
  return remote.$get('/learning-path/getAllPathForUser') as Promise<pathTypes>;
};
