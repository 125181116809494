import { RadioGroup } from '@/atoms/radio-group';
import { Separator } from '@/atoms/separator';
import { Tabs } from '@/atoms/tabs';
import { Markdown } from '@/components/markdown';
import { QuizTabEnum } from '../types';
import NiceModal from '@ebay/nice-modal-react';
import { Checkbox } from '@/atoms/checkbox';
import { useExerciseContextWithCurrent } from '../exercise.context';
import { QuizHint } from '../hint';
import styles from './choice.module.less';
import { ResultNotification } from '../result-notification';

export const Choice = () => {
  const { currentRecord, dispatch, state } = useExerciseContextWithCurrent();
  if (state.status !== 'ongoing') {
    throw new Error('[OJProblem] 练习状态不正确');
  }

  if (currentRecord.questionType !== 'quiz-question') {
    throw new Error('Choice 题型错误');
  }

  const orderNum = currentRecord.index + 1;
  const title = currentRecord.question.title;
  const { userAnswers, viewStatus, answerStatus } = currentRecord;
  const hasMultiAnswers = currentRecord.question.hasMultiAnswers;
  const questionTypeName = hasMultiAnswers ? '多选题' : '单选题';

  return (
    <Tabs
      current={'QUESTION'}
      tabs={[
        {
          key: 'QUESTION',
          title: QuizTabEnum.QUESTION,
          content: (
            <div className={styles.body}>
              <div className={styles.title}>
                练习{orderNum}·{questionTypeName}
              </div>
              {hasMultiAnswers &&
                currentRecord.answerStatus === 'wrong' &&
                userAnswers.length > 0 && (
                  <div style={{ color: 'var(--secondary-red)' }}>
                    未选择全部正确答案
                  </div>
                )}
              <Markdown>{title}</Markdown>
              <Separator style={{ margin: '20px 0' }} />

              {hasMultiAnswers ? (
                currentRecord.question.options.map((o, index) => {
                  const value = index.toString();
                  const answers = currentRecord.question.answers || [];
                  let status: 'correct' | 'wrong' | 'miss' | undefined;
                  if (userAnswers.includes(value) && answers.includes(value)) {
                    // 用户选择该选项，且选项正确，显示正确答案
                    status = 'correct';
                  } else if (
                    userAnswers.includes(value) &&
                    !answers.includes(value)
                  ) {
                    // 用户选择该选项，但选项错误
                    status = 'wrong';
                  } else if (
                    !userAnswers.includes(value) &&
                    answers.includes(value)
                  ) {
                    // 用户未选择该选项，但选项正确
                    status = 'miss';
                  }
                  // 1. 用户尚未看过题解/答案，不显示遗漏的答案
                  // 2. 用户尚未提交答案，不显示状态
                  if (
                    answerStatus === 'unsubmitted' ||
                    (viewStatus === 'only-question' && status === 'miss')
                  ) {
                    status = undefined;
                  }

                  return (
                    <>
                      <Checkbox
                        key={index}
                        label={
                          <div>
                            {String.fromCharCode(65 + index)}:
                            <br />
                            <Markdown>{o.text}</Markdown>
                          </div>
                        }
                        value={value}
                        status={status}
                        itemAlign="flex-start"
                        disabled={answerStatus === 'correct'}
                        checked={userAnswers.indexOf(value) !== -1}
                        onCheckedChange={(checked) => {
                          let newUserAnswers = [];
                          if (checked) {
                            newUserAnswers = userAnswers.concat([value]);
                          } else {
                            newUserAnswers = userAnswers.filter(
                              (ans) => ans !== value,
                            );
                          }

                          NiceModal.hide(ResultNotification);
                          dispatch({
                            type: 'answerChange',
                            data: { newAnswers: newUserAnswers },
                          });
                        }}
                      />
                      <Separator style={{ margin: '20px 0' }} />
                    </>
                  );
                })
              ) : (
                <RadioGroup
                  value={userAnswers[0]}
                  disabled={answerStatus === 'correct'}
                  separator
                  onValueChange={(newAnswer: string) => {
                    NiceModal.hide(ResultNotification);
                    dispatch({
                      type: 'answerChange',
                      data: { newAnswers: [newAnswer] },
                    });
                  }}
                  itemAlign="flex-start"
                  options={currentRecord.question.options.map((o, index) => {
                    const value = index.toString();
                    let status: undefined | 'wrong' | 'correct';
                    if (userAnswers[0] === value) {
                      if (answerStatus === 'wrong') {
                        status = 'wrong';
                      } else if (answerStatus === 'correct') {
                        status = 'correct';
                      }
                    }
                    return {
                      value,
                      status,
                      label: (
                        <div>
                          {String.fromCharCode(65 + index)}:
                          <br />
                          <Markdown>{o.text}</Markdown>
                        </div>
                      ),
                    };
                  })}
                />
              )}
              <QuizHint />
            </div>
          ),
        },
      ]}
    />
  );
};
