import { useCallback, useState } from 'react';

import { Button } from '@/atoms/button';
import { Separator } from '@/atoms/separator';
import { useStartExercise } from './use-start-exercise';

import styles from './welcome.module.less';
import { useExerciseContext } from './exercise.context';

interface Props {
  learningPathId: number;
  questionNum: number;
  lessonContentId: number;
  type: string;
  exerciseInfo?: { globalStatus: string; intro: string };
}

export const ExerciseWelcome = ({
  learningPathId,
  questionNum,
  lessonContentId,
  type,
  exerciseInfo,
}: Props) => {
  const { dispatch } = useExerciseContext();
  const [loading, setLoading] = useState(false);
  const {
    ready,
    questionNum: customQuestionNum,
    startExercise,
  } = useStartExercise(learningPathId, lessonContentId, type);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      const { firstRecord, sessionUuid, customQuestionLength } =
        await startExercise();

      dispatch({
        type: 'start',
        data: {
          uuid: sessionUuid,
          firstRecord,
          customQuestionNum: customQuestionLength,
        },
      });
    } catch (error) {
      setLoading(false);
    }
  }, [dispatch, startExercise]);
  return (
    <div className={styles.welcome}>
      <div className={styles.title}>准备好练习了吗 ？</div>
      <Separator style={{ margin: '18px 0' }} />
      <div className={styles.subtitle}>
        共有 {customQuestionNum ? customQuestionNum : questionNum} 个问题
        {exerciseInfo?.intro ? `，${exerciseInfo.intro}` : ''}
      </div>
      <Button
        style={{ marginTop: 57, width: 158 }}
        loading={loading}
        disabled={!ready}
        onClick={handleClick}
      >
        开始练习
      </Button>
    </div>
  );
};
