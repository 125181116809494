import { useHistory, useParams } from 'react-router-dom';

import { useCallback, useEffect, useState, useRef } from 'react';

import { Loading } from '@/atoms/loading';
import remote from '@/utils/remote';
import { UserExam, PaperPart } from '@/typings/exam';
import QuizPart from './components/random-quiz-part';
import FileUploadPart from './components/file-upload-part';
import OjPart from './components/adaptive-oj-part';
import Webcam from './components/webcam';
import styles from './part.module.less';

const CertExamSessionPart = () => {
  const { id, partId } = useParams<{ id: string; partId: string }>();

  const history = useHistory() as any;
  const status = history?.location?.state?.status;

  const [generating, setGenerating] = useState(false);
  const [webcamPage, setWebcamPage] = useState<boolean>(true);
  const [webcamStatus, setWebcamStatus] = useState<string>('unauthorized'); //consent,reject,unauthorized
  const [screenStatus, setScreenStatus] = useState<string>('unauthorized'); //consent,reject,unauthorized

  const [exam, setExam] = useState<UserExam>();
  const [part, setPart] = useState<PaperPart>();
  const initDate = useCallback(async () => {
    if (webcamPage === false) {
      if (status === 'unstarted') {
        setGenerating(true);
        await remote.$patch(
          `/user/exams/${id}/exam-paper-parts/${partId}/status`,
          {
            status: 'ongoing',
          },
        );
      }
      setGenerating(false);
      const exam: UserExam = await remote.$get(`/user/exams/${id}`);
      const part: PaperPart = await remote.$get(
        `/user/exams/${id}/exam-paper-parts/${partId}`,
      );
      setExam(exam);
      setPart(part);
    }
  }, [id, partId, status, webcamPage]);
  useEffect(() => {
    initDate();
  }, [initDate]);
  useEffect(() => {
    if (webcamPage === false) {
      if (exam && exam.userExam.status !== 'ongoing') {
        history.replace(`/cert-exam/${id}`);
      } else if (part && part.status !== 'ongoing') {
        history.replace(`/cert-exam/${id}/session`);
      }
    }
  }, [history, id, exam, part, webcamPage]);

  const useMediaRecorder = () => {
    const mediaStream = useRef<MediaStream>();
    const screenStream = useRef<MediaStream>();
    const mediaRecorder = useRef<MediaRecorder>();
    const screenRecorder = useRef<MediaRecorder>();

    const startRecord = async () => {
      try {
        await navigator.mediaDevices
          .getDisplayMedia({
            video: true,
          })
          .then(async (stream) => {
            screenStream.current = stream;
            stream.getVideoTracks()[0].onended = async () => {
              //单击停止共享按钮后，触发这个事件
              setScreenStatus('reject');
            };
          });
        setScreenStatus('consent');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('screen error:', error);
        setScreenStatus('reject');
        screenStream.current = undefined;
      }

      try {
        await navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then(async (stream) => {
            mediaStream.current = stream;
            stream.getVideoTracks()[0].onended = async () => {
              setWebcamStatus('reject');
            };
          });
        setWebcamStatus('consent');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('webcam error:', error);
        setWebcamStatus('reject');
        mediaStream.current = undefined;
      }

      if (webcamStatus === 'consent') {
        mediaRecorder.current = new MediaRecorder(mediaStream.current!);
        mediaRecorder?.current?.start();
        screenRecorder.current = new MediaRecorder(screenStream.current!);
        screenRecorder?.current?.start();
      }
    };

    const stopRecord = async () => {
      mediaRecorder.current?.stop();
      mediaStream.current?.getTracks().forEach((track) => track.stop());
      screenRecorder.current?.stop();
      screenStream.current?.getTracks().forEach((track) => track.stop());
    };
    return {
      startRecord,
      stopRecord,
      getMediaStream: () => mediaStream.current,
    };
  };
  const { startRecord, stopRecord, getMediaStream } = useMediaRecorder();

  // 关闭全屏
  const exitScreen = () => {
    if (document?.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    }
  };
  useEffect(() => {
    return exitScreen;
  }, []);

  const stopWebcam = () => {
    stopRecord();
  };
  useEffect(() => {
    return stopWebcam;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    examTitle: history?.location?.state?.title,
    examId: Number(id),
    webcamStatus,
    screenStatus,
  };

  if (webcamPage) {
    return (
      <Webcam
        setWebcamPage={setWebcamPage}
        {...props}
        startRecord={startRecord}
        stopRecord={stopRecord}
        getMediaStream={getMediaStream}
      />
    );
  }

  if (generating) {
    return (
      <div className={styles.loading}>
        <Loading />
        试卷生成中
      </div>
    );
  }

  if (!part || !exam) {
    return <Loading />;
  }

  if (part.type === 'randomQuiz' || part.type === 'adaptiveQuiz') {
    return <QuizPart part={part} {...props} />;
  } else if (part.type === 'upload') {
    return <FileUploadPart part={part} {...props} />;
  } else if (part.type === 'adaptiveOJ' || part.type === 'randomOJ') {
    return <OjPart part={part} {...props} />;
  } else {
    return <></>;
  }
};

export default CertExamSessionPart;
