import { Button } from '@/atoms/button';
import { useInitSurvey } from '@/components/init-survey';
import NiceModal from '@ebay/nice-modal-react';
import { FaqIcon } from '@/icons/faq';
import { MetarkInverse } from '@/icons/qingzhou';
import { StackIcon } from '@/icons/stack';
import { saveSessionUuid } from '@/services/assessment';
import remote from '@/utils/remote';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import { Nav } from '../../layouts';

import styles from './index.module.less';
import { ModalProps, QzNiceModal } from '@/atoms/modal';

const HeaderCard: React.FC<{
  title: string;
  intro: string;
  cardBottom: React.ReactChild;
}> = ({ title, intro, cardBottom }) => {
  return (
    <div className={styles['header-card']}>
      <div>
        <div className={styles['header-card-title']}>{title}</div>
        <div className={styles['header-card-intro']}>{intro}</div>
      </div>
      {cardBottom}
    </div>
  );
};

const LearningStep: React.FC<{
  stepNum: number;
  title: string;
  intro: string;
  imgProps: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
}> = ({ stepNum, title, intro, imgProps }) => {
  return (
    <div className={styles['learning-step']}>
      <div>
        <div className={styles['step-number']}>
          <StepNode />
          Step {stepNum}
        </div>
        <div className={styles['step-title']}>{title}</div>
        <div className={styles['step-intro']}>{intro}</div>
      </div>
      <div className={styles['step-img-wrapper']}>
        <img alt="help" {...imgProps} />
      </div>
    </div>
  );
};

export const Landing = () => {
  const history = useHistory();
  const { user, allPath, hasPermissionPathList } = useContext(AppContext);

  // 根据用户情况跳转页面
  useEffect(() => {
    if (!user) return;
    if (!hasPermissionPathList?.length) return; // 没有有权限的路径
    (async function () {
      if (allPath) {
        // 本地记录的上次路径地址
        const localPathId = localStorage.getItem('learningPathId');
        // 所有已经学过的
        const learningData = allPath.filter((item) => {
          return item.userLearning;
        });
        const producedData = allPath.filter((item) => {
          return item.isProduced;
        });
        const learningIds = learningData.map((item) => {
          return item.id;
        });
        const { assessmentId, id } = producedData[0];
        // 跳转上次记录 learning-path 地址
        if (
          localPathId &&
          hasPermissionPathList.find(
            (item) => item.id === Number(localPathId),
          ) &&
          learningIds.indexOf(Number(localPathId)) !== -1
        ) {
          history.push(`/learning-path/${localPathId}`);
          return;
        }
        // 默认第一个路径
        if (
          hasPermissionPathList.find(
            (item) => item.id === Number(learningIds[0]),
          ) &&
          learningIds[0]
        ) {
          history.push(`/learning-path/${learningIds[0]}`);
          return;
        }
        // 临时接口，AB 测试用户等待分组
        const { hasFinished, sessionUuid } = await remote.$get(
          `/learning-path/${id}/has-finished-exam`,
        );
        // 如果已经完成了首次测评，但路径没有生成，进入待分组页面
        if (learningIds.length < 1 && hasFinished) {
          saveSessionUuid(assessmentId, sessionUuid);
          history.push(`/exam/${assessmentId}/session`);
        }
        // 该接口用于判断是否填写了激活码
      }
    })();
  }, [user, history, allPath, hasPermissionPathList]);

  const [initSurvey, startInitSurvey] = useInitSurvey();

  useEffect(() => {
    const pannel = sessionStorage.getItem('pannel');
    if (pannel) {
      startInitSurvey({ defaultStep: 2 });
      sessionStorage.removeItem('pannel');
    }
  });

  return (
    <>
      <div className={styles.header}>
        <Nav inverse className={styles.nav} />
        <div className={styles['header-main']}>智能化技能学习平台</div>
        <div className={styles['header-cards']}>
          <HeaderCard
            title="个人用户"
            intro="青舟智学，你的专属老师"
            cardBottom={
              <Button
                className={styles['header-card-btn']}
                onClick={() => startInitSurvey()}
              >
                开始学习之旅
              </Button>
            }
          />
          <HeaderCard
            title="企业版"
            intro="用数字化方式培养数字化人才"
            cardBottom={
              <Button variant="sub" className={styles['header-card-btn']}>
                了解更多
              </Button>
            }
          />
        </div>
        {/* <div className={styles['header-sub']}>
          对标 CSP 算法认证体系，助力 BAT 大厂编程面试
        </div> */}
      </div>
      {/* <div className={styles['partner']}>
        <img
          src="https://staticcdn.boyuai.com/user-assets/5524/tg9FT8ueuT46EDWyNtGxRg/logo.png!png"
          alt="partner"
        />
      </div> */}
      <div className={styles['learning-paths-wrapper']}>
        <div className={styles['sub-header']}>多元学习路径</div>
        <div className={styles['sub-desc']}>
          探索前沿技术发展，把握高薪职业机会
        </div>
        <div className={styles['learning-paths-flex']}>
          {[
            [
              '数据结构与算法',
              'https://staticcdn.boyuai.com/user-assets/5524/emLxTyX7cbk186pdztiVUy/alg.png!png',
              'dark',
              '#252837',
              true,
              '源自上海交通大学ACM班',
              'algo',
            ],
            [
              'RPA',
              'https://staticcdn.boyuai.com/user-assets/5524/anwgQoqa1cNQcA6gkDz5hh/rpa.png!png',
              'dark',
              '#5A4BFF',
              true,
              '机器人流程自动化，火热发展中',
              'rpa',
            ],
            [
              '动手学强化学习',
              'https://staticcdn.boyuai.com/user-assets/14410/Pf2VdWaSPKVqhihX8kebZd/rl.png!png',
              'light',
              '#F8F8FD',
              true,
              '动手强化学习提高学习能力',
              'hrl',
            ],
            [
              'MLOps',
              'https://staticcdn.boyuai.com/user-assets/5524/ENH6Lm8L5CC4v8NKeNT66Z/mlops.png!png',
              'light',
              '#F8F8FD',
              false,
              '',
              '',
            ],
            [
              '机器学习',
              'https://staticcdn.boyuai.com/user-assets/5524/2Tek8UWQgW2nz4h3MtTdGB/ml.png!png',
              'light',
              '#F8F8FD',
              false,
              '',
              '',
            ],
            [
              '计算机视觉',
              'https://staticcdn.boyuai.com/user-assets/5524/wdr9HGbZiV4zgkVtnbH8pk/cv.png!png',
              'dark',
              '#252837',
              false,
              '',
              '',
            ],
            // [
            //   '自然语言处理',
            //   'https://staticcdn.boyuai.com/user-assets/5524/ipBjbbDTV1em1yrLHyzJVt/nlp.png!png',
            //   'light',
            //   '#F8F8FD',
            //   false,
            //   '',
            //   '',
            // ],
          ].map((p, idx) => (
            <div
              key={idx}
              className={styles['learning-paths-item']}
              data-theme={p[2]}
              data-disabled={!p[3]}
              style={{
                backgroundImage: `url(${p[1]})` as string,
                backgroundColor: p[3] as string,
              }}
              onClick={() => {
                if (p[4]) {
                  startInitSurvey();
                } else {
                  NiceModal.show(QzNiceModal, {
                    width: 300,
                    title: '课程制作中',
                    children: (
                      <div style={{ textAlign: 'center' }}>
                        <div>
                          关注我们的微信公众号，
                          <br />
                          及时获取课程更新信息。
                        </div>
                        <img
                          style={{ width: 220, marginTop: 20 }}
                          alt="qrcode"
                          src="https://staticcdn.boyuai.com/user-assets/6074/3sVvqpPhmVgbu9hfYFKJZE/qrcode.svg"
                        />
                      </div>
                    ),
                  } as ModalProps);
                }
              }}
            >
              <div className={styles['learning-paths-item-title']}>
                {p[0]}
                <div className={styles['learning-paths-item-desc']}>
                  {p[4] ? p[5] : '加紧制作中，敬请期待...'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['learning-steps-wrapper']}>
        <div className={styles['section-header']}>
          <StackIcon />
          <span className={styles['section-title']}>学习方式</span>
        </div>
        <div className={styles['learning-steps']}>
          <LearningStep
            stepNum={1}
            title="学前测试"
            intro="进行一个简单的学前小测验，评估你的技能水平，青舟智学为你定制独家C++算法学习路径。"
            imgProps={{
              src: 'https://staticcdn.boyuai.com/user-assets/6074/yUt5Fwe3crfKFBgoAXV4HU/step-1.png',
            }}
          />
          <LearningStep
            stepNum={2}
            title="生成路径"
            intro="审阅你的学习路径，了解需要学习的课程和知识点。制定个人学习计划，加入学习小组，开启提升之旅。"
            imgProps={{
              src: 'https://staticcdn.boyuai.com/user-assets/6074/1qCxTBceh4NcsDbFpDwjkX/step-2.png',
            }}
          />
          <LearningStep
            stepNum={3}
            title="坚持学习"
            intro="学习课程内容，完成知识点练习。边学边练，一路闯关。好学，停不下来。"
            imgProps={{
              src: 'https://staticcdn.boyuai.com/user-assets/6074/QemN8ttwhHRuLQgDGumViy/step-3.png',
            }}
          />
          <LearningStep
            stepNum={4}
            title="完成学习"
            intro="完成全部知识点学习和CSP考试备赛课程，历史真题模拟练习，高分势在必得。"
            imgProps={{
              src: 'https://staticcdn.boyuai.com/user-assets/6074/AJdoBEiNXJbcECdTdZVirn/step-4.png',
            }}
          />
        </div>
      </div>
      <div className={styles['faq-wrapper']}>
        <div className={styles.faq}>
          <div className={styles['section-header']}>
            <FaqIcon />
            <span className={styles['section-title']}>FAQ</span>
          </div>
          {[
            {
              title: '上课的形式是什么，可以互动么？',
              intro:
                '互动课：课程以图文、视频构成，课程中间穿插习题，边学习边检验成果。你也可以参与课程群进行讨论和答疑。',
            },
            {
              title: '要学多久？',
              intro:
                '我们总共提供了 10~15 小时的课程内容，但智能学习系统会根据你已掌握的知识跳过对应的内容。所以最终的学习时长取决于学生原本的算法水平和学习效率。',
            },
          ].map((item, index) => (
            <div key={index} className={styles['faq-item']}>
              <div className={styles['faq-title']}>{item.title}</div>
              <div className={styles['faq-intro']}>{item.intro}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['try-us-wrapper']}>
        <div className={styles['try-us']}>
          <div className={styles['try-us-title']}>
            <span>来试一试</span>
            <span>青舟智学吧！</span>
          </div>
          <div>
            <Button
              variant="sub"
              className={styles['try-us-btn']}
              onClick={() => startInitSurvey()}
            >
              开始学习之旅
            </Button>
            <Button className={styles['try-us-btn']} variant="sub">
              下载企业版介绍
            </Button>
          </div>
        </div>
      </div>
      <div className={styles['footer-wrapper']}>
        <div className={styles.footer}>
          <div style={{ position: 'relative', width: '100%' }}>
            <img
              className={styles.qrcode}
              alt="qrcode"
              src="https://staticcdn.boyuai.com/user-assets/6074/3sVvqpPhmVgbu9hfYFKJZE/qrcode.svg"
            />
          </div>
          <div>
            <MetarkInverse />
          </div>
          <div>
            <div className={styles.copyright}>
              <span>
                © {new Date().getFullYear()} qingzhouzhixue.com 版权所有
                上海伯禹教育科技有限公司
              </span>
              &nbsp;|&nbsp;
              <a
                target="_blank"
                href="https://beian.miit.gov.cn/"
                rel="noreferrer"
              >
                沪ICP备18044415号-3
              </a>
            </div>
          </div>
        </div>
      </div>
      {initSurvey}
    </>
  );
};

const StepNode = () => {
  return (
    <svg
      width="23"
      height="13"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.4342 7.33352V8.33352H2.4342H6.85121C7.18527 9.09746 7.7069 9.76988 8.37293 10.2853C9.2493 10.9635 10.3261 11.3315 11.4342 11.3315C12.5423 11.3315 13.6191 10.9635 14.4955 10.2853C15.1615 9.76988 15.6831 9.09746 16.0172 8.33352H20.4342H21.4342V7.33352V5.33352V4.33352H20.4342H16.0172C15.6831 3.56957 15.1615 2.89715 14.4955 2.38174C13.6191 1.70355 12.5423 1.33557 11.4342 1.33557C10.3261 1.33557 9.2493 1.70355 8.37293 2.38174C7.7069 2.89715 7.18527 3.56957 6.85121 4.33352H2.4342H1.4342V5.33352V7.33352ZM12.1413 7.04062C11.9538 7.22816 11.6994 7.33352 11.4342 7.33352C11.169 7.33352 10.9146 7.22816 10.7271 7.04062C10.5396 6.85308 10.4342 6.59873 10.4342 6.33352C10.4342 6.0683 10.5396 5.81395 10.7271 5.62641C10.9146 5.43887 11.169 5.33352 11.4342 5.33352C11.6994 5.33352 11.9538 5.43887 12.1413 5.62641C12.3288 5.81395 12.4342 6.0683 12.4342 6.33352C12.4342 6.59873 12.3288 6.85308 12.1413 7.04062Z"
        fill="#B5B8CB"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
