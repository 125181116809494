import React from 'react';
import { Root, Image, Fallback } from '@radix-ui/react-avatar';

import './index.less';

export const Avatar: React.FC<{ src: string; fallback?: string }> = ({
  src,
  fallback,
}) => {
  return (
    <Root className="qz-avatar">
      <Image src={src} className="qz-avatar-image" />
      <Fallback className="qz-avatar-fallback">{fallback}</Fallback>
    </Root>
  );
};
