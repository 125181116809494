import { Tab, Tabs } from '@/atoms/tabs';
import { useCallback, useRef, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import MonacoEditor from 'react-monaco-editor';
import {
  ChangeHandler,
  EditorDidMount,
  MonacoEditorProps,
} from 'react-monaco-editor/lib/types';
import { isEmpty } from 'lodash';
import SplitterLayout from 'react-splitter-layout';
import { useExerciseContextWithCurrent } from '../exercise.context';
import styles from './oj-problem.module.less';
import { Markdown } from '../../markdown';
import { Separator } from '@/atoms/separator';
import { HintTrigger, OJHint } from '../hint';
import { OJTab, OJTabEnum } from '../types';
import { OJStatusText } from '@/typings/oj';
import { formatBytes } from '@/utils';
import { Button } from '@/atoms/button';
import { RestartSvg } from '@/icons/restart';
import { Modal } from '@/atoms/modal';
import { ResultNotification } from '../result-notification';
import { OJConsole, OJRunButton } from './oj-console';
import { OJExplainButton } from './explain';

const options: MonacoEditorProps['options'] = {
  overviewRulerBorder: false, // 避免拖拽时一直抖动，实在想要可以写个假的固定住
  minimap: {
    enabled: false,
  },
  // 以下来自波波鱼，有需要再开启
  // codeLens: false,
  // glyphMargin: false,
  // folding: false,
  // scrollbar: {
  //   vertical: 'hidden',
  //   handleMouseWheel: false,
  // },
  // renderValidationDecorations: 'off',
  // scrollBeyondLastLine: false,
  // wordWrap: 'on',
  // fixedOverflowWidgets: true,
};

const cppDefaultTemplate = `#include <bits/stdc++.h>
using namespace std; 

int main() {
    // 请补全代码，实现题目功能

    return 0;
}
`;

export const OJProblem: React.FC = () => {
  const { currentRecord, dispatch } = useExerciseContextWithCurrent();
  if (currentRecord.questionType !== 'oj-problem') {
    throw new Error('OJProblem 题型不匹配');
  }
  const { question } = currentRecord;
  const code =
    typeof currentRecord.userAnswers[0] === 'string'
      ? currentRecord.userAnswers[0]
      : currentRecord.userAnswers[0] ||
        currentRecord.question.template['CPP'] ||
        cppDefaultTemplate;
  const { lastSubmission, explanation, consoleExplanation, failedTestcase } =
    currentRecord;
  const editorRef = useRef<MonacoEditor>(null);
  const [restartModalOpen, toggleRestartModalOpen] = useState(false);

  const handleChange: ChangeHandler = useCallback(
    (code, _event) => {
      dispatch({ type: 'answerChange', data: { newAnswers: [code] } });
    },
    [dispatch],
  );

  const handleEditorDidMount: EditorDidMount = (editor, monaco) => {
    // console.log(editor, monaco);
  };

  const setTabKey = useCallback(
    (tab: OJTab) => {
      dispatch({ type: 'setTab', data: { tab } });
    },
    [dispatch],
  );

  const tabs: Tab<OJTab>[] = [
    {
      key: 'PROBLEM',
      title: OJTabEnum.PROBLEM,
      content: (
        <div className={styles.tabContent}>
          <div className={styles.recordTitle}>
            练习{currentRecord.index + 1}·代码题
          </div>
          <div className={styles.title}>{question.title}</div>
          <Markdown className={styles.desc}>{question.description}</Markdown>
          {!isEmpty(question.inputDescription) && (
            <Markdown className={styles.inputDesc}>
              {`输入描述：${question.inputDescription}`}
            </Markdown>
          )}
          {!isEmpty(question.outputDescription) && (
            <Markdown className={styles.outputDesc}>
              {`输出描述：${question.outputDescription}`}
            </Markdown>
          )}
          {question.samples.map((s, idx) => (
            <div key={idx} className={styles.samples}>
              <div className={styles.label}>示例 {idx + 1}：</div>
              <div className={styles.sample}>
                <div className={styles.input}>
                  输入：<pre>{isEmpty(s.input) ? '无输入' : s.input}</pre>
                </div>
                <div className={styles.output}>
                  输出：<pre>{s.output}</pre>
                </div>
              </div>
            </div>
          ))}
          <Separator style={{ marginTop: 32, marginBottom: 16 }} />
          <HintTrigger onTrigger={() => setTabKey('SOLUTION')} />
        </div>
      ),
    },
    {
      key: 'SUBMISSION',
      title: OJTabEnum.SUBMISSION,
      content: (
        <div className={styles.tabContent}>
          {lastSubmission ? (
            <>
              <div
                className={`${styles.submissionItem} ${styles.submissionResult}`}
              >
                <div>
                  运行结果：
                  <span
                    className={styles.submissionStatus}
                    data-status={lastSubmission.status}
                  >
                    {OJStatusText[lastSubmission.status]}
                  </span>
                </div>
                {explanation && (
                  <OJExplainButton
                    onClick={() => {
                      dispatch({
                        type: 'setOJExplanationMode',
                        data: 'submission',
                      });
                      dispatch({
                        type: 'setTab',
                        data: { tab: 'EXPLAINATION' },
                      });
                    }}
                  />
                )}
              </div>
              {lastSubmission.status === 'compile-error' &&
                lastSubmission.errorLines &&
                lastSubmission.errorLines.length > 0 && (
                  <div className={styles.submissionItem}>
                    错误行数：
                    {lastSubmission.errorLines.map((line, index) => {
                      return (
                        <span
                          style={{
                            marginRight: 10,
                            color: 'var(--secondary-red)',
                          }}
                        >
                          {`第${line}行${
                            index + 1 === lastSubmission.errorLines?.length
                              ? ''
                              : '、'
                          }`}
                        </span>
                      );
                    })}
                  </div>
                )}
              {lastSubmission.status === 'runtime-error' &&
                lastSubmission.exitCode && (
                  <div className={styles.submissionItem}>
                    错误提示：
                    <span style={{ color: 'var(--secondary-red)' }}>
                      exit code {lastSubmission.exitCode}
                    </span>
                  </div>
                )}
              {lastSubmission.timeCost > 0 && (
                <div className={styles.submissionItem}>
                  运行用时：{lastSubmission.timeCost} ms
                </div>
              )}
              {lastSubmission.memoryCost > 0 && (
                <div className={styles.submissionItem}>
                  内存消耗：{formatBytes(lastSubmission.memoryCost)}
                </div>
              )}
              {lastSubmission.totalTestCases > 0 && (
                <div className={styles.submissionItem}>
                  通过测试用例：{lastSubmission.passedTestCases} /{' '}
                  {lastSubmission.totalTestCases}
                </div>
              )}
              {/* 其它错误 */}
              {lastSubmission.errorInfo && (
                <div className={styles.submissionFailed}>
                  <div className={styles.submissionTestCase}>
                    <div className={styles.label}>输出</div>
                    <pre className={styles.data} data-error="true">
                      {lastSubmission.errorInfo}
                    </pre>
                  </div>
                </div>
              )}
              {/* testcase 未通过 */}
              {failedTestcase && (
                <div className={styles.submissionFailed}>
                  <div className={styles.submissionTestCase}>
                    <div className={styles.label}>输入</div>
                    <pre className={styles.data}>
                      {failedTestcase.inputPreview}
                      {failedTestcase.inputTruncated && (
                        <>
                          <br />
                          ...
                          <br />
                          <a
                            href={failedTestcase.inputFile}
                            target="_blank"
                            rel="noreferrer"
                          >
                            下载完整数据
                          </a>
                        </>
                      )}
                    </pre>
                  </div>
                  <div className={styles.submissionTestCase}>
                    <div className={styles.label}>输出</div>
                    <pre className={styles.data}>
                      {failedTestcase.userOutput}
                      {failedTestcase.userOutputTruncated && (
                        <>
                          <br />
                          ...
                          <br />
                        </>
                      )}
                    </pre>
                  </div>
                  <div className={styles.submissionTestCase}>
                    <div className={styles.label}>预期结果</div>
                    <pre className={styles.data}>
                      {failedTestcase.outputPreview}
                      {failedTestcase.outputTruncated && (
                        <>
                          <br />
                          ...
                          <br />
                          <a
                            href={failedTestcase.outputFile}
                            target="_blank"
                            rel="noreferrer"
                          >
                            下载完整数据
                          </a>
                        </>
                      )}
                    </pre>
                  </div>
                </div>
              )}
              <Separator style={{ marginTop: 32, marginBottom: 16 }} />
              <HintTrigger onTrigger={() => setTabKey('SOLUTION')} />
            </>
          ) : (
            <div className={styles.noSubmission}>你还没有提交代码</div>
          )}
        </div>
      ),
    },
  ];

  // 如果看过知识点
  if (currentRecord.viewStatus !== 'only-question') {
    tabs.push({
      key: 'SOLUTION',
      title: OJTabEnum.SOLUTION,
      content: (
        <div className={styles.tabContent}>
          <OJHint />
        </div>
      ),
    });
  }

  // 智能解析
  if (currentRecord.explanationMode) {
    tabs.push({
      key: 'EXPLAINATION',
      title: OJTabEnum.EXPLAINATION,
      content: (
        <div className={styles.tabContent}>
          {currentRecord.explanationMode === 'console' && consoleExplanation}
          {currentRecord.explanationMode === 'submission' && explanation}
        </div>
      ),
    });
  }

  return (
    <div className="exercise-oj-problem">
      <SplitterLayout
        // split="vertical"
        // // TODO: 这个库不怎么支持响应式布局，考虑换成 LeetCode 的开源库
        // minSize={0}
        // defaultSize="50%"
        customClassName="code-main"
        // // primary="second"
        onSecondaryPaneSizeChange={() => {
          if (!editorRef.current?.editor) return;
          editorRef.current.editor.layout();
        }}
      >
        <div className={styles.leftPanel}>
          <Tabs
            current={currentRecord.tab}
            onChange={(key) => {
              setTabKey(key as OJTab);
            }}
            tabs={tabs}
          />
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.editorToolbar}>
            {/* TODO: 由于缺少选择组件，暂时不支持切换语言 */}
            <div></div>
            <div className={styles.rightArea}>
              <RestartSvg
                className={styles.icon}
                onClick={() => toggleRestartModalOpen(true)}
              />
              <Modal
                className={styles.restartModal}
                title="重置代码"
                open={restartModalOpen}
                onOpenChange={(open) => toggleRestartModalOpen(open)}
              >
                你确认要重置吗？
                <div className={styles.restartModalBtns}>
                  <Button
                    variant="sub"
                    onClick={() => toggleRestartModalOpen(false)}
                  >
                    取消
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch({
                        type: 'answerChange',
                        data: {
                          newAnswers: [
                            currentRecord.question.template['CPP'] ||
                              cppDefaultTemplate ||
                              '',
                          ],
                        },
                      });
                      toggleRestartModalOpen(false);
                    }}
                  >
                    确认
                  </Button>
                </div>
              </Modal>
              <OJRunButton className={styles.runCodeBtn} />
            </div>
          </div>
          <div
            className={styles.editorWrapper}
            onClick={() => {
              NiceModal.hide(ResultNotification);
            }}
          >
            <MonacoEditor
              ref={editorRef}
              language="cpp" // TODO: 支持选择
              value={code}
              options={{
                ...options,
              }}
              onChange={handleChange}
              editorDidMount={handleEditorDidMount}
            />
            <OJConsole />
          </div>
        </div>
      </SplitterLayout>
    </div>
  );
};
