import { useState } from 'react';
import { Button } from '@/atoms/button';
import { Tab, Tabs } from '@/atoms/tabs';
import { EjectDown, EjectUp } from '@/icons/eject';
import {
  OJConosleTabEnum,
  OJConsoleTab,
  OJRunSubmission,
  OJStatus,
  OJStatusText,
} from '@/typings/oj';
import { PlayWithoutCircle } from '@/icons/play';
import { useExerciseContextWithCurrent } from '../exercise.context';
import styles from './oj-console.module.less';
import { submitOJRunCode, wait4OJRunCode } from '../exercise.service';
import { OJExplainButton } from './explain';
// import { getOJExplanation, OJExplainButton } from './explain';

export const OJRunButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { currentRecord, dispatch } = useExerciseContextWithCurrent();
  if (currentRecord.questionType !== 'oj-problem') {
    throw new Error('OJProblem 题型不匹配');
  }

  const [running, setRunning] = useState(false);

  return (
    <Button
      className={className}
      loading={running}
      disabled={!currentRecord.userAnswers[0]}
      variant="sub"
      onClick={async () => {
        if (running) return;
        setRunning(true);

        // 关闭解析
        dispatch({ type: 'setConsoleExplanation', data: '' });
        dispatch({ type: 'setOJExplanationMode', data: undefined });
        if (currentRecord.tab === 'EXPLAINATION') {
          dispatch({ type: 'setTab', data: { tab: 'PROBLEM' } });
        }

        // 初始化控制台
        dispatch({
          type: 'setConsoleSubmission',
          data: {
            status: OJStatus.JUDGING,
            stdStdOut: '',
            userExitCode: -1,
            userCpuTime: -1,
            userMemory: -1,
            userStdOut: '',
            userStdErr: '',
            userErrorInfo: '',
          },
        });
        dispatch({ type: 'toggleConsole', data: true });
        dispatch({ type: 'setConsoleTab', data: 'SUBMISSION' });

        try {
          // 测评
          const uuid = await submitOJRunCode(
            currentRecord.recordId,
            currentRecord.userAnswers[0] || '',
            currentRecord.language,
            currentRecord.consoleInput || '',
          );
          const oj = wait4OJRunCode(currentRecord.recordId, uuid);
          let submission: OJRunSubmission | null = null;
          while (!submission || submission.status === OJStatus.JUDGING) {
            const query = oj.next();
            submission = (await query).value;
            if (submission) {
              dispatch({
                type: 'setConsoleSubmission',
                data: submission,
              });
            } else {
              dispatch({
                type: 'setConsoleSubmission',
                data: {
                  status: OJStatus.SYSTEM_ERROR,
                  stdStdOut: '',
                  userExitCode: -1,
                  userCpuTime: -1,
                  userMemory: -1,
                  userStdOut: '',
                  userStdErr: '',
                  userErrorInfo: '',
                },
              });
              break;
            }
          }

          // 获取错误解析
          // if (submission?.userErrorInfo) {
          //   const explanation = await getOJExplanation({
          //     errorMessage: submission.userErrorInfo,
          //     code: currentRecord.userAnswers[0],
          //     language: currentRecord.language,
          //   });
          //   dispatch({ type: 'setConsoleExplanation', data: explanation });
          // }
        } finally {
          setRunning(false);
        }
      }}
    >
      <PlayWithoutCircle style={{ marginRight: 8 }} />
      <span>运行</span>
    </Button>
  );
};

export const OJConsole: React.FC = () => {
  const { dispatch, currentRecord } = useExerciseContextWithCurrent();
  if (currentRecord.questionType !== 'oj-problem') {
    throw new Error('[OJConsole] 题型异常');
  }

  if (currentRecord.consoleInput === undefined) {
    dispatch({
      type: 'setConsoleInput',
      data: currentRecord.question.samples[0].input,
    });
  }

  if (!currentRecord.consoleVisible) {
    return (
      <div
        className={styles.console}
        style={{ width: 74 }}
        onClick={() => dispatch({ type: 'toggleConsole', data: true })}
      >
        <div className={styles.consoleEjectUp}>
          <EjectUp />
          <span>控制台</span>
        </div>
      </div>
    );
  }

  const { consoleSubmission, consoleExplanation } = currentRecord;
  const submissionItems: { label: string; data: string }[] = [];
  let runCodeText = OJStatusText['judging'];
  if (consoleSubmission) {
    runCodeText = OJStatusText[consoleSubmission.status];
    if (currentRecord.consoleInput) {
      submissionItems.push({ label: '输入', data: currentRecord.consoleInput });
    }
    if (
      consoleSubmission.status === OJStatus.COMPILE_ERROR ||
      consoleSubmission.status === OJStatus.RUNTIME_ERROR
    ) {
      runCodeText = '运行出错';
      submissionItems.push({
        label: '输出',
        data: consoleSubmission.userErrorInfo,
      });
    } else if (
      consoleSubmission.status === OJStatus.ACCEPTED ||
      consoleSubmission.status === OJStatus.WRONG_ANSWER ||
      consoleSubmission.status === OJStatus.FINISHED
    ) {
      runCodeText = '已完成';
      submissionItems.push({
        label: '输出',
        data: consoleSubmission.userStdOut,
      });
      submissionItems.push({
        label: '预期结果',
        data: consoleSubmission.stdStdOut,
      });
    }
    if (consoleSubmission.userStdErr) {
      submissionItems.push({
        label: 'stderr',
        data: consoleSubmission.userStdErr,
      });
    }
  }

  const tabs: Tab<OJConsoleTab>[] = [
    {
      key: 'INPUT',
      title: OJConosleTabEnum.INPUT,
      content: (
        <div className={styles.consoleContent}>
          <textarea
            className={styles.userInput}
            value={currentRecord.consoleInput}
            onChange={(e) => {
              dispatch({ type: 'setConsoleInput', data: e.target.value });
            }}
          />
        </div>
      ),
    },
    {
      key: 'SUBMISSION',
      title: OJConosleTabEnum.SUBMISSION,
      content: (
        <div className={styles.consoleContent}>
          {consoleSubmission ? (
            <div
              className={styles.consoleSubmission}
              data-status={consoleSubmission.status}
            >
              <div className={styles.runCodeHeader}>
                <div className={styles.runCodeResult}>
                  <div className={styles.runCodeStatus}>{runCodeText}</div>
                  {consoleSubmission.userCpuTime > 0 && (
                    <div className={styles.runCodeTime}>
                      执行用时：{consoleSubmission.userCpuTime} ms
                    </div>
                  )}
                </div>
                {consoleExplanation && (
                  <OJExplainButton
                    onClick={() => {
                      dispatch({
                        type: 'setOJExplanationMode',
                        data: 'console',
                      });
                      dispatch({
                        type: 'setTab',
                        data: { tab: 'EXPLAINATION' },
                      });
                    }}
                  />
                )}
              </div>
              {submissionItems.map((item) => (
                <div className={styles.runCodeItem}>
                  <span>{item.label}</span>
                  <pre data-label={item.label}>{item.data}</pre>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.consoleNoSubmission}>
              <span>你还没有运行程序</span>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.console}>
      <Tabs
        current={currentRecord.consoleTab || 'INPUT'}
        onChange={(key) => {
          dispatch({ type: 'setConsoleTab', data: key as OJConsoleTab });
        }}
        tabs={tabs}
        tabRight={
          <EjectDown
            width="12"
            height="12"
            onClick={() => dispatch({ type: 'toggleConsole', data: false })}
          />
        }
      />
    </div>
  );
};
