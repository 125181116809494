/**
 * TODO: 考虑和学前测评复用？
 */
import { Button } from '@/atoms/button';
import { Separator } from '@/atoms/separator';
import { Markdown } from '@/components/markdown';
import remote from '@/utils/remote';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styles from './index.module.less';

export const ExamAssessment = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [assessment, setAssessment] = useState<any>();
  useEffect(() => {
    remote.$get(`/assessment/${id}`).then((data) => {
      setAssessment(data);
    });
  }, [id]);

  if (!assessment) {
    return null;
  }

  return (
    <div className={styles.page}>
      <div className={styles.panel}>
        <div className={styles['pannel-inner']}>
          <div className={styles.title}>{assessment.title}</div>
          <div className={styles.intro}>
            <span>预估使用 {assessment.duration} 分钟</span>
            <Separator orientation="vertical" style={{ margin: '0 6px' }} />
            <span>
              共有 {assessment.questionMin}~{assessment.questionMax} 道选择题
            </span>
          </div>
          <Markdown>{assessment.description}</Markdown>
          <Button
            style={{ marginTop: 40 }}
            size="small"
            onClick={() => {
              history.push(`/exam/${id}/session`);
            }}
          >
            开始测评
          </Button>
        </div>
      </div>
    </div>
  );
};
