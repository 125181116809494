export const EjectUp: React.FC<SVGProps> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#B0B2B7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.14779 10.0535H12.3466L8.24719 3.90487L4.14779 10.0535ZM8.64723 1.03731L15.4421 11.2296C15.4902 11.302 15.5179 11.3861 15.522 11.473C15.5262 11.5598 15.5067 11.6462 15.4657 11.7229C15.4246 11.7996 15.3635 11.8637 15.2889 11.9084C15.2143 11.9531 15.129 11.9767 15.042 11.9767H1.45236C1.36538 11.9767 1.28005 11.9531 1.20545 11.9084C1.13085 11.8637 1.06977 11.7996 1.02872 11.7229C0.987671 11.6462 0.968186 11.5598 0.972341 11.473C0.976497 11.3861 1.00414 11.302 1.05232 11.2296L7.84715 1.03731C7.89106 0.97146 7.95055 0.917467 8.02034 0.880124C8.09012 0.842781 8.16804 0.823242 8.24719 0.823242C8.32634 0.823242 8.40426 0.842781 8.47405 0.880124C8.54383 0.917467 8.60332 0.97146 8.64723 1.03731ZM1.51582 13.9H14.9786C15.2336 13.9 15.4782 14.0013 15.6585 14.1816C15.8389 14.362 15.9402 14.6066 15.9402 14.8616C15.9402 15.1167 15.8389 15.3612 15.6585 15.5416C15.4782 15.7219 15.2336 15.8232 14.9786 15.8232H1.51582C1.26078 15.8232 1.01619 15.7219 0.835853 15.5416C0.655513 15.3612 0.554199 15.1167 0.554199 14.8616C0.554199 14.6066 0.655513 14.362 0.835853 14.1816C1.01619 14.0013 1.26078 13.9 1.51582 13.9Z" />
    </svg>
  );
};

export const EjectDown: React.FC<SVGProps> = (props) => {
  return <EjectUp {...props} transform="rotate(180)" />;
};
