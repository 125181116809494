/**
 * TODO: 重构为 exam 页面，考虑和学前测评复用？
 */
import { Button } from '@/atoms/button';
import { Checkbox } from '@/atoms/checkbox';
import { IProgressProps, Progress } from '@/atoms/progress';
import { Modal } from '@/atoms/modal';
import { IRadioOptionProps, RadioGroup } from '@/atoms/radio-group';
import { Separator } from '@/atoms/separator';
import { Loading } from '@/atoms/loading';
import { CircleChevronLeft } from '@/icons/chevron';
import { Markdown } from '@/components/markdown';
import { removeSessionUuid, retrieveSessionUuid } from '@/services/assessment';
import remote from '@/utils/remote';
import {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import * as RadixAccordion from '@radix-ui/react-accordion';
import Timer from 'easytimer.js';
import { FeedbackButton } from '@/components/feedback';
import { ReactComponent as TimerSvg } from '@/icons/timer/icon.svg';
import { AppContext } from '../../App';

import styles from './index.module.less';
import Summary, { SummaryResp } from './summary';
import { useQuery } from '@/hooks';

type RecordResp = {
  question: {
    id: number;
    uuid: string;
    // platformId?: number;
    type: 'select';
    title: string;
    options: { text: string }[];
    countdown: number;
    hasMultiAnswers: boolean;
  };
  questionRemaining: number;
  progress: number;
  recordId: number;
  index: number;
};

const ExamAssessmentLayout: React.FC<{
  sessionUuid: string;
  feedbackContentId: number | string;
  progress?: IProgressProps;
  countdown: number | null;
  countdownKey: string;
  onCountdownAchieved: (payload: object) => any;
  btnText: string;
  btnDisabled: boolean;
  btnOnClick: MouseEventHandler<HTMLButtonElement>;
}> = ({
  children,
  sessionUuid,
  feedbackContentId,
  progress,
  countdown,
  countdownKey,
  onCountdownAchieved,
  btnText,
  btnDisabled,
  btnOnClick,
}) => {
  const { user } = useContext(AppContext);
  const [countdownOpen, setCountdownOpen] = useState(true);
  const [countdownText, setCountdownText] = useState<string>();
  const [countdownWarning, toggleCountdownWarning] = useState(false);
  const onCountdownAchievedRef = useRef<(payload: object) => any>((payload) => {
    // eslint-disable-next-line no-console
    console.log('onCountdownAchieved', payload);
  });

  useEffect(() => {
    onCountdownAchievedRef.current = onCountdownAchieved;
  }, [onCountdownAchieved]);

  // 【倒计时并不严格】初始值有 2 个来源，分别是 question.countdown 和 sessionStorage.countdownKey
  // 缓存主要用于刷新页面后的倒计时继续，使用 sessionStorage 简单实现，避免缓存错误影响用户答题
  useEffect(() => {
    if (!countdown || !countdownKey) return;

    let startSeconds = countdown;
    const lastTime = sessionStorage.getItem(countdownKey);
    if (lastTime) {
      const last = new Date(lastTime);
      const now = new Date();
      const diff = Math.floor((now.getTime() - last.getTime()) / 1000);
      startSeconds = Math.max(countdown - diff, 1); // 刷新页面后超时则直接提交
    }

    const timer = new Timer();
    timer.start({ countdown: true, startValues: { seconds: startSeconds } });
    setCountdownText(timer.getTimeValues().toString().slice(3));
    if (!lastTime) {
      sessionStorage.setItem(countdownKey, new Date().toString());
    }
    timer.addEventListener('secondsUpdated', function () {
      const values = timer.getTimeValues();
      toggleCountdownWarning(values.minutes === 0 && values.seconds <= 15);
      setCountdownText(values.toString().slice(3));
    });
    timer.addEventListener('targetAchieved', function () {
      toggleCountdownWarning(false);
      onCountdownAchievedRef.current({ countdownAchieved: true });
    });
    return () => {
      timer.removeAllEventListeners();
      timer.stop();
    };
  }, [countdown, countdownKey]);

  return (
    <div className={styles.page}>
      <div className={styles['question-panel']}>
        <div className={styles['question-pannel-inner']}>{children}</div>

        <div className={styles.footer}>
          {progress && <Progress style={{ height: 6 }} {...progress} />}
          <div className={styles['footer-bar']}>
            <div className={styles['footer-bar-left']}>
              <FeedbackButton
                from="首次测评"
                feedbackTypeOptions={[
                  '内容有错误',
                  '描述不清晰',
                  '功能问题',
                  '其他问题',
                ]}
                contentType="Quiz 题"
                contentId={feedbackContentId}
                sessionUuid={sessionUuid}
                phone={user?.phone}
              />
              <Separator
                style={{ margin: '0 6px 0 16px' }}
                orientation="vertical"
              />
              <RadixAccordion.Root
                type="single"
                value={countdownOpen || countdownWarning ? 'countdown' : ''}
                onValueChange={(value) => {
                  setCountdownOpen(!!value);
                }}
                collapsible={!countdownWarning}
              >
                <RadixAccordion.Item
                  className={styles.countdown}
                  value="countdown"
                  data-warning={countdownWarning}
                >
                  <RadixAccordion.Trigger className={styles['countdown-inner']}>
                    <TimerSvg
                      className={styles['countdown-timer']}
                      data-warning={countdownWarning}
                    />
                    <RadixAccordion.Content
                      className={styles['countdown-text']}
                      data-warning={countdownWarning}
                    >
                      本题倒计时 {countdownText}
                      {!countdownWarning && (
                        <CircleChevronLeft
                          className={styles['countdown-hide']}
                        />
                      )}
                    </RadixAccordion.Content>
                  </RadixAccordion.Trigger>
                </RadixAccordion.Item>
              </RadixAccordion.Root>
            </div>
            <Button
              style={{ width: 160, height: 40 }}
              disabled={btnDisabled}
              onClick={btnOnClick}
            >
              {btnText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const NO_IDEA = 'no-idea';

const selectOption = (
  recordId: number | string,
  sessionUuid: string,
  userAnswers: string[],
) => {
  return remote.$post(`/assessment-session-record/${recordId}/select-record`, {
    sessionUuid,
    userAnswers,
  });
};

export const ExamAssessmentSession: React.FC<{
  hasNoIdeal?: boolean;
  isCert?: boolean;
}> = ({ hasNoIdeal = true, isCert }) => {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { id: assessmentId } = useParams<{ id: string }>();
  const [sessionUuid, setSessionUuid] = useState<string | null>();
  useEffect(() => {
    setSessionUuid(retrieveSessionUuid(assessmentId));
  }, [assessmentId]);

  const [hasFinished, setHasFinished] = useState(false);
  const [summary, setSummary] = useState<SummaryResp>();
  const [record, setRecord] = useState<{ initialized: boolean } & RecordResp>({
    initialized: false,
    question: null as any,
    questionRemaining: 0,
    progress: 0,
    recordId: 0,
    index: 0,
  });
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [noIdea, setNoIdea] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasConfirmGenerate = useQuery().get('has-generate');

  const fetchQuestion = useCallback(
    async (payload) => {
      const data: RecordResp = await remote.$post(
        '/assessment-session-record',
        payload,
      );
      if (data) {
        setRecord({
          initialized: true,
          question: data.question,
          questionRemaining: data.questionRemaining,
          progress: data.progress,
          recordId: data.recordId,
          index: data.index,
        });
        return data;
      } else {
        // 没有新题
        if (hasConfirmGenerate) {
          // 已经进入过完成评测页面，直接进入大纲
          setHasFinished(true);
        } else {
          // 第一次完成，进入完成评测页面，获取summary
          const summary: SummaryResp = await remote.$get(
            `/assessment-session/${payload.sessionUuid}/summary`,
          );
          setSummary(summary);
        }
      }
    },
    [hasConfirmGenerate],
  );

  const { allPath } = useContext(AppContext);
  const currentPath =
    allPath &&
    allPath.find((item) => {
      return item.assessmentId === Number(assessmentId);
    });
  const learningPathId = currentPath?.id || -1;

  useEffect(() => {
    if (!sessionUuid) return;
    fetchQuestion({ sessionUuid, pathId: learningPathId });
  }, [fetchQuestion, sessionUuid, learningPathId]);

  // 已登录用户完成测评后自动绑定 session & 生成路径 & 进入路径页
  useEffect(() => {
    if (!sessionUuid) return;
    if (!hasFinished) return;
    if (!user) return;
    (async function () {
      await remote.$patch(`/assessment-session/${sessionUuid}/user`);
      await remote.$put(`/learning-path/${learningPathId}`);
      removeSessionUuid(assessmentId);
      history.push(`/learning-path/${learningPathId}?first-time=true`);
    })();
  }, [learningPathId, assessmentId, hasFinished, history, sessionUuid, user]);

  // 阻止路径的生成，等待脚本分组 & 生成
  const [canEnter, setCanEnter] = useState(false);
  useEffect(() => {
    remote
      .$get(`/learning-path/${learningPathId}/can-entry`)
      .then((data) => setCanEnter(!!data));
  }, [learningPathId]);

  if (!sessionUuid) {
    return <div>暂无进行中的测评</div>;
  }

  if (hasFinished) {
    if (user) {
      // 等待 effect 完成后自动进入路径
      return <Loading />;
    }
    return (
      <Modal open hideCloseIcon className={styles['login-modal']}>
        <div className={styles['login-panel']}>
          <img
            alt="greetings"
            src="https://staticcdn.boyuai.com/user-assets/6074/UZ7vKYsT9CgD8VSpPXjD9Z/astronaut.svg"
          />
          <div>
            <p className={styles.tip}>
              为了避免数据的丢失
              <br />
              青舟建议你
              <br />
              <b className={styles['bold-tip']}>先登录</b>，再生成学习路径
            </p>
            <Button
              style={{ width: 160 }}
              onClick={() => {
                history.push({
                  search: 'has-generate=true',
                });
                remote.requestLogin();
              }}
            >
              点击登录
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  if (summary) {
    return (
      <Summary
        summary={summary}
        canEnter={canEnter}
        sessionUuid={sessionUuid}
        isCert={isCert}
        onClickGeneratePath={() => setHasFinished(true)}
      />
    );
  }

  if (!record.initialized) {
    return <Loading />;
  }

  if (record.question.type !== 'select') {
    return <div>{record.question.type} is not implemented.</div>;
  }

  const submit = (payload = {}) => {
    setLoading(true);
    fetchQuestion({
      pathId: learningPathId,
      sessionUuid,
      prevRecordId: record.recordId,
      prevUserAnswers: userAnswers,
      prevUserNoIdea: noIdea,
      ...payload,
    }).then(() => {
      setUserAnswers([]);
      setNoIdea(false);
      setLoading(false);
      window.scrollTo({ top: 0, left: 0 });
    });
  };

  return (
    <ExamAssessmentLayout
      sessionUuid={sessionUuid}
      // feedbackContentId={`${record.question.id}/${record.question.platformId}`}
      feedbackContentId={`${record.question.id}`}
      progress={{ value: record.progress }}
      countdown={loading ? null : record.question.countdown}
      countdownKey={`assessment-record-${record.recordId}-countdown`}
      onCountdownAchieved={submit}
      btnText={record.questionRemaining === 0 ? '提交' : '下一题'}
      btnDisabled={loading || (!userAnswers.length && !noIdea)}
      btnOnClick={() => submit()}
    >
      <div className={styles['question-title']}>
        <Markdown>{`**题目 ${record.index + 1} :** ${
          record.question.title
        }`}</Markdown>
      </div>

      {record.question.hasMultiAnswers ? (
        record.question.options.map((option: any, index: number) => {
          const value = index.toString();

          return (
            <div style={{ marginLeft: 16 }}>
              <Checkbox
                label={<Markdown>{option.text}</Markdown>}
                value={value}
                checked={userAnswers.indexOf(value) !== -1}
                onCheckedChange={(checked) => {
                  let newUserAnswers = [];
                  if (checked) {
                    newUserAnswers = userAnswers.concat([value]);
                  } else {
                    newUserAnswers = userAnswers.filter((ans) => ans !== value);
                  }

                  setUserAnswers(newUserAnswers);
                  setNoIdea(false);
                  selectOption(record.recordId, sessionUuid, newUserAnswers);
                }}
              />
            </div>
          );
        })
      ) : (
        <RadioGroup
          style={{ marginLeft: 64 }}
          value={userAnswers[0] || ''}
          onValueChange={(value) => {
            setUserAnswers([value]);
            setNoIdea(false);
            selectOption(record.recordId, sessionUuid, [value]);
          }}
          options={record.question.options.map(
            (option: any, index: number) =>
              ({
                label: <Markdown>{option.text}</Markdown>,
                value: index.toString(),
              } as IRadioOptionProps),
          )}
        />
      )}
      <Separator style={{ marginTop: 20, marginBottom: 20 }} />
      {hasNoIdeal && (
        <RadioGroup
          style={{ marginLeft: 64, marginBottom: 50 }}
          value={noIdea ? NO_IDEA : ''}
          onValueChange={() => {
            setUserAnswers([]);
            setNoIdea(true);
            selectOption(record.recordId, sessionUuid, [NO_IDEA]);
          }}
          options={[{ label: '我不确定', value: NO_IDEA }]}
        />
      )}
    </ExamAssessmentLayout>
  );
};
