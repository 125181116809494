import { Checkbox } from '@/atoms/checkbox';
import { RadioGroup } from '@/atoms/radio-group';
import { Separator } from '@/atoms/separator';
import { Tabs } from '@/atoms/tabs';

import { WcsQUiz } from '@/typings/wcs';
import React from 'react';

import { Markdown } from '../markdown';

import styles from './index.module.less';

type Props = {
  quiz: WcsQUiz;
  userAnswer: string[];
  title: string;
  disabled?: boolean;
  showAnswerStatus?: boolean;
  score?: number;
  onUserAnswersChange?: (newUserAnswer: string[]) => void;
};

const Quiz = ({
  quiz,
  userAnswer = [],
  disabled = false,
  title,
  score,
  showAnswerStatus = false,
  onUserAnswersChange,
}: Props) => {
  return (
    <Tabs
      current={'QUESTION'}
      tabs={[
        {
          key: 'QUESTION',
          title: '题目',
          content: (
            <div className={styles.body}>
              <div className={styles.title}>
                {title}
                {score && <span className={styles.titleScore}>{score}分</span>}
              </div>

              <Markdown>{quiz.title}</Markdown>
              <Separator style={{ margin: '20px 0' }} />

              {quiz.hasMultiAnswers ? (
                quiz.options.map((o, index) => {
                  const value = index.toString();
                  const answers = quiz.answers || [];

                  let status: 'correct' | 'wrong' | 'miss' | undefined;

                  if (showAnswerStatus) {
                    if (userAnswer.includes(value) && answers.includes(value)) {
                      // 用户选择该选项，且选项正确，显示正确答案
                      status = 'correct';
                    } else if (
                      userAnswer.includes(value) &&
                      !answers.includes(value)
                    ) {
                      // 用户选择该选项，但选项错误
                      status = 'wrong';
                    } else if (
                      !userAnswer.includes(value) &&
                      answers.includes(value)
                    ) {
                      // 用户未选择该选项，但选项正确
                      status = 'miss';
                    }
                  }

                  return (
                    <React.Fragment key={index}>
                      <Checkbox
                        label={
                          <div>
                            {String.fromCharCode(65 + index)}:
                            <br />
                            <Markdown>{o.text}</Markdown>
                          </div>
                        }
                        value={value}
                        status={status}
                        itemAlign="flex-start"
                        disabled={disabled}
                        checked={userAnswer.indexOf(value) !== -1}
                        onCheckedChange={(checked) => {
                          let newUserAnswer = [];
                          if (checked) {
                            newUserAnswer = userAnswer.concat([value]);
                          } else {
                            newUserAnswer = userAnswer.filter(
                              (ans) => ans !== value,
                            );
                          }
                          if (onUserAnswersChange)
                            onUserAnswersChange(newUserAnswer);
                        }}
                      />
                      <Separator style={{ margin: '20px 0' }} />
                    </React.Fragment>
                  );
                })
              ) : (
                <RadioGroup
                  value={userAnswer[0]}
                  disabled={disabled}
                  separator
                  onValueChange={(newAnswer: string) => {
                    if (onUserAnswersChange) onUserAnswersChange([newAnswer]);
                  }}
                  itemAlign="flex-start"
                  options={quiz.options.map((o, index) => {
                    const value = index.toString();
                    const answers = quiz.answers || [];
                    let status: undefined | 'wrong' | 'correct';

                    if (showAnswerStatus && userAnswer[0] === value) {
                      if (userAnswer[0] === answers[0]) {
                        status = 'correct';
                      } else {
                        status = 'wrong';
                      }
                    }
                    return {
                      value,
                      status,
                      label: (
                        <div>
                          {String.fromCharCode(65 + index)}:
                          <br />
                          <Markdown>{o.text}</Markdown>
                        </div>
                      ),
                    };
                  })}
                />
              )}
            </div>
          ),
        },
      ]}
    />
  );
};

export default Quiz;
