import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { Separator } from '@/atoms/separator';
import React, { CSSProperties, useEffect, useState } from 'react';

import './index.less';

export interface IRadioOptionProps {
  label: React.ReactNode;
  value: string;
  status?: 'wrong' | 'correct';
  disabled?: boolean;
}

export const RadioGroup: React.FC<{
  className?: string;
  style?: CSSProperties;
  options: IRadioOptionProps[];
  value?: RadixRadioGroup.RadioGroupProps['value'];
  onValueChange: RadixRadioGroup.RadioGroupProps['onValueChange'];
  bordered?: boolean;
  separator?: boolean;
  itemAlign?: 'flex-start' | 'center';
  disabled?: boolean;
  variant?: 'default' | 'cards';
}> = ({
  className,
  style,
  options,
  value: outerValue,
  onValueChange,
  bordered = false,
  separator = false,
  itemAlign = 'center',
  variant = 'default',
  disabled = false,
}) => {
  const [innerValue, setInnerValue] = useState<string>();
  const value = outerValue ?? innerValue;
  useEffect(() => {
    if (!outerValue) {
      setInnerValue('');
    }
  }, [outerValue]);

  return (
    <RadixRadioGroup.Root
      className={`qz-radio-group ${className || ''}`}
      style={style}
      value={value}
      onValueChange={(newValue) => {
        setInnerValue(newValue);
        onValueChange?.(newValue);
      }}
      data-variant={variant}
    >
      {options.map((o) => {
        const selectStatus = o.value === value ? 'active' : 'inactive';
        // 这个组件不支持单独使用
        return (
          // o.value 作为 key 唯一性不够强，不断做题时可能总是 index，加上随机数缓解
          <div
            key={`${o.value}-${Math.random() * 100}`}
            className="qz-radio-wrapper"
          >
            <RadixRadioGroup.Item
              id={o.value}
              value={o.value}
              className="qz-radio-item"
              data-bordered={bordered}
              data-disabled={Boolean(disabled || o.disabled)}
              data-status={o.status ?? selectStatus}
              style={{ alignItems: itemAlign }}
              disabled={Boolean(disabled || o.disabled)}
            >
              <RadixRadioGroup.Indicator
                forceMount
                className="qz-radio-indicator"
              >
                <IndicatorIcon status={o.status ? o.status : selectStatus} />
              </RadixRadioGroup.Indicator>
              <label htmlFor={o.value} className="qz-radio-label">
                {o.status === 'correct' && (
                  <div
                    style={{
                      color: 'var(--secondary-green)',
                      lineHeight: '25px',
                    }}
                  >
                    回答正确
                  </div>
                )}
                {o.status === 'wrong' && (
                  <div
                    style={{
                      color: 'var(--secondary-red)',
                      lineHeight: '25px',
                    }}
                  >
                    回答错误
                  </div>
                )}
                {o.label}
              </label>
            </RadixRadioGroup.Item>
            {separator && <Separator style={{ margin: '20px 0' }} />}
          </div>
        );
      })}
    </RadixRadioGroup.Root>
  );
};

const IndicatorIcon = ({
  status = 'inactive',
}: {
  status?: 'wrong' | 'correct' | 'active' | 'inactive';
}) => {
  if (status === 'inactive') {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="5"
          width="15"
          height="15"
          rx="7.5"
          fill="var(--system-background)"
          stroke="var(--system-icon)"
        />
      </svg>
    );
  }
  let outer = 'var(--primary-blue)';
  let inner = 'var(--primary-blue)';
  if (status === 'correct') {
    outer = 'var(--secondary-green-80)';
    inner = 'var(--secondary-green)';
  } else if (status === 'wrong') {
    outer = 'var(--secondary-red-80)';
    inner = 'var(--secondary-red)';
  }
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="5"
        width="15"
        height="15"
        rx="7.5"
        fill="var(--system-background)"
        stroke={outer}
      />
      <rect x="7.5" y="7.5" width="10" height="10" rx="5" fill={inner} />
    </svg>
  );
};
