export const Jump = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0096 10.4843C12.9112 8.93167 10.2943 8.24903 7.70485 8.57875C5.11537 8.90847 2.75312 10.2251 1.11072 12.2541C0.977231 12.419 0.914724 12.6302 0.93695 12.8412C0.959176 13.0522 1.06431 13.2457 1.22923 13.3792C1.39416 13.5127 1.60535 13.5752 1.81636 13.553C2.02736 13.5307 2.2209 13.4256 2.35439 13.2607C3.72965 11.561 5.70505 10.4544 7.87273 10.1691C10.0404 9.88391 12.2348 10.4419 14.003 11.728L11.1592 15.2413L20.0391 15.5716L17.8659 6.95542L15.0096 10.4843Z"
        fill="#B0B2B7"
      />
    </svg>
  );
};
