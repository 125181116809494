import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

// https://reactrouter.com/web/example/query-parameters
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as any;
}
