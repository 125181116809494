import { Button, IButtonProps } from '@/atoms/button';
import { CPUIcon } from '@/icons/cpu';
import { OJLanguage } from '@/typings/oj';
import remote from '@/utils/remote';

export const getOJExplanation = async (payload: {
  errorMessage: string;
  code: string;
  language: OJLanguage;
}) => {
  const { explain } = await remote.$post(
    '/assessment-session-record/error-explain',
    payload,
  );
  return explain;
};

/**
 * 用于打开智能解析 tab
 */
export const OJExplainButton: React.FC<IButtonProps> = (props) => {
  return (
    <Button
      {...props}
      variant="text"
      style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}
    >
      <CPUIcon /> <span style={{ marginLeft: 4 }}>智能解析</span>
    </Button>
  );
};
