import * as RadixCheckbox from '@radix-ui/react-checkbox';
import React, { useEffect, useState } from 'react';

import './index.less';

export const Checkbox: React.FC<{
  label?: React.ReactNode;
  value: string;
  status?: 'correct' | 'wrong' | 'miss';
  checked?: boolean;
  disabled?: boolean;
  itemAlign?: 'flex-start' | 'center';
  onCheckedChange?: (checked: boolean) => void;
}> = ({
  label,
  value,
  status,
  onCheckedChange,
  checked: outerChecked,
  itemAlign = 'center',
  disabled = false,
}) => {
  const [innerChecked, setInnerChecked] = useState<boolean>();
  const checked = outerChecked ?? innerChecked;
  useEffect(() => {
    if (!outerChecked) {
      setInnerChecked(false);
    }
  }, [outerChecked]);

  return (
    <RadixCheckbox.Root
      key={`${value}-${Math.random() * 100}`}
      className="qz-checkbox"
      style={{ alignItems: itemAlign }}
      checked={checked}
      onCheckedChange={(newChecked) => {
        if (newChecked === 'indeterminate') return;
        onCheckedChange?.(newChecked);
        setInnerChecked(newChecked);
      }}
      data-disabled={disabled}
      disabled={disabled}
    >
      <RadixCheckbox.Indicator forceMount className="qz-checkbox-indicator">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="5"
            y="5"
            width="15"
            height="15"
            rx="3.5"
            stroke="currentColor"
          />
          {checked && (
            <rect
              x="7.5"
              y="7.5"
              width="10"
              height="10"
              rx="2"
              fill="currentColor"
            />
          )}
        </svg>
      </RadixCheckbox.Indicator>
      <label className="qz-checkbox-label" htmlFor={value}>
        {status === 'correct' && (
          <div
            style={{
              color: 'var(--secondary-green)',
              lineHeight: '25px',
            }}
          >
            回答正确
          </div>
        )}
        {status === 'wrong' && (
          <div
            style={{
              color: 'var(--secondary-red)',
              lineHeight: '25px',
            }}
          >
            回答错误
          </div>
        )}
        {status === 'miss' && (
          <div
            style={{
              color: 'var(--secondary-red)',
              lineHeight: '25px',
            }}
          >
            未选择错误
          </div>
        )}
        {label || value}
      </label>
    </RadixCheckbox.Root>
  );
};

// interface Props {
//   options: {
//     label: string;
//     value: string;
//     disabled?: boolean;
//   }[];
//   defaultValue?: string[];
//   value?: string[];
//   onChange?: (checkedValue: string[]) => void;
// }

// export const CheckBoxGroup: React.FC<Props> = ({
//   options,
//   value: outerValue,
//   defaultValue,
//   onChange,
// }) => {
//   const [innerValue, setInnerValue] = useState(defaultValue || []);
//   const controlled = !!outerValue;
//   const value = outerValue || innerValue;
//   return (
//     <div className="qz-checkbox-group">
//       {options.map((o) => (
//         <Checkbox
//           key={o.label}
//           label={o.label}
//           value={o.value}
//           checked={value && value?.indexOf(o.value) > 0}
//           onCheckedChange={(checked) => {
//             const inValue = value && value?.indexOf(o.value) > 0;
//             if (checked) {
//               if (!inValue) {
//                 if (onChange) onChange([...value, o.label]);
//                 if (!controlled) {
//                   setInnerValue((pre) => [...pre, o.label]);
//                 }
//               }
//             } else {
//               if (inValue) {
//                 if (onChange) onChange(value.filter((l) => l !== o.label));
//                 if (!controlled) {
//                   setInnerValue((pre) => pre.filter((l) => l !== o.label));
//                 }
//               }
//             }
//           }}
//         />
//       ))}
//     </div>
//   );
// };
