import { Button } from '@/atoms/button';
import { ReactComponent as Clock } from '@/icons/clock/icon.svg';

import styles from './exams.module.less';
import { QZFormalTimeRange } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { UserExam } from '@/typings/exam';
import remote from '@/utils/remote';
import { Link } from 'react-router-dom';

type Props = {};

const AccountExams = (props: Props) => {
  const [exams, setExams] = useState<UserExam[]>([]);
  const getExams = useCallback(async () => {
    const exams: UserExam[] = await remote.$get('/user/exams');
    setExams(exams);
  }, []);
  useEffect(() => {
    getExams();
  }, [getExams]);
  return (
    <div className={styles.page}>
      <div className={styles.header}>我的考试</div>
      <div className={styles.exams}>
        {exams.length === 0 && (
          <div className={styles.examRow} style={{ justifyContent: 'center' }}>
            暂无考试
          </div>
        )}
        {exams.map((e) => {
          let timeText = '';
          if (e.examTimeType === 'anyTime') {
            timeText = '任意时间';
          } else {
            timeText = QZFormalTimeRange(e.examStartTime!, e.examEndTime!);
          }

          let action;
          if (e.userExam.status === 'scored') {
            action = <Button size="small">查看成绩</Button>;
          } else if (e.userExam.status === 'applied') {
            action = (
              <Button size="small" variant="sub">
                申请中
              </Button>
            );
          } else if (e.userExam.status === 'submitted') {
            action = (
              <Button size="small" outline>
                待判分
              </Button>
            );
          } else if (e.userExam.status === 'withdraw') {
            action = (
              <Button size="small" variant="sub">
                已放弃
              </Button>
            );
          } else {
            if (e.examTimeType === 'anyTime') {
              timeText = '任意时间';
              if (e.userExam.status === 'unstarted') {
                action = <Button size="small">未开始</Button>;
              } else if (e.userExam.status === 'ongoing') {
                action = <Button size="small">进行中</Button>;
              }
            } else {
              const now = new Date().getTime();
              if (now < new Date(e.examStartTime!).getTime()) {
                action = (
                  <Button size="small" outline>
                    未开始
                  </Button>
                );
              } else if (now > new Date(e.examEndTime!).getTime()) {
                action = (
                  <Button size="small" outline>
                    已结束
                  </Button>
                );
              } else {
                action = <Button size="small">进行中</Button>;
              }
            }
          }
          return (
            <div key={e.id} className={styles.examRow}>
              <div className={styles.examRowTitle}>{e.title}</div>
              <div className={styles.examRowTime}>
                <Clock />
                考试时间： {timeText}
              </div>
              <div className={styles.examRowAction}>
                <Link to={`/cert-exam/${e.id}`}>{action}</Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountExams;
