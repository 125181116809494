import { useParams } from 'react-router';
import ReactEcharts from 'echarts-for-react';
import { ReactComponent as BackSvg } from '@/icons/back/icon.svg';

import styles from './index.module.less';
import { ScoreDimension, UserExam, UserExamPaper } from '@/typings/exam';
import { Separator } from '@/atoms/separator';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@/atoms/button';
import CertView from '@/components/cert-viewer';
import { AppContext } from '../../App';
import { useHistory } from 'react-router-dom';
import { Loading } from '@/atoms/loading';
import remote from '@/utils/remote';
import { getExamRule } from '@/utils/constants';
import { Markdown } from '@/components/markdown';
import dayjs from 'dayjs';

const CertExamUnstartedOngoing = ({
  exam,
  id,
}: {
  exam: UserExam;
  id: string;
}) => {
  const [countDown, setCountDown] = useState<{
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  }>();

  const history = useHistory();
  useEffect(() => {
    let timer: any;
    if (
      exam.userExam.status === 'unstarted' &&
      exam.examTimeType === 'specificTime' &&
      new Date().getTime() < new Date(exam.examStartTime!).getTime()
    ) {
      const updateCountDown = () => {
        const now = new Date().getTime();
        const startTime = new Date(exam.examStartTime!).getTime();
        let diffSeconds = Math.floor((startTime - now) / 1000);
        if (diffSeconds <= 0) {
          setCountDown(undefined);
          clearInterval(timer);
          return;
        }
        let diffDays = Math.floor(diffSeconds / (24 * 3600));
        diffSeconds = diffSeconds - diffDays * (24 * 3600);
        let diffHours = Math.floor(diffSeconds / 3600);
        diffSeconds = diffSeconds - diffHours * 3600;
        let diffMinutes = Math.floor(diffSeconds / 60);
        diffSeconds = Math.floor(diffSeconds - diffMinutes * 60);
        setCountDown({
          days: String(diffDays).padStart(2, '0'),
          hours: String(diffHours).padStart(2, '0'),
          minutes: String(diffMinutes).padStart(2, '0'),
          seconds: String(diffSeconds).padStart(2, '0'),
        });
      };
      updateCountDown();
      timer = setInterval(updateCountDown, 1000);
    }
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content = (
    <div>
      <div className={styles.subTitle}>关于这个测试</div>
      <div className={styles.content}>
        {exam?.examRule ? (
          <Markdown>
            {exam.examRule?.notices ? exam.examRule.notices : ''}
          </Markdown>
        ) : (
          <Markdown>{getExamRule(exam)}</Markdown>
        )}
      </div>

      <Button
        onClick={async () => {
          if (exam.userExam.status === 'unstarted') {
            await remote.$patch(`/user/exams/${id}/exam-paper/status`, {
              status: 'ongoing',
            });
          }
          history.push(`/cert-exam/${id}/session`);
        }}
      >
        进入考试
      </Button>
    </div>
  );
  if (
    exam.examTimeType === 'specificTime' &&
    exam.userExam.status === 'unstarted'
  ) {
    if (
      new Date().getTime() < new Date(exam.examStartTime!).getTime() &&
      countDown
    ) {
      content = (
        <div className={styles.countDownWrapper}>
          <div className={styles.countDownTitle}>距离考试开始还有：</div>
          {
            <div className={styles.countDown}>
              <div className={styles.countDownItem}>
                {countDown.days}
                <div className={styles.unitCountDown}>天</div>
              </div>
              <div className={styles.countDownItem}>
                {countDown.hours}
                <div className={styles.unitCountDown}>小时</div>
              </div>
              <div className={styles.countDownItem}>
                {countDown.minutes}
                <div className={styles.unitCountDown}>分</div>
              </div>
              <div className={styles.countDownItem}>
                {countDown.seconds}
                <div className={styles.unitCountDown}>秒</div>
              </div>
            </div>
          }
        </div>
      );
    } else if (new Date().getTime() > new Date(exam.examEndTime!).getTime()) {
      content = <div>本次考试已经结束</div>;
    }
  }
  return content;
};

export const CertExam = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { user } = useContext(AppContext);

  const [exam, setExam] = useState<UserExam>();
  const [resumeInfo, setResumeInfo] = useState<HTMLDivElement>();
  const [userExamPaper, setUserExamPaper] = useState<UserExamPaper>();
  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const initDate = useCallback(async () => {
    const exam: UserExam = await remote.$get(`/user/exams/${id}`);
    if (exam && exam?.examRule) {
      const front = exam.examRule?.barText?.split('url_address')[0];
      const behind = exam.examRule?.barText?.split('url_address')[1];
      const info = (
        <div>
          <span>{front}</span>
          <a href={exam.examRule?.url} target="_blank" rel="noreferrer">
            {exam.examRule?.url}
          </a>
          <span>{behind}</span>
        </div>
      ) as any;
      setResumeInfo(info);
    }
    setExam(exam);
    if (exam.userExam.status === 'scored') {
      const score: UserExam = await remote.$get(`/user/exams/${id}/getScore`);
      setExam(score);
      const examPaper: UserExamPaper = await remote.$get(
        `/user/exams/${id}/exam-paper`,
      );
      setUserExamPaper(examPaper);
      const totalSeconds = examPaper.parts.reduce(
        (pre, cur) =>
          pre + dayjs(cur.submittedAt).diff(dayjs(cur.startedAt), 'seconds'),
        0,
      );
      setTotalSeconds(totalSeconds);
    }
  }, [id]);
  useEffect(() => {
    initDate();
  }, [initDate]);
  if (!exam) {
    return <Loading />;
  }
  if (exam.userExam.status === 'scored' && !userExamPaper) {
    return <Loading />;
  }

  let getOption = (scoreDimensions: ScoreDimension[]) => {
    return {
      radar: {
        indicator: scoreDimensions.map((dimension) => ({
          name: dimension.title,
          max: dimension.totalGrade,
        })),
      },
      series: [
        {
          type: 'radar',
          data: [
            {
              value: scoreDimensions.map((dimension) => dimension.userGrade),
              label: {
                show: true,
                formatter: function (params: any) {
                  return params.value;
                },
              },
            },
          ],
          areaStyle: {
            color: '#DDDAFD',
          },
          tooltip: {
            trigger: 'item',
          },
        },
      ],
    };
  };

  let content;

  if (
    exam.userExam.status === 'unstarted' ||
    exam.userExam.status === 'ongoing'
  ) {
    content = <CertExamUnstartedOngoing exam={exam} id={id} />;
  } else if (exam.userExam.status === 'submitted') {
    content = (
      <div>
        <div>
          <div className={styles.subTitle}>你已完成本次考试</div>
          <div className={styles.content}>
            {exam?.examRule ? (
              <div>
                {exam.examRule?.delayScoredText
                  ? exam.examRule.delayScoredText
                  : ''}
              </div>
            ) : (
              <div>本次考试成绩将于一周内出分，请关注群内老师公告。</div>
            )}
            {resumeInfo ? resumeInfo : ''}
          </div>
        </div>
      </div>
    );
  } else if (exam.userExam.status === 'scored') {
    let cert,
      radar,
      shouldShowRadar = !!exam.userExam.scoreDetail?.scoreDimensions?.length;
    // 如果是多维度，则不显示证书
    if (shouldShowRadar) {
      // 多维度雷达图
      radar = (
        <div className={styles.radar}>
          <ReactEcharts
            option={getOption(exam.userExam.scoreDetail!.scoreDimensions)}
          />
        </div>
      );
    } else {
      // 证书
      if (exam.cert.serialNumber) {
        cert = (
          <div className={styles.cert}>
            恭喜你，成功通过本次考试认证，
            <br /> 点击
            <CertView
              cert={exam.cert}
              realName={user?.realName || user?.phone || '学员'}
            />
            。
            <img
              src="https://staticcdn.boyuai.com/user-assets/5524/ioxpN8KbJTtPsfdseKCeGu/exam-cert.png!png"
              alt="exam-cert"
            />
          </div>
        );
      } else {
        cert = (
          <div className={styles.cert}>
            很抱歉，你没有通过本次认证考试，
            <br /> 如有需要，请联系老师 ，<br /> 安排后续补考。
            <img
              src="https://staticcdn.boyuai.com/user-assets/5524/ywqAJtqBrcmYCrHXwRgjsS/fail-exam-cert.png!png"
              alt="fail-exam-cert"
            />
          </div>
        );
      }
    }

    content = userExamPaper ? (
      <div>
        <div className={styles.scoredContent}>
          <div className={styles.transcript}>
            <div className={styles.transcriptHeadRow}>
              <div className={styles.transcriptRowTitle}>模块</div>
              <div className={styles.transcriptRowScore}>用时</div>
              {exam.partsPassScore && (
                <div className={styles.transcriptRowScore}>通过得分</div>
              )}
              <div className={styles.transcriptRowScore}>得分</div>
            </div>
            {userExamPaper.parts.map((p, idx) => {
              let partPassScore;
              if (exam.partsPassScore) {
                partPassScore = exam.partsPassScore.parts.find(
                  (ps) => ps.id === p.id,
                )?.passScore;
              }
              const currentPartSeconds = dayjs(p.submittedAt).diff(
                dayjs(p.startedAt),
                'seconds',
              );
              const minutes = Math.floor(currentPartSeconds / 60);
              const seconds = currentPartSeconds % 60;
              return (
                <div className={styles.transcriptRow} key={idx}>
                  <div className={styles.transcriptRowTitle}>
                    {/* {p.title} */}
                    {`第${idx + 1}部分`}
                    {/* {`（共${p.totalScore}分）`} */}
                  </div>
                  <div className={styles.transcriptRowScore}>
                    {`${minutes < 10 ? '0' + minutes : minutes}:${
                      seconds < 10 ? '0' + seconds : seconds
                    }`}
                  </div>
                  {partPassScore && (
                    <div className={styles.transcriptRowScore}>
                      {partPassScore}
                    </div>
                  )}
                  <div className={styles.transcriptRowScore}>{p.userScore}</div>
                </div>
              );
            })}
            <div className={styles.transcriptFooterRow}>
              <div className={styles.transcriptRowTitle}>总得分：</div>
              <div className={styles.transcriptRowScore}>
                {exam.userExam.score}
              </div>
            </div>
          </div>
          <div className={styles.certBox}>
            {radar}
            {cert}
          </div>
        </div>
      </div>
    ) : null;
  }

  return (
    <div>
      <div className={styles.topBar}>
        <div
          className={styles.back}
          onClick={() => {
            history.push('/account/exams');
          }}
        >
          <BackSvg />
        </div>
        <div>{exam.title}</div>
        <div className={styles.extra}></div>
      </div>
      <div className={styles.body}>
        <div
          className={styles.title}
          style={{ marginBottom: exam.userExam.compositeScore ? 18 : 30 }}
        >
          {exam.title}
        </div>
        {/* <div className={styles.time}>
          考试时间：
          {exam.examTimeType === 'anyTime'
            ? '任意时间'
            : QZFormalTimeRange(exam.examStartTime!, exam.examEndTime!)}
          <br />
          通过得分：{exam.passScore} 分
        </div> */}
        {exam.userExam.compositeScore ? (
          <div className={styles.timeScore}>
            <span>
              完成于：
              {dayjs(exam.userExam.submittedAt).format('YYYY-MM-DD HH:mm')}
            </span>
            <span>
              共耗时：{Math.floor(totalSeconds / 3600)}小时
              {Math.floor(totalSeconds / 60)}分
            </span>
            <span>
              综合得分：
              <span className={styles.score}>
                {exam.userExam.compositeScore}
              </span>
            </span>
          </div>
        ) : null}
        {exam.userExam.status === 'scored' && resumeInfo}
        <Separator style={{ marginBottom: '50px', marginTop: '12px' }} />
        {content}
      </div>
    </div>
  );
};
