import { useEffect } from 'react';
import { useQuery } from '@/hooks';
import remote from '@/utils/remote';

export const openExternalLink = (url: string) => {
  window.open(
    `${process.env.REACT_APP_SUB_DIRECTORY}/redirect?url=${encodeURIComponent(
      url,
    )}`,
  );
};

/**
 * 本页面可用于外链的中转（校验、OAuth2、...）
 */
export const RedirectPage = () => {
  const query = useQuery();
  const url = query.get('url');

  useEffect(() => {
    if (!url) return;

    (async function () {
      const validation: any = await remote.$post('/oauth2/validate', { url });
      if (!validation.isOAuth2) {
        window.location.href = url;
        return;
      }
      const authorization: any = await remote.$post('/oauth2/authorize', {
        response_type: 'code',
        client_id: validation.clientId,
      });
      const resolvedUrl = `${authorization.redirectUri}?code=${
        authorization.authorizationCode
      }&redirect=${encodeURIComponent(url)}`;
      window.location.href = resolvedUrl;
    })();
  }, [url]);

  return null;
};
