import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Separator } from '@/atoms/separator';
import { Button } from '@/atoms/button';
import { computeHoursOrMinutes } from '@/utils';
import { useOnScreen } from '@/utils/hooks';
import { ReactComponent as CheckSvg } from '@/icons/check/icon.svg';
import { ReactComponent as BarSvg } from '@/icons/bar/bar.svg';

import { LearningStatus } from './status';
import styles from './course-panel.module.less';
import { Tooltip } from '@/atoms/tooltip';
import { Info } from '@/icons/info';
import { openExternalLink } from '../redirect';
import remote from '@/utils/remote';
import { Cross } from '@/icons/cross';
import { Modal } from '@/atoms/modal';

const UnitStatusBar: React.FC<{
  status: string;
  unitId: string;
  learnMode: string;
  unit2Lessons: any;
}> = ({ status, unitId, unit2Lessons, learnMode }) => {
  const icon = <LearningStatus status={status} mode="icon" />;
  let content = null;
  if (status === 'skipped') {
    content = <>{icon}已跳过</>;
  } else if (status === 'finished') {
    content = <>{icon}已完成</>;
  } else if (status === 'locked') {
    content = <>{icon}未解锁</>;
  } else if (
    status === 'ongoing' ||
    (status === 'unstarted' && learnMode === 'sequential')
  ) {
    const lessons = unit2Lessons[unitId] || [];
    const remainLessons = lessons.filter(
      (lesson: any) => lesson.status !== 'finished',
    );
    const remainMinutes = remainLessons.reduce((prev: number, lesson: any) => {
      return prev + lesson.duration;
    }, 0);
    const percent =
      ((lessons.length - remainLessons.length) * 100) / lessons.length;
    content = (
      <div className={styles.ongoing}>
        <div className={styles.stat}>
          <LearningStatus status={status} mode="icon" percent={percent} />
          <span>学习中</span>
        </div>
        <div>预计剩余耗时：{computeHoursOrMinutes(remainMinutes)}</div>
      </div>
    );
  } else if (status === 'unstarted') {
    content = <>{icon}未开始</>;
  }

  if (!content) return null;

  return (
    <>
      <div className={styles['unit-status-bar']}>{content}</div>
      <Separator />
    </>
  );
};

export const CoursePanel: React.FC<{
  learningPathId: number;
  learningPath: any;
  course: any;
  course2Units: any;
  unitAndExercises: any;
  unit2Lessons: any;
  lesson2LessonContents: any;
  full: boolean;
  learnMode: string;
  onCurrentChange: (courseId: number, unitId: string, lessonId: number) => void;
  onStartExercise: (
    lessonContentId: number,
    title: string,
    sessionUuid?: string,
    type?: string,
  ) => void;
  setExerciseInfo: (data: any) => void;
}> = ({
  learningPathId,
  learningPath,
  course,
  course2Units,
  unitAndExercises,
  unit2Lessons,
  full,
  onCurrentChange,
  learnMode,
  lesson2LessonContents,
  onStartExercise,
  setExerciseInfo,
}) => {
  const scrollToItem = useCallback(
    (lessonId) => {
      let ele;
      let yOffset;
      yOffset = -80;
      ele = document.getElementById(`lesson-${lessonId}`);

      if (ele) {
        const y =
          ele.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!course || !learningPath) return null;
  const units = unitAndExercises || {};
  const lessonCount = units.reduce((prev: number, unit: any) => {
    const lessons = unit2Lessons[unit.publicId] || [];
    return prev + lessons.length;
  }, 0);
  let courseDuration = units.reduce((prev: number, unit: any) => {
    const lessons = unit2Lessons[unit.publicId] || [];
    const unitDuration = lessons.reduce(
      (prev: number, lesson: any) => prev + lesson.duration,
      0,
    );
    return prev + unitDuration;
  }, 0);
  courseDuration = Math.floor(courseDuration / 60);

  const firstUnit =
    (learningPath.course2Units[learningPath.courses[0]?.id] || [])[0] || {};
  const firstLesson = learningPath.unit2Lessons[firstUnit.id]?.[0] || {};

  let welcome;
  if (learningPath?.nextLesson?.lesson) {
    if (firstLesson?.id !== learningPath.nextLesson.lesson.id) {
      welcome = (
        <div className={styles['next-lesson-wrapper']}>
          <div>
            <div className={styles['next-lesson-welcome']}>欢迎回来！</div>
            <div className={styles['next-lesson-title']}>
              你上次学到《{learningPath.nextLesson.course.title} -{' '}
              {learningPath.nextLesson.lesson.title}》
            </div>
          </div>
          <Button
            style={{ width: 160, height: 42 }}
            onClick={() => {
              if (course.id !== learningPath.nextLesson.course.id) {
                onCurrentChange(
                  learningPath.nextLesson.course.id,
                  learningPath.nextLesson.unit.id,
                  learningPath.nextLesson.lesson.id,
                );
                setTimeout(() => {
                  scrollToItem(learningPath.nextLesson.lesson.id);
                }, 500);
              } else {
                scrollToItem(learningPath.nextLesson.lesson.id);
              }
            }}
          >
            继续学习
          </Button>
        </div>
      );
    } else {
      welcome = (
        <div className={styles['next-lesson-wrapper']}>
          <div>
            <div className={styles['next-lesson-welcome']}>欢迎学习！</div>
            <div className={styles['next-lesson-title']}>
              第一个知识点《{learningPath.nextLesson.lesson.title}》
            </div>
          </div>
          <Button
            style={{ width: 160, height: 42 }}
            onClick={() => {
              scrollToItem(learningPath.nextLesson.lesson.id);
            }}
          >
            开始学习
          </Button>
        </div>
      );
    }
  }
  return (
    <div className={styles['course-panel']}>
      {welcome}
      <div className={styles['course-title']}>{course.title}</div>
      {course.intro && (
        <pre className={styles['course-intro']}>{course.intro}</pre>
      )}
      <div className={styles['course-statistics']}>
        <span>{units.length} 个单元</span>
        <span>{lessonCount} 个知识点</span>
        <span>{courseDuration < 1 ? '< 1' : courseDuration} 个小时</span>
      </div>

      {units.map((unit: any, index: number) => {
        // const unitLocked = unit.status === 'locked';
        if (unit.type === 'unit') {
          return (
            <div key={unit.publicId} className={styles['unit-card']}>
              <UnitStatusBar
                status={unit.status}
                unitId={unit.publicId}
                learnMode={learnMode}
                unit2Lessons={unit2Lessons}
              />
              <div className={styles['unit-trigger']}>
                <div
                  className={styles['unit-title']}
                  id={'unit-' + unit.publicId}
                >
                  {unit.title}
                  {/* <QzLink
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                  to={`/learning-path/${learningPathId}/unit/${unit.id}?full=${full}`}
                  disabled
                >
                  {unit.title}
                </QzLink> */}
                </div>
                {unit.intro && (
                  <div className={styles['unit-intro']}>{unit.intro}</div>
                )}
              </div>
              {unit2Lessons[unit.id].map((lesson: any) => {
                return (
                  <Lesson
                    key={lesson.id}
                    lesson={lesson}
                    contents={lesson2LessonContents[lesson.id]}
                    unit={unit}
                    learnMode={learnMode}
                    learningPathId={learningPathId}
                    onStartExercise={onStartExercise}
                    onInScreen={() => {
                      onCurrentChange(course.id, unit.id, lesson.id);
                    }}
                  />
                );
              })}
            </div>
          );
        } else {
          return (
            <div
              key={unit.publicId}
              className={`${styles['unit-card']} ${styles['exercise-card']}`}
            >
              <div className={styles['unit-trigger']}>
                <div
                  className={styles['unit-title']}
                  id={'unit-' + unit.publicId}
                >
                  {unit.title}
                  <span style={{ float: 'right' }}>
                    <LearningStatus status={unit.globalStatus} mode="text" />
                  </span>
                </div>
                {unit.intro && (
                  <div className={styles['unit-intro']}>{unit.intro}</div>
                )}
              </div>
              <div className={styles['exercise-footer']}>
                <Button
                  style={{ width: 160, height: 42 }}
                  onClick={() => {
                    setExerciseInfo({
                      globalStatus: unit.globalStatus,
                      intro: unit.intro,
                    });
                    onStartExercise(
                      unit.id,
                      unit.title,
                      unit.sessionUuid,
                      'multi',
                    );
                  }}
                >
                  {unit.globalStatus === 'unstarted' ? '开始练习' : '再次练习'}
                </Button>
                <div className={styles['exercise-score']}>
                  <span>知识点综合得分：</span>
                  <span className={styles['score']}>{`${
                    unit.currentScore || '--'
                  }/${unit.totalScore}`}</span>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
const windowHeight = window.innerHeight;

const Lesson = ({
  lesson,
  unit,
  onInScreen,
  learnMode,
  learningPathId,
  contents = [],
  onStartExercise,
}: {
  lesson: any;
  unit: any;
  contents: any;
  learnMode: string;
  learningPathId: any;
  onInScreen: () => void;
  onStartExercise: (
    lessonContentId: number,
    title: string,
    sessionUuid?: string,
    type?: string,
  ) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const onView = useOnScreen(ref, `-90px 0px -${windowHeight - 140}px 0px`);
  useEffect(() => {
    if (onView) {
      onInScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onView]);

  const [lessonStatus, setLessonStatus] = useState<string>(() => {
    let lessonStatus = lesson.status;
    if (
      learnMode === 'sequential' &&
      (lessonStatus === 'unstarted' || lessonStatus === 'ongoing')
    ) {
      lessonStatus = 'current';
    }
    return lessonStatus;
  });
  useEffect(() => {
    setLessonStatus(lesson.status);
  }, [lesson.status]);
  if (!lesson || !unit) return null;

  let lessonEle = (
    <>
      <div className={styles['lesson-duration']}>
        <span>知识点</span>
        <span className={styles.duration}>
          {computeHoursOrMinutes(lesson.duration)}
        </span>
      </div>
      <div className={styles['lesson-title-wrapper']}>
        <div className={styles['lesson-title']}>{lesson.title}</div>
        <LearningStatus status={lessonStatus} mode="text" />
      </div>
    </>
  );
  let lessonContentEle;
  let assessmentEle;
  if (lessonStatus !== 'locked') {
    lessonContentEle = (
      <div className={styles.lessonContents}>
        <div className={styles.headerRow}>
          <div>学习资源</div>
          <div>状态</div>
        </div>
        {contents
          .filter((c: any) => c.contentType === 'learning-resource')
          .map((c: any) => (
            <Resource
              key={c.id}
              resource={c}
              learningPathId={learningPathId}
              lessonStatus={lessonStatus}
              onResourceOpen={() =>
                setLessonStatus((pre) => {
                  if (pre === 'unstarted') {
                    return 'ongoing';
                  }
                  return pre;
                })
              }
            />
          ))}
      </div>
    );
    assessmentEle = (
      <div className={styles.lessonContents}>
        <div className={styles.headerRow}>
          <div>知识点练习</div>
          <div>得分</div>
        </div>
        {contents
          .filter((c: any) => c.contentType === 'exercise')
          .map((e: any) => {
            let scoreHintEle;
            let scoreEle;
            if (e.score == null) {
              if (lessonStatus === 'skipped') {
                scoreHintEle = (
                  <Tooltip content="跳过的知识点也可以通过复习来加强掌握哦">
                    <span style={{ marginRight: 5, height: 16 }}>
                      <Info />
                    </span>
                  </Tooltip>
                );
              } else if (learnMode === 'sequential') {
                scoreHintEle = (
                  <Tooltip content="完成知识点练习，达到60分解锁下个知识点">
                    <span style={{ marginRight: 5, height: 16 }}>
                      <Info />
                    </span>
                  </Tooltip>
                );
              }
              scoreEle = (
                <div className={styles.bodyScore} style={{ height: 27 }}>
                  <BarSvg color="var(--system-icon)" />
                </div>
              );
            } else {
              if (e.score < 60) {
                scoreHintEle = (
                  <Tooltip
                    content={
                      learnMode === 'sequential'
                        ? '再次练习，达到60分解锁下个知识点'
                        : '可以通过多次练习提高分数'
                    }
                  >
                    <span
                      style={{
                        color: 'var(--secondary-red-80)',
                        marginRight: 5,
                      }}
                    >
                      <Cross size={20} />
                    </span>
                  </Tooltip>
                );
              }
              scoreEle = <div className={styles.bodyScore}>{e.score}</div>;
            }

            return (
              <div
                key={e.id}
                className={styles.bodyRow}
                style={{ alignItems: 'center' }}
              >
                <div
                  className={styles.bodyLink}
                  onClick={() => {
                    onStartExercise(e.id, e.title, e.sessionUuid);
                  }}
                >
                  {e.title}
                </div>
                <div className={styles.bodyDesc}>{e.duration}分钟 | 电脑</div>
                {scoreHintEle}
                <Modal
                  title="练习得分如何计算？"
                  trigger={
                    <button
                      title="练习得分如何计算？"
                      style={{ cursor: 'pointer' }}
                    >
                      {scoreEle}
                    </button>
                  }
                >
                  <div>
                    平台会根据你的答题表现和作答题目的难度，使用智能算法综合评定。
                    <h5>基本规则：</h5>
                    <ol>
                      <li>
                        用户独立解答题目(即提交前未查看答案或题目解析)且正确作答的题目越多，得分就越高；
                      </li>
                      {learnMode === 'sequential' && (
                        <li>
                          得分达到一次60分及以上时，即可解锁后续知识点和课程；
                        </li>
                      )}

                      <li>
                        用户连续2次练习都独立正确解答全部题目，即可获得满分成绩；
                      </li>

                      <li>
                        用户可多次练习，若答题正确率下滑，可能会导致得分下降。
                      </li>
                    </ol>
                  </div>
                </Modal>
              </div>
            );
          })}
      </div>
    );
  }
  return (
    <div key={lesson.id} ref={ref} id={'lesson-' + lesson.id}>
      <Separator />
      <div className={styles['lesson-section']} data-disabled>
        {lessonEle}
        {lessonContentEle}
        {contents.filter((c: any) => c.contentType === 'exercise').length
          ? assessmentEle
          : null}
      </div>
    </div>
  );
};

const Resource = ({
  resource,
  learningPathId,
  lessonStatus,
  onResourceOpen,
}: {
  resource: any;
  learningPathId: any;
  lessonStatus: string;
  onResourceOpen: () => void;
}) => {
  const [redirecting, setRedirecting] = useState(false);
  const [status, setStatus] = useState(resource.status);
  const openResource = async () => {
    if (status === 'unstarted') {
      if (redirecting) return;
      setRedirecting(true);

      try {
        // 先标记完成再 OAuth，这样保证用户权限正确（后续可以优化这个流程，无视该标记）
        await remote.$post('/user-lesson-content-relation', {
          lessonContentId: resource.id,
          learningPathId: learningPathId,
          status: 'finished',
        });
        setStatus('finished');
        if (onResourceOpen) onResourceOpen();
      } finally {
        setRedirecting(false);
      }
    }

    openExternalLink(resource.url);
  };

  let statusIcon;
  if (status === 'unstarted' || status === 'locked') {
    statusIcon = <BarSvg title="未开始" color="var(--system-icon)" />;
  } else {
    if (lessonStatus === 'finished') {
      statusIcon = <CheckSvg title="已完成" fill="var(--secondary-green)" />;
    } else if (lessonStatus === 'skipped') {
      statusIcon = <BarSvg title="已跳过" color="var(--primary-blue-60)" />;
    } else {
      statusIcon = <CheckSvg title="已完成" fill="var(--secondary-yellow)" />;
    }
  }

  return (
    <div className={styles.bodyRow}>
      <div className={styles.bodyLink} onClick={openResource}>
        {resource.title}
      </div>
      <div className={styles.bodyDesc}>
        {resource.duration}分钟 |{/* {resource.contributors[0]?.name} | */}{' '}
        {resource.pcRequired ? '电脑' : '手机、电脑'}
      </div>

      {statusIcon}
    </div>
  );
};
