import { useLayoutEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkBreaks from 'remark-breaks';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { uuid } from '@/utils';
// @ts-ignore
import renderMathInElement from 'katex/contrib/auto-render/auto-render';
import 'katex/dist/katex.min.css';
import styles from './index.module.less';

export const Markdown: React.FC<ReactMarkdownOptions> = ({
  children,
  ...options
}) => {
  const idRef = useRef(`qz-markdown-${uuid()}`);

  // 配置与 OJ 同步
  useLayoutEffect(() => {
    renderMathInElement(document.getElementById(idRef.current), {
      throwOnError: false,
      delimiters: [
        { left: '$', right: '$', display: false },
        { left: '$$', right: '$$', display: true },
        { left: '\\[', right: '\\]', display: true },
        { left: '\\(', right: '\\)', display: false },
      ],
    });
  }, []);

  return (
    <div id={idRef.current}>
      <ReactMarkdown
        {...options}
        children={children as string}
        className={styles['qz-markdown-code']}
        remarkPlugins={[remarkGfm, remarkMath, remarkBreaks]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={atomOneLight}
                language={match[1]}
                PreTag="div"
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </div>
  );
};
