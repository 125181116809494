import { useContext, useEffect, useState } from 'react';
import { Button } from '@/atoms/button';
import { Popover, PopoverRectangle } from '@/atoms/popover';
import { Separator } from '@/atoms/separator';
import { Avatar } from '@/atoms/avatar';
import { Input } from '@/atoms/input';
import { Modal } from '@/atoms/modal';
import FileUpload from '@/atoms/upload';
import remote from '@/utils/remote';
import styles from './index.module.less';
import { AppContext } from '../App';
import { useHistory, useParams } from 'react-router';
import { useInitSurvey } from '@/components/init-survey';
import {
  MetarkIcon,
  MetarkIconInverse,
  Qingzhou,
  QingzhouInverse,
  MyInfoIcon,
  CameraIcon,
  MyTestIcon,
  ExerciseHistoryIcon,
  LoggedOutIcon,
  RightArrowsIcon,
  DownIcon,
  UpIcon,
} from '@/icons/qingzhou';
import { ReactComponent as AddSvg } from '@/components/icons/path/add.svg';
import { Loading } from '@/atoms/loading';
import { Drawer } from '@/atoms/drawer';
import { pathTypes } from '@/typings/path-exam';

interface INavProps {
  /**
   * 反色模式
   */
  inverse?: boolean;
  className?: string;
  setRenderChidren?: any;
}

interface INavLayoutProps extends INavProps {
  loginRequired?: boolean;
}

export const NavLayout: React.FC<INavLayoutProps> = ({
  children,
  loginRequired = true,
}) => {
  const { user } = useContext(AppContext);
  const [isRenderChildren, setRenderChidren] = useState<boolean>(true);

  // 登录状态检查，外部可自由指定
  useEffect(() => {
    if (loginRequired && !user) {
      remote.requestLogin();
    }
  }, [loginRequired, user]);
  if (!user && loginRequired) {
    return <Loading />;
  }

  return (
    <div className={styles['nav-layout']}>
      <Nav setRenderChidren={setRenderChidren} />
      {isRenderChildren ? children : null}
    </div>
  );
};

export const Nav: React.FC<INavProps> = ({
  className,
  inverse = false,
  setRenderChidren,
}) => {
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [avatarModal, setAvatarModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<{
    avatar: string;
    realName: string;
  }>({
    avatar: '',
    realName: '',
  });
  const [paths, setPaths] = useState<pathTypes>();
  const [currentPath, setCurrentPath] = useState<number>();
  const { user, resetUser, setUser } = useContext(AppContext);
  const { id: learningPathId } = useParams<{ id: string }>();
  const [initSurvey, startInitSurvey] = useInitSurvey();
  const { allPath } = useContext(AppContext);

  useEffect(() => {
    if (allPath) {
      const learningData = allPath.filter((item) => {
        return item.userLearning;
      });
      setPaths(learningData);
      const pathname = history.location.pathname;
      const index = pathname.lastIndexOf('/');
      const pathId = pathname.substring(index + 1, pathname.length);
      setCurrentPath(Number(pathId));
    }
  }, [allPath, history.location.pathname]);

  const defaultAvatar =
    'https://staticcdn.boyuai.com/user-assets/14410/T7J5BzKdncHPxR3JcjWuL9/default-avatar.png!png';

  const goHome = () =>
    (window.location.href = process.env.REACT_APP_SUB_DIRECTORY || '/');
  const isLoginPage = history.location.pathname === '/login';

  return (
    <div
      className={`${styles.nav}${className ? ` ${className}` : ''}`}
      data-inverse={inverse}
    >
      <div className={styles.logo} onClick={goHome}>
        {inverse ? <MetarkIconInverse /> : <MetarkIcon />}
        {inverse ? <QingzhouInverse /> : <Qingzhou />}
      </div>
      {/* <div className={styles.link} onClick={goHome}>
        首页
      </div>
      <div className={styles.link}>产品功能</div> */}
      <div className={styles.actions}>
        {user ? (
          <div
            className={styles.pathBtn}
            onClick={() => {
              setShow(true);
            }}
          >
            学习路径
            {show ? (
              <UpIcon color={inverse ? 'var(--system-white)' : ''} />
            ) : (
              <DownIcon color={inverse ? 'var(--system-white)' : ''} />
            )}
          </div>
        ) : (
          !isLoginPage && (
            <Popover
              content={
                <div>
                  <img
                    style={{ display: 'block', margin: '20px 100px' }}
                    width={148}
                    src="https://staticcdn.boyuai.com/user-assets/14410/yAuAxvMvgHFwRwE61T11JN/path-login.png!png"
                    alt="路径图片"
                  />
                  <Button
                    style={{ display: 'block', margin: '0 auto' }}
                    onClick={() => {
                      remote.requestLogin();
                    }}
                  >
                    登录青舟，添加路径
                  </Button>
                </div>
              }
            >
              <div className={styles.pathBtn}>
                学习路径
                {show ? (
                  <UpIcon color={inverse ? 'var(--system-white)' : ''} />
                ) : (
                  <DownIcon color={inverse ? 'var(--system-white)' : ''} />
                )}
              </div>
            </Popover>
          )
        )}

        <div className={styles.link}>
          <a
            href="https://www.boyuai.com/elites/"
            target="_blank"
            rel="noreferrer"
          >
            伯禹人工智能学院
          </a>
        </div>
        {user ? (
          <>
            <PopoverRectangle
              content={
                <div className={styles['user-info']}>
                  <div className={styles.phone}>
                    {user.realName ||
                      user.phone.substring(0, 3) +
                        '****' +
                        user.phone.substring(user.phone.length - 4)}
                  </div>
                  <Separator />
                  <div className={styles['user-active']}>
                    <div
                      className={styles.item}
                      onClick={() => {
                        setUserInfo({
                          avatar: user.avatar || defaultAvatar,
                          realName: user.realName || '',
                        });
                        setAvatarModal(true);
                      }}
                    >
                      <MyInfoIcon />
                      <span>个人信息</span>
                      <RightArrowsIcon />
                    </div>
                    <div
                      className={styles.item}
                      onClick={() => {
                        window.open('/account/exams');
                      }}
                    >
                      <MyTestIcon />
                      <span>我的考试</span>
                      <RightArrowsIcon />
                    </div>
                    <div
                      className={styles.item}
                      onClick={() => {
                        window.open('/exercise-history');
                      }}
                    >
                      <ExerciseHistoryIcon />
                      <span>练习历史</span>
                      <RightArrowsIcon />
                    </div>
                    <div
                      className={styles.item}
                      onClick={() => {
                        remote.logout();
                        resetUser();
                        history.push('/');
                      }}
                    >
                      <LoggedOutIcon />
                      <span>退出登录</span>
                    </div>
                  </div>
                </div>
              }
            >
              <div style={{ marginRight: 16 }}>
                <Avatar src={user.avatar ? user.avatar : defaultAvatar} />
                <span style={{ marginLeft: 6 }}>
                  <DownIcon color={inverse ? 'var(--system-white)' : ''} />
                </span>
              </div>
            </PopoverRectangle>
          </>
        ) : (
          <>
            <Button
              variant="sub"
              onClick={() => {
                remote.requestLogin();
              }}
            >
              注 册
            </Button>
            <Button
              style={inverse ? { background: 'transparent' } : {}}
              onClick={() => {
                remote.requestLogin();
              }}
            >
              登 录
            </Button>
          </>
        )}
      </div>
      <Drawer
        open={show}
        onOpenChange={async (open) => {
          if (!open) {
            setShow(false);
          }
        }}
      >
        <div className={styles.drawerContent}>
          <div className={styles.header}>我的学习路径</div>
          <div className={styles.content}>
            {paths?.map((item) => {
              return (
                <div
                  className={styles.item}
                  key={item.id}
                  style={{
                    borderColor: item.id === currentPath ? '#7B6FFF' : '',
                  }}
                  onClick={() => {
                    if (Number(learningPathId) === item.id) {
                      setShow(false);
                    } else {
                      setCurrentPath(item.id);
                      setShow(false);
                      setRenderChidren(false);
                      history.push(`/learning-path/${item.id}`);
                      setTimeout(() => {
                        setRenderChidren(true);
                      }, 0);
                    }
                  }}
                >
                  <div className={styles.img}>
                    <img src={item.bannerUrl} alt={item.title} />
                  </div>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.intro}>{item.intro}</div>
                </div>
              );
            })}
            <div
              className={`${styles.add} ${styles.item}`}
              onClick={() => {
                setShow(false);
                startInitSurvey({ defaultStep: 2 });
              }}
            >
              <AddSvg />
              <div>添加新路径</div>
            </div>
          </div>
        </div>
      </Drawer>
      {user && (
        <Modal
          title="个人信息"
          width={420}
          open={avatarModal}
          onOpenChange={async (open) => {
            if (!open) {
              setAvatarModal(false);
            }
          }}
        >
          <div className={styles['avatar-modal']}>
            <div className={styles.item}>
              <label>头像：</label>
              <Avatar src={userInfo.avatar} />
              <span className={styles.fileBtn}>
                <span className={styles.file}>
                  <FileUpload
                    onUploaded={(url: string) => {
                      setUserInfo((pre) => ({ ...pre, avatar: url }));
                    }}
                  />
                </span>
                <span className={styles.avatarShadow}>
                  <CameraIcon />
                </span>
              </span>
            </div>
            <div className={styles.item}>
              <label>姓名：</label>
              <Input
                className={styles.input}
                value={userInfo.realName}
                onChange={(e) => {
                  setUserInfo((pre) => ({ ...pre, realName: e.target.value }));
                }}
              />
            </div>
            <div className={styles.item}>
              <label>手机号：</label>
              <span>
                {user.phone.substring(0, 3) +
                  '****' +
                  user.phone.substring(user.phone.length - 4)}
              </span>
            </div>
            <Button
              className={styles.save}
              onClick={async () => {
                setAvatarModal(false);
                const res = await remote.$patch('/user', userInfo);
                setUser(res);
              }}
            >
              保 存
            </Button>
          </div>
        </Modal>
      )}
      {initSurvey}
    </div>
  );
};
