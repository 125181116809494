/* eslint-disable */
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CSSTransition } from 'react-transition-group';
import { Separator } from '@/atoms/separator';
import { useExerciseContext } from './exercise.context';
import { Button } from '@/atoms/button';
import { getSolutionAndAnswer, updateViewStatus } from './exercise.service';
import { Modal } from '@/atoms/modal';
import styles from './result-notification.module.less';
import './result-notification.less';

export type ResultNotificationProps = {
  status: 'correct' | 'wrong';
  onClose?: () => void;
  onSolutionShow?: () => void;
};

/**
 * 练习提交反馈
 * 需要跨 footer & quiz-question & oj-problem 通信，所以将状态进行提升
 */
export const ResultNotification = NiceModal.create<ResultNotificationProps>(
  ({ status, onClose, onSolutionShow }) => {
    const modal = useModal();
    const { currentRecord, dispatch } = useExerciseContext();
    if (!currentRecord) {
      throw new Error('RelatedConcept 必须要有 currentRecord');
    }
    let message;
    const answerText = currentRecord.question.hasSolution ? '题解' : '答案';

    if (status === 'wrong') {
      message =
        currentRecord.questionType === 'quiz-question' ? (
          <>
            再试一次，或者{' '}
            <Button
              variant="text"
              onClick={async () => {
                await updateViewStatus(currentRecord.recordId, 'show-solution');
                const { solution, answers } = await getSolutionAndAnswer(
                  currentRecord.recordId,
                );
                dispatch({
                  type: 'updateViewStatus',
                  data: { newStatus: 'show-solution', solution, answers },
                });
                onSolutionShow?.();
                modal.hide();
                setTimeout(() => {
                  const ele = document.getElementById('exercise-hint');
                  if (ele) ele.scrollIntoView({ behavior: 'smooth' });
                }, 1);
              }}
            >
              查看{answerText}
            </Button>{' '}
            。
          </>
        ) : (
          <>再试一次。</>
        );
    } else {
      message = '恭喜你，继续坚持～';
    }

    return (
      <CSSTransition
        classNames="result-notification-transition"
        in={modal.visible}
        timeout={{
          enter: 250,
        }}
        onExit={() => {
          onClose?.();
          modal.remove();
        }}
      >
        <Modal
          className={styles['result-notification']}
          open // for transition
          modal={false} // for retry
          onClose={modal.hide}
          data-status={status}
        >
          <div className={styles.title}>
            {status === 'correct' ? <Check /> : <ErrorIcon />}
            <div className={styles.text}>
              {status === 'correct' ? '答对了！' : '答错了！'}
            </div>
          </div>
          <Separator />
          <div className={styles.message}>{message}</div>
        </Modal>
      </CSSTransition>
    );
  },
);

const Check = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0571 30.9492C55.0571 44.7563 43.8642 55.9492 30.0571 55.9492C16.2499 55.9492 5.05707 44.7563 5.05707 30.9492C5.05707 17.1421 16.2499 5.94922 30.0571 5.94922C43.8642 5.94922 55.0571 17.1421 55.0571 30.9492ZM23.5131 28.6215C23.8181 28.7525 24.0939 28.943 24.3246 29.1818L27.5571 32.4143L35.7896 24.1818C36.0202 23.943 36.296 23.7525 36.601 23.6215C36.9061 23.4905 37.2341 23.4215 37.5661 23.4186C37.898 23.4158 38.2272 23.479 38.5344 23.6047C38.8417 23.7304 39.1208 23.9161 39.3555 24.1508C39.5903 24.3855 39.7759 24.6646 39.9016 24.9719C40.0273 25.2791 40.0906 25.6083 40.0877 25.9403C40.0848 26.2722 40.0158 26.6003 39.8848 26.9053C39.7538 27.2103 39.5633 27.4862 39.3246 27.7168L29.3246 37.7168C28.8557 38.1854 28.22 38.4487 27.5571 38.4487C26.8941 38.4487 26.2584 38.1854 25.7896 37.7168L20.7896 32.7168C20.5508 32.4862 20.3603 32.2103 20.2293 31.9053C20.0983 31.6003 20.0293 31.2722 20.0264 30.9403C20.0236 30.6083 20.0868 30.2791 20.2125 29.9719C20.3382 29.6646 20.5238 29.3855 20.7586 29.1508C20.9933 28.9161 21.2724 28.7304 21.5797 28.6047C21.8869 28.479 22.2161 28.4158 22.5481 28.4186C22.88 28.4215 23.2081 28.4905 23.5131 28.6215Z"
        fill="var(--secondary-green-80)"
      />
    </svg>
  );
};

const ErrorIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1084 55.9604C43.9155 55.9604 55.1084 44.7676 55.1084 30.9604C55.1084 17.1533 43.9155 5.96045 30.1084 5.96045C16.3013 5.96045 5.1084 17.1533 5.1084 30.9604C5.1084 44.7676 16.3013 55.9604 30.1084 55.9604ZM26.5879 30.9989L22.9009 34.7079C22.6629 34.9392 22.4733 35.2156 22.3432 35.521C22.2131 35.8263 22.1451 36.1545 22.1432 36.4864C22.1413 36.8183 22.2055 37.1473 22.3321 37.4541C22.4587 37.761 22.6451 38.0395 22.8804 38.2736C23.1158 38.5076 23.3954 38.6924 23.7029 38.8172C24.0105 38.9421 24.3398 39.0044 24.6717 39.0006C25.0036 38.9969 25.3314 38.927 25.636 38.7952C25.9406 38.6634 26.2159 38.4722 26.4459 38.2329L30.1153 34.5417L33.7134 38.1555C34.1812 38.6256 34.8165 38.8906 35.4797 38.8922C36.1429 38.8939 36.7796 38.632 37.2497 38.1642C37.7197 37.6965 37.9848 37.0611 37.9864 36.3979C37.9881 35.7348 37.7262 35.0981 37.2584 34.628L33.6402 30.9957L37.3459 27.2679C37.8001 26.7951 38.0505 26.1629 38.043 25.5073C38.0355 24.8517 37.7708 24.2253 37.3059 23.763C36.841 23.3008 36.2131 23.0396 35.5575 23.0358C34.9019 23.0321 34.2711 23.286 33.8009 23.7429L30.112 27.4538L26.3584 23.6855C25.8879 23.229 25.2569 22.9756 24.6013 22.9798C23.9458 22.9841 23.3181 23.2457 22.8536 23.7083C22.389 24.1709 22.1248 24.7974 22.1177 25.4529C22.1107 26.1085 22.3614 26.7405 22.8159 27.213L26.5879 30.9989Z"
        fill="var(--secondary-red-80)"
      />
    </svg>
  );
};
