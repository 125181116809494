import { OJStatus } from '@/typings/oj';

export const needTestcase = (status: OJStatus) => {
  return (
    status === OJStatus.MEMORY_LIMIT_EXCEED ||
    status === OJStatus.TIME_LIMIT_EXCEED ||
    status === OJStatus.WRONG_ANSWER ||
    status === OJStatus.RUNTIME_ERROR
  );
};
