export const RestartSvg: React.FC<SVGProps> = (props) => {
  return (
    <svg
      width="116"
      height="116"
      viewBox="0 0 116 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="58.5803" cy="58" r="49.7143" fill="#F8F8FD" />
      <path
        d="M37.8314 61.0018C36.9962 62.0732 36.5338 63.3859 36.5338 64.7517C36.5338 66.9186 37.7817 68.9696 39.7901 70.1134C40.3072 70.4078 40.8959 70.5628 41.4953 70.5622L53.4168 70.5622L53.1185 76.4934C53.0489 77.9267 53.5709 79.2877 54.5851 80.3253C55.0828 80.8367 55.6833 81.2436 56.3494 81.5209C57.0155 81.7981 57.7331 81.9398 58.4578 81.9372C61.043 81.9372 63.3298 80.2481 64.0159 77.8302L68.2863 62.8212L74.7144 62.8212C75.5943 62.8212 76.3053 62.1311 76.3053 61.2769L76.3053 43.7101C76.3053 42.8559 75.5943 42.1658 74.7144 42.1658L44.8212 42.1658C44.3638 42.1658 43.9164 42.2527 43.5038 42.4264C41.1374 43.4061 39.6111 45.6502 39.6111 48.1404C39.6111 48.7485 39.7006 49.3469 39.8796 49.9261C39.0444 50.9974 38.5821 52.3101 38.5821 53.6759C38.5821 54.284 38.6715 54.8824 38.8505 55.4615C38.0153 56.5329 37.553 57.8456 37.553 59.2114C37.5629 59.8194 37.6524 60.4227 37.8314 61.0018ZM72.7258 45.6406L72.7258 59.3465L68.699 59.3465L68.699 45.6406L72.7258 45.6406ZM41.0877 62.6765L42.1764 61.7595L41.4854 60.5337C41.2577 60.1299 41.1397 59.6765 41.1423 59.2162C41.1423 58.4199 41.5003 57.6622 42.1167 57.141L43.2055 56.2241L42.5145 54.9982C42.2868 54.5944 42.1688 54.141 42.1714 53.6807C42.1714 52.8844 42.5294 52.1267 43.1458 51.6055L44.2346 50.6886L43.5435 49.4628C43.3159 49.059 43.1978 48.6056 43.2005 48.1453C43.2005 47.0642 43.8567 46.0894 44.8709 45.6454L65.5173 45.6454L65.5173 59.5009L60.5707 76.8988C60.4431 77.3447 60.1693 77.7381 59.7908 78.0194C59.4122 78.3007 58.9495 78.4545 58.4727 78.4576C58.0949 78.4576 57.7221 78.3514 57.4238 78.1343C56.9316 77.7771 56.6681 77.2366 56.6979 76.6623L57.1752 67.0875L41.545 67.0875C40.6601 66.5614 40.1133 65.6734 40.1133 64.7517C40.1133 63.9554 40.4712 63.2025 41.0877 62.6765Z"
        fill={props.color || '#B5B8CB'}
      />
    </svg>
  );
};
