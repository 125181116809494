import { useEffect, useState } from 'react';
import noScroll from 'no-scroll';
import NiceModal from '@ebay/nice-modal-react';

import { ReactComponent as BackSvg } from '@/icons/back/icon.svg';
import { Loading } from '@/atoms/loading';

import { ExerciseWelcome } from './welcome';
import { ExerciseRecord } from './record';

import styles from './exercise.module.less';
import {
  ExerciseContextContainer,
  useExerciseContext,
} from './exercise.context';
import { initExercise } from './exercise.service';
import Result from './result';
import { usePageSession } from '@/hooks/page-session';

interface Props {
  learningPathId: number;
  lessonContentId: number;
  sessionUuid?: string;
  title: string;
  learnMode?: string;
  type?: string;
  exerciseInfo?: { globalStatus: string; intro: string };
  onClose: () => void;
}

export const InnerExercise = ({
  learningPathId,
  lessonContentId,
  sessionUuid,
  type = 'point', //multi
  title,
  onClose,
  learnMode,
  exerciseInfo,
}: Props) => {
  // 划入动效，同时关闭外部单元页的scroll
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
    noScroll.on();
    return () => {
      noScroll.off();
    };
  }, []);

  const { state, dispatch } = useExerciseContext();

  useEffect(() => {
    (async function initState() {
      try {
        const exercise = await initExercise(
          learningPathId,
          lessonContentId,
          sessionUuid,
          type,
        );
        dispatch({ type: 'init', data: { ...exercise, lessonContentId } });
      } catch (error) {}
    })();
  }, [lessonContentId, sessionUuid, type, dispatch, learningPathId]);

  let content = null;
  if (state.status === 'loading') {
    content = (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  } else if (state.status === 'unstarted') {
    // 没有开始session，展示欢迎页
    content = (
      <ExerciseWelcome
        learningPathId={learningPathId}
        questionNum={state.questionNum}
        lessonContentId={lessonContentId}
        type={type}
        exerciseInfo={exerciseInfo}
      />
    );
  } else if (state.status === 'ongoing') {
    content = <ExerciseRecord type={type} learningPathId={learningPathId} />;
  } else if (state.status === 'finished') {
    content = (
      <Result
        learningPathId={learningPathId}
        learnMode={learnMode || ''}
        onClose={onClose}
        lessonContentId={lessonContentId}
        type={type}
        exerciseInfo={exerciseInfo}
      />
    );
  }

  return (
    <div className={styles.wrapper} data-visible={visible}>
      <div className={styles.header}>
        <div className={styles.back} onClick={onClose}>
          <BackSvg />
        </div>
        <div className={styles.main}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>
            {type === 'multi' ? '综合练习' : '知识点练习'}
          </div>
        </div>
      </div>
      <div>{content}</div>
    </div>
  );
};

export const Exercise = (props: Props) => {
  usePageSession('exercise', { lessonContentId: props.lessonContentId });

  return (
    <ExerciseContextContainer>
      <NiceModal.Provider>
        <InnerExercise {...props} />
      </NiceModal.Provider>
    </ExerciseContextContainer>
  );
};
