import OSS from 'ali-oss';
import remote from './remote';

const getSts = (): Promise<{
  credentials: {
    SecurityToken: string;
    AccessKeyId: string;
    AccessKeySecret: string;
    Expiration: string;
  };
  bucket: string;
}> => {
  return remote.$get('/user/oss-sts');
};

export const uploadOSS = async (
  data: File,
  filename: string,
  //   headers: any,
  onPorgress: (p: number) => void,
) => {
  const { credentials, bucket } = await getSts();
  const client = new OSS({
    region: 'oss-cn-shanghai',
    accessKeyId: credentials.AccessKeyId,
    accessKeySecret: credentials.AccessKeySecret,
    stsToken: credentials.SecurityToken,
    bucket,
  });

  //   const headers = {
  //     // 指定该Object被下载时的网页缓存行为。
  //     'Cache-Control': 'no-cache',
  //     // 指定该Object被下载时的名称。
  //     'Content-Disposition': 'example.txt',
  //     // 指定该Object被下载时的内容编码格式。
  //     'Content-Encoding': 'utf-8',
  //     // 指定过期时间，单位为毫秒。
  //     Expires: '1000',
  //     // 指定Object的存储类型。
  //     'x-oss-storage-class': 'Standard',
  //     // 指定Object标签，可同时设置多个标签。
  //     'x-oss-tagging': 'Tag1=1&Tag2=2',
  //     // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  //     'x-oss-forbid-overwrite': 'true',
  //   };

  // 指定上传到examplebucket的Object名称，例如exampleobject.txt。
  const name = filename;

  return await client.multipartUpload(name, data, {
    // 获取分片上传进度、断点和返回值。
    progress: (p, cpt, res) => {
      if (onPorgress) onPorgress(Number(p));
    },
    parallel: 1,
    partSize: 1024 * 1024 * 1024,
  });
};

export const downloadOSS = async (filename: string, fullpath: string) => {
  const { credentials, bucket } = await getSts();
  const client = new OSS({
    region: 'oss-cn-shanghai',
    accessKeyId: credentials.AccessKeyId,
    accessKeySecret: credentials.AccessKeySecret,
    stsToken: credentials.SecurityToken,
    bucket,
  });
  const response = {
    'content-disposition': `attachment; filename=${encodeURIComponent(
      filename,
    )}`,
  };
  // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
  const url = client.signatureUrl(fullpath, { response });
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  link.download = filename;
  link.click();
};
