export type OJLanguage = 'CPP' | 'Python3';

export enum OJStatus {
  JUDGING = 'judging',
  COMPILE_ERROR = 'compile-error',
  ACCEPTED = 'accepted',
  PARTRAIL_ACCEPTED = 'partrail-accepted',
  WRONG_ANSWER = 'wrong-answer',
  RUNTIME_ERROR = 'runtime-error',
  TIME_LIMIT_EXCEED = 'time-limit-exceed',
  MEMORY_LIMIT_EXCEED = 'memory-limit-exceed',
  SYSTEM_ERROR = 'system-error',
  FINISHED = 'finished',
}

export const OJStatusText = {
  [OJStatus.JUDGING]: '正在评分',
  [OJStatus.COMPILE_ERROR]: '编译失败',
  [OJStatus.ACCEPTED]: '答案正确',
  [OJStatus.PARTRAIL_ACCEPTED]: '部分错误',
  [OJStatus.WRONG_ANSWER]: '答案错误',
  [OJStatus.RUNTIME_ERROR]: '运行时错误',
  [OJStatus.TIME_LIMIT_EXCEED]: '运行超时',
  [OJStatus.MEMORY_LIMIT_EXCEED]: '内存超限',
  [OJStatus.SYSTEM_ERROR]: '系统错误',
  [OJStatus.FINISHED]: '运行结束',
};

export type OJRunSubmission = {
  status: OJStatus;
  stdStdOut: string;
  userExitCode: number;
  userCpuTime: number;
  userMemory: number;
  userStdOut: string;
  userStdErr: string;
  userErrorInfo: string;
};

export enum OJConosleTabEnum {
  INPUT = '自测输入',
  SUBMISSION = '运行输出',
}
export type OJConsoleTab = keyof typeof OJConosleTabEnum;

export type OJFailedTestcase = {
  inputFile: string;
  inputPreview: string;
  inputTruncated: boolean;
  outputPreview: string;
  outputFile: string;
  outputTruncated: boolean;
  userOutput: string;
  userOutputTruncated: boolean;
};

export type OJQuestionSubmission = {
  status: OJStatus;
  timeCost: number;
  memoryCost: number;
  score: number;
  totalTestPoints: number;
  passedTestPoints: number;
  maxPassedTestPoints: number;
  errorInfo: string;
};
