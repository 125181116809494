export const CPUIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 12.9873H12.5V3.9873H3.5V12.9873ZM9.5 14.4873H6.5V15.9873H5V14.4873H2.75C2.55109 14.4873 2.36032 14.4083 2.21967 14.2676C2.07902 14.127 2 13.9362 2 13.7373V11.4873H0.5V9.9873H2V6.9873H0.5V5.4873H2V3.2373C2 3.03839 2.07902 2.84763 2.21967 2.70697C2.36032 2.56632 2.55109 2.4873 2.75 2.4873H5V0.987305H6.5V2.4873H9.5V0.987305H11V2.4873H13.25C13.4489 2.4873 13.6397 2.56632 13.7803 2.70697C13.921 2.84763 14 3.03839 14 3.2373V5.4873H15.5V6.9873H14V9.9873H15.5V11.4873H14V13.7373C14 13.9362 13.921 14.127 13.7803 14.2676C13.6397 14.4083 13.4489 14.4873 13.25 14.4873H11V15.9873H9.5V14.4873ZM5 5.4873H11V11.4873H5V5.4873Z"
        fill="#9C93FF"
      />
    </svg>
  );
};
