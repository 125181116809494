import remote, { RemoteRequestOptions } from '@/utils/remote';
import { useCallback, useState } from 'react';

/**
 * 暂不支持匿名用户事件
 */
export default function useReportEvent() {
  // const [trackId, setTrackId] = useState(null);
  const [jobs /* , setJobs */] = useState([]);

  // TODO: 生成 trackId / 关联 trackId & user
  // useEffect(() => {
  //   const localId = localStorage.getItem('trackId');
  //   if (!isLogin()) {
  //     if (localId) {
  //       setTrackId(localId);
  //     } else {
  //       // 未登录用户需要分发一个追踪ID
  //       $post(
  //         '/events/track-ids',
  //         {},
  //         { hideLoading: true, throwException: true }
  //       )
  //         .then(uuid => {
  //           localStorage.setItem('trackId', uuid);
  //           setTrackId(uuid);
  //         })
  //         .catch(e => {
  //           setTrackId('error');
  //         });
  //     }
  //   } else {
  //     localStorage.removeItem('trackId');
  //     setTrackId('logined');
  //     if (localId) {
  //       $post(
  //         `/events/track-ids/${localId}/claim`,
  //         {},
  //         { hideLoading: true, throwException: true }
  //       )
  //         .then(() => {
  //           // noop
  //         })
  //         .catch(e => {
  //           // noop
  //         });
  //     }
  //   }
  // }, []);

  const sendEvent = useCallback(
    async ({
      trackId,
      event,
      payload,
      extraHeaders,
    }: {
      trackId?: string;
      event: string;
      payload: Record<string, any>;
      extraHeaders?: RemoteRequestOptions['extraHeaders'];
    }) => {
      try {
        const clientTime = new Date().getTime();
        let fixedPayload = { clientTime };
        if (payload) {
          fixedPayload = {
            ...payload,
            ...fixedPayload,
          };
        }
        await remote.$post(
          `/event/${event}`,
          {
            payload: fixedPayload,
            trackId,
          },
          {
            extraHeaders,
          },
          // { hideLoading: true, throwException: true }
        );
      } catch (e) {
        // noop
      }
    },
    [],
  );

  // useEffect(() => {
  //   if (trackId && trackId !== 'error' && jobs.length) {
  //     jobs.forEach(job => sendEvent(trackId, job.event, job.payload));
  //     setJobs([]);
  //   }
  // }, [trackId, jobs, sendEvent]);

  const reportEvent = useCallback(
    async (
      event,
      payload,
      extraHeaders?: RemoteRequestOptions['extraHeaders'],
    ) => {
      // if (!trackId) {
      //   // 等待中
      //   setJobs(prev => [...prev, { event, payload }]);
      // } else if (trackId === 'error') {
      //   // noop
      // } else {
      //   await sendEvent({trackId, event, payload});
      // }

      await sendEvent({ event, payload, extraHeaders });
    },
    [/* trackId, */ sendEvent],
  );

  return { reportEvent, ready: jobs.length === 0 };
}
