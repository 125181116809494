import {
  OJConsoleTab,
  OJFailedTestcase,
  OJLanguage,
  OJRunSubmission,
  OJStatus,
} from '@/typings/oj';

export type AnswerStatus = 'unsubmitted' | 'correct' | 'wrong';
export type RecordStatus = 'unsubmitted' | 'correct' | 'wrong' | 'mastered';

export type RecordOJSubmission = {
  recordStatus: RecordStatus;
  status: OJStatus;
  timeCost: number; // in ms
  memoryCost: number; // in bytes
  score: number;
  errorInfo: string;
  totalTestCases: number;
  passedTestCases: number;
  exitCode?: number;
  errorLines?: number[];
};

/**
 * 暂时取消了选项乱序，因为多选题尚未实现，会影响“以上都对”这种选项。
 */
export type ExerciseQuizQuestion = {
  id: number;
  // platformId?: number;
  hasSolution: boolean;
  hasMultiAnswers: boolean;
  type: 'select'; // 单选、多选
  title: string;
  options: { text: string }[];
  // shuffledOptions?: {
  //   value: string;
  //   mark: string; // ABCD
  //   text: string;
  // }[];

  // 额外接口
  solution?: string;
  answers?: string[];
  // shuffledAnswers?: string[];
};

export type ExerciseOJProblem = {
  id: number;
  hasSolution: boolean;
  title: string;
  description: string;
  inputDescription: string;
  outputDescription: string;
  samples: {
    input: string;
    output: string;
  }[];
  hint: string;
  template: Record<OJLanguage, string>;

  // 额外接口
  solution?: string;
  answers?: string[];
};

export type LearningResource = {
  id: number;
  title: string;
  url: string;
};

export type ExerciseQuestionType = 'oj-problem' | 'quiz-question';
export type ExerciseViewStatus =
  | 'only-question'
  | 'show-resources'
  | 'show-solution';

type ExerciseBaseRecord = {
  index: number; // 当前题目 index（用户刷新页面后，服务端不再返回之前题目，所以需要索引值）
  // 视图状态（看过题解？看过知识点？）
  viewStatus: ExerciseViewStatus;
  userAnswers: string[]; // 是否提交，提交的内容，数组适配多选题，普通题型取[0]
  answerStatus: AnswerStatus; // 纯前端状态，用于重试
  status: RecordStatus; // 步骤得分状态
  recordId: number;
  learningResources: LearningResource[]; // 相关学习资源链接
  questionType: ExerciseQuestionType;
  question: ExerciseOJProblem | ExerciseQuizQuestion;
};

export enum QuizTabEnum {
  QUESTION = '练习题目',
}

export type QuizTab = keyof typeof QuizTabEnum;

interface ExerciseQuizRecord extends ExerciseBaseRecord {
  questionType: 'quiz-question';
  question: ExerciseQuizQuestion;
  tab: QuizTab;
}

export enum OJTabEnum {
  PROBLEM = '练习题目',
  SUBMISSION = '提交结果',
  SOLUTION = '提示',
  EXPLAINATION = '智能解析',
}
export type OJTab = keyof typeof OJTabEnum;

// 显示运行/提交的错误解析
export type OJExplanationMode = 'console' | 'submission';

interface ExerciseOJRecord extends ExerciseBaseRecord {
  questionType: 'oj-problem';
  question: ExerciseOJProblem;
  tab: OJTab;
  language: OJLanguage;

  consoleVisible?: boolean;
  consoleTab?: OJConsoleTab;
  consoleInput?: string;
  consoleSubmission?: OJRunSubmission; // 运行结果

  lastSubmission?: RecordOJSubmission; // 提交结果
  failedTestcase?: OJFailedTestcase;

  // 智能解析
  explanationMode?: OJExplanationMode;
  explanation?: string;
  consoleExplanation?: string;
}

export type ExerciseRecordType = ExerciseQuizRecord | ExerciseOJRecord;

export type ExerciseResultType = {
  degrees: { name: string; curr: number; prev: number }[];
  correctQuestions: number;
  currentScore: number;
  totalQuestions: number;
  totalScore: number;
  scores: { degrees: { name: string; curr: number; prev: number } }[];
};

export type ExerciseType =
  | LoadingExercise
  | UnstartedExercise
  | OngoingExercise
  | FinishedExercise;

export type LoadingExercise = {
  status: 'loading';
};

export type UnstartedExercise = {
  status: 'unstarted';
  title: string;
  questionNum: number;
  lessonContentId: number;
};

export type OngoingExercise = {
  status: 'ongoing';
  title: string;
  /**
   * 随着算法迭代，questionNum 可能会变化，不可完全信任，进行时请用 mastery.length
   */
  questionNum: number;
  lessonContentId: number;
  sessionUuid: string;
  mastery: number[]; // 所有题目得分情况; 1 = 得分; 0 = 不得分
  current: number;
  records: ExerciseRecordType[];
};

export type FinishedExercise = {
  status: 'finished';
  title: string;
  questionNum: number;
  lessonContentId: number;
  result: ExerciseResultType;
};
