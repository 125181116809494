// 注册登录相关 API
// https://dev.qingzhouzhixue.com/api/v1/swagger/#/%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E7%99%BB%E5%BD%95

import { useCallback, useContext, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
  CountryPhoneInputValue,
  CountryPhoneInput,
} from 'antd-country-phone-input/dist/lite';
import 'rc-select/assets/index.less';
import CaptchaInput from '@/components/captcha-input';
import remote, { RemoteError } from '@/utils/remote';
import { Button } from '@/atoms/button';
import { useHistory } from 'react-router';
import { User } from '@/typings';
import styles from './login.module.less';
import { goBack } from './utils';
import { AppContext } from '../../App';
import { ModalProps, QzNiceModal } from '@/atoms/modal';

// 波波鱼 login 项目有一套灵活的中间层，同时支持 3 * n 个页面，青舟在实现时建议参考一下。
export const LoginPage = () => {
  const history = useHistory();
  const { setUser } = useContext(AppContext);
  const [phoneInput, setPhoneInput] = useState<CountryPhoneInputValue>({
    short: 'CN',
  });
  const [verifyCode, setVerifyCode] = useState<string>();

  const handleSend = useCallback(async () => {
    return remote.$post('/verify-code', {
      countryCode: phoneInput.code,
      phone: phoneInput.phone,
    });
  }, [phoneInput]);

  const handleLogin = useCallback(async () => {
    try {
      const jwt: { accessToken: string } = await remote.$post('/auth', {
        countryCode: phoneInput.code,
        phone: phoneInput.phone,
        verifyCode: Number(verifyCode),
      });

      localStorage.setItem('qz-jwt', jwt.accessToken);
      localStorage.setItem('qz-jwt-issued-at', new Date().toISOString());
      const user: User = await remote.$get('/user');
      setUser(user);
      goBack({ history });
    } catch (error) {
      const err = error as RemoteError;
      if (!err || !err.isAxiosError) return;
      NiceModal.show(QzNiceModal, {
        title: '登录失败',
        desc: err.response?.data?.message || '手机号或验证码有误',
      } as ModalProps);
    }
  }, [history, phoneInput.code, phoneInput.phone, setUser, verifyCode]);

  return (
    <div className={styles.loginBg}>
      <div className={styles.loginWrap}>
        <div className={styles.loginLeft}>
          <div className={styles.loginLogo}> </div>
          <img
            src="https://staticcdn.boyuai.com/user-assets/5524/B9ZydC44x89APguJNeK77d/login-astronaut.png!png"
            alt="login-astronaut"
            className={styles.loginLeftImg}
          />
          <div className={styles.loginTitle}>欢迎来到青舟智学</div>
          <div className={styles.loginAppeal}>让我们一起来学习吧</div>
        </div>
        <div className={styles.loginRight}>
          <div className={styles.loginRightTitle}>账号登录</div>
          <div className={styles.inputWrapper}>
            <CountryPhoneInput
              value={phoneInput}
              onChange={(v) => {
                setPhoneInput(v);
              }}
              style={{ paddingLeft: 20 }}
              placeholder="请输入手机号"
            />
          </div>
          <CaptchaInput
            className={styles.inputWrapper}
            value={verifyCode}
            onChange={(e) => setVerifyCode(e.target.value)}
            onSend={handleSend}
            // onSendError={(err) => console.error(err)}
            placeholder="输入验证码"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
            btnProps={{
              disabled: !phoneInput.phone,
            }}
            renderSendBtnChildren={(countdownLeft) =>
              countdownLeft === 0
                ? '获取验证码'
                : `重新发送 (${countdownLeft}s)`
            }
          />
          <div className={styles.loginHint}>
            未注册的手机号验证后自动创建账户
          </div>
          <div>
            <Button onClick={handleLogin} style={{ width: '100%' }}>
              登录
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.loginTerms}>
        注册即代表你同意
        <a
          href="https://www.boyuai.com/page/terms"
          rel="noreferrer"
          target="_blank"
        >
          用户协议
        </a>
        与
        <a
          href="https://www.boyuai.com/page/privacy"
          rel="noreferrer"
          target="_blank"
        >
          隐私条款
        </a>
      </div>
    </div>
  );
};
