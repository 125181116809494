import { CSSProperties } from 'react';
import { ReactComponent as Lock } from '@/icons/lock/icon.svg';
import { GreenCircleCheck, GreyCircleCheck } from '@/icons/circle-check';
import { Circle } from 'rc-progress';

import './status.less';

export const LearningStatus: React.FC<{
  status: string;
  mode: 'icon' | 'text';
  percent?: number;
  style?: CSSProperties;
  svgProps?: SVGProps;
  withLine?: boolean;
}> = ({ status, mode = 'icon', percent, style = {}, svgProps, withLine }) => {
  if (mode === 'icon') {
    let icon;
    if (status === 'skipped') {
      icon = <GreyCircleCheck style={style} {...svgProps} />;
    } else if (status === 'finished') {
      icon = <GreenCircleCheck style={style} {...svgProps} />;
    } else if (status === 'locked') {
      icon = <Lock style={style} {...svgProps} />;
    } else if (status === 'ongoing' || status === 'unstarted') {
      icon = (
        <Circle
          style={{ ...style }}
          trailWidth={16}
          strokeWidth={16}
          percent={percent}
        />
      );
    }
    let className = 'learning-status-icon';
    if (withLine) className += ' with-line';
    return <div className={className}>{icon}</div>;
  }

  let text;
  if (status === 'skipped') {
    text = '已跳过';
  } else if (status === 'finished') {
    text = '已完成';
  } else if (status === 'locked') {
    text = '未解锁';
  } else if (status === 'ongoing') {
    text = '学习中';
  } else if (status === 'current') {
    text = '当前学习';
  } else if (status === 'unstarted') {
    text = '未开始';
  }
  return (
    <div className="learning-status-text" data-status={status}>
      {text}
    </div>
  );
};
