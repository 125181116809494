import dayjs from 'dayjs';

export const computeHoursOrMinutes = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes} 分钟`;
  }
  const hours = Math.floor(minutes / 60);
  let text = `${hours} 小时`;
  const remaining = minutes % 60;
  if (remaining) {
    text += ` ${remaining} 分钟`;
  }
  return text;
};

export const QZFormalTimeRange = (startStr: string, endStr?: string) => {
  let timeText;
  const start = dayjs(startStr);
  const end = dayjs(endStr);
  if (!endStr) {
    timeText = start.format('YYYY年M月D日');
  } else {
    if (start.isSame(end, 'day')) {
      timeText =
        start.format('YYYY年M月D日') +
        '  ' +
        start.format('H:mm') +
        '-' +
        end.format('H:mm');
    } else if (start.isSame(end, 'year')) {
      timeText =
        start.format('YYYY年') +
        ' ' +
        start.format('M月D日H:mm') +
        ' - ' +
        end.format('M月D日H:mm');
    } else {
      timeText =
        start.format('YYYY年M月D日H:mm') +
        ' - ' +
        end.format('YYYY年M月D日H:mm');
    }
  }
  return timeText;
};

export const sleep = (timeout: number) => {
  return new Promise((res) => {
    setTimeout(() => {
      res(null);
    }, timeout);
  });
};

/**
 * 打乱array的排序
 * @param array
 */
export function shuffleArray(array: Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

/**
 * {@link https://stackoverflow.com/a/18650828}
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const isDesktop =
  window && window.matchMedia('(min-width:1025px)').matches;
export const isLandTablet =
  window &&
  window.matchMedia('(min-width:769px) and (max-width:1024px)').matches;
export const isTablet =
  window &&
  window.matchMedia('(min-width:481px) and (max-width:768px)').matches;

export const isPhone = window && window.matchMedia('(max-width:480px)').matches;

// https://stackoverflow.com/a/8809472
export function uuid() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * 控制promise的并发数
 * @param max 最大并发数
 * @param {Array<Function>} queue  待执行队列
 */
export function limitMaxCountPromise(max: number, queue: Array<any>) {
  if (max <= 0 || !Array.isArray(queue) || queue.length === 0) {
    return Promise.reject('参数不正确');
  }

  const copyedQueue: Array<any> = [...queue];
  const pool: any[] = [];
  copyedQueue.forEach((v, i) => {
    const index = Math.floor(i / max);
    if (pool[index] !== undefined) {
      pool[index].push(v);
    } else {
      pool[index] = [v];
    }
  });

  async function run(index: number) {
    let currentIndex = index;
    await Promise.all(
      pool[currentIndex].map((v: any) => {
        return v;
      }),
    ).finally(() => {
      if (pool[currentIndex + 1] !== undefined) {
        return run(currentIndex + 1);
      } else {
        return Promise.resolve();
      }
    });
  }

  return run(0);
}
