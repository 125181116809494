export const FeedbackIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91643 19L2.46143 22.5V4C2.46143 3.73478 2.56678 3.48043 2.75432 3.29289C2.94186 3.10536 3.19621 3 3.46143 3H21.4614C21.7266 3 21.981 3.10536 22.1685 3.29289C22.3561 3.48043 22.4614 3.73478 22.4614 4V18C22.4614 18.2652 22.3561 18.5196 22.1685 18.7071C21.981 18.8946 21.7266 19 21.4614 19H6.91643ZM4.46143 18.385L6.22443 17H20.4614V5H4.46143V18.385ZM11.4614 13H13.4614V15H11.4614V13ZM11.4614 7H13.4614V12H11.4614V7Z"
        fill="#B0B2B7"
      />
    </svg>
  );
};
