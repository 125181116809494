export const RestartSvg: React.FC<SVGProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2296 18.5536C15.7779 19.8115 13.9208 20.5027 12 20.5C7.5816 20.5 4 16.9184 4 12.5C4 8.0816 7.5816 4.5 12 4.5C16.4184 4.5 20 8.0816 20 12.5C20 14.2088 19.464 15.7928 18.552 17.092L16 12.5H18.4C18.3999 11.025 17.8902 9.59525 16.9573 8.45271C16.0244 7.31017 14.7255 6.52495 13.2803 6.22989C11.835 5.93482 10.3323 6.14801 9.02614 6.83341C7.72002 7.51881 6.69074 8.63433 6.11242 9.99126C5.5341 11.3482 5.44224 12.8632 5.85239 14.2801C6.26254 15.697 7.14951 16.9287 8.36326 17.7668C9.57701 18.605 11.043 18.9982 12.5133 18.8799C13.9836 18.7616 15.3679 18.1391 16.432 17.1176L17.2296 18.5536Z"
        fill="#B0B2B7"
      />
    </svg>
  );
};
