import { Button } from '@/atoms/button';
import { Modal } from '@/atoms/modal';
import { useState } from 'react';

import styles from './submit-button.module.less';

type Props = {
  handleSubmit: () => void;

  disableMessage?: string;
};

const SubmitButton = ({ handleSubmit, disableMessage = '' }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Modal
        title="提交试卷"
        open={open}
        className={styles.modal}
        onOpenChange={(open) => setOpen(open)}
      >
        {disableMessage}
        <br />
        你确认要提交试卷吗？
        <br />
        提交后将无法修改提交的答案
        <div className={styles.buttons}>
          <Button variant="sub" onClick={() => setOpen(false)}>
            取消
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              setOpen(false);
            }}
          >
            提交
          </Button>
        </div>
      </Modal>
      <Button onClick={() => setOpen(true)}>提交试卷</Button>
    </div>
  );
};

export default SubmitButton;
