import { useHistory } from 'react-router';
import { Button } from '@/atoms/button';
import { useEffect, useState } from 'react';
import styles from './not-found.module.less';

type Data = {
  status: string;
  method: string;
  url: string;
  requestId: string;
  message: string;
};

export const NotFound = () => {
  const [type, setType] = useState<string>('default-404');
  const [data, setData] = useState<Data>();
  const history = useHistory() as any;

  useEffect(() => {
    if (history.location?.state) {
      if (history.location.state?.status === 404) {
        setType('data-404');
        setData(history.location.state);
      } else {
        setType('data-403');
        setData(history.location.state);
      }
    }
  }, [history]);

  const goHome = () =>
    (window.location.href = process.env.REACT_APP_SUB_DIRECTORY || '/');

  const getPage = (status: string) => {
    switch (status) {
      case 'data-404':
        return (
          <>
            <div className={styles.title}>404，资源不存在 :(</div>
            <div className={styles.remark}>
              抱歉，我们的服务器出了点问题，请稍后重试或截图联系客服。
            </div>
            <img
              src="https://staticcdn.boyuai.com/user-assets/14410/1XtFHYKu4nPXiK8YmDXzLj/error404.png!png"
              alt="404"
            />
            <div className={styles.content}>
              <div>Method: {data?.method?.toUpperCase()}</div>
              <div>URL: {data?.url}</div>
              <div>Request ID: {data?.requestId}</div>
              <div>Message: {data?.message}</div>
            </div>
          </>
        );
      case 'data-403':
        return (
          <>
            <div className={styles.title}>无访问权限</div>
            <div className={styles.remark}>
              抱歉，你的账号没有该访问权限，请转至教师端重试或截图联系客服。
            </div>
            <img
              src="https://staticcdn.boyuai.com/user-assets/14410/uaNxe2NzomEMv2eBUiyhUF/error403.png!png"
              alt="404"
            />
            <div className={styles.content}>
              <div>Method: {data?.method?.toUpperCase()}</div>
              <div>URL: {data?.url}</div>
              <div>Request ID: {data?.requestId}</div>
              <div>Message: {data?.message}</div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={styles.bigTitle}>
              <span className={styles.big}>404</span>
              ，资源不存在 :(
            </div>
            <img
              src="https://staticcdn.boyuai.com/user-assets/14410/1XtFHYKu4nPXiK8YmDXzLj/error404.png!png"
              alt="404"
            />
          </>
        );
    }
  };

  return (
    <div className={styles.errorPage}>
      <div className={styles.page}>
        <div className={styles.info}>{getPage(type)}</div>

        <div className={styles.homeBtn}>
          <Button onClick={() => goHome()}>返回首页</Button>
        </div>
      </div>
    </div>
  );
};
