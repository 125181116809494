import { ReactComponent as GreenSvg } from './icon.svg';

export const GreenCircleCheck: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return <GreenSvg {...props} />;
};

export const GreyCircleCheck: React.FC<
  React.SVGProps<SVGSVGElement> & { title?: string }
> = (props) => {
  return <GreenSvg fill="#B0B2B7" {...props} />;
};
