/* eslint-disable no-console */
import { Reducer } from 'react';

const getCurrentTimeFormatted = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();
  const milliseconds = currentTime.getMilliseconds();
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const loggerMiddleware = <S, A extends { type: string }>(
  name: string,
  reducer: Reducer<S, A>,
) => {
  if (process.env.NODE_ENV === 'production') {
    return reducer;
  }
  const reducerWithLogger = (state: S, action: A) => {
    const next = reducer(state, action);
    console.groupCollapsed(
      `%c${name} Context %cAction: %c${
        action.type
      } %cat ${getCurrentTimeFormatted()}`,
      'color: orange; font-weight: bold;',
      'color: green; font-weight: bold;',
      'color: gray; font-weight: bold;',
      'color: lightblue; font-weight: lighter;',
    );
    console.log(
      '%cPrevious State:',
      'color: #9E9E9E; font-weight: 700;',
      state,
    );
    console.log('%cAction:', 'color: #00A7F7; font-weight: 700;', action);
    console.log('%cNext State:', 'color: #47B04B; font-weight: 700;', next);
    console.groupEnd();
    return next;
  };

  return reducerWithLogger;
};
