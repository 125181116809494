import ReactLoading, { LoadingProps } from 'react-loading';
import './index.less';

export const Loading: React.FC<LoadingProps> = (props) => (
  <div className="qz-loading">
    <ReactLoading
      delay={500} // 和 pace.js 保持一致，太短的 loading 容易造成不好的体验
      type="spin"
      color="#7b6fff"
      height={48}
      width={48}
      {...props}
    />
  </div>
);
