import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import styles from './animation.module.less';

const Animation = () => {
  const animationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!animationRef.current) {
      return;
    }
    lottie.loadAnimation({
      container: animationRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://staticcdn.boyuai.com/materials/2021/04/12/8586-rocket-in-space.json',
    });
  }, []);
  return (
    <div className={styles.wrapper}>
      <div ref={animationRef} style={{ width: 358, height: 358 }} />

      <div className={styles.loader}>
        <div className={styles.loaderBar} />
      </div>
      <div className={styles.msg}>正在为你生成专属的学习路径</div>
    </div>
  );
};

export default Animation;
