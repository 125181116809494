import React from 'react';
import { Root, Trigger, Content, Arrow } from '@radix-ui/react-popover';

import './index.less';

export const Popover: React.FC<{ content: React.ReactNode }> = ({
  children,
  content,
}) => {
  return (
    <Root>
      <Trigger className="qz-popover-trigger">{children}</Trigger>
      <Content sideOffset={20} align="center" className="qz-popover-content">
        <Arrow width={40} height={20} className="qz-popover-arrow" />
        {content}
      </Content>
    </Root>
  );
};

export const PopoverRectangle: React.FC<{ content: React.ReactNode }> = ({
  children,
  content,
}) => {
  return (
    <Root>
      <Trigger className="qz-popover-trigger">{children}</Trigger>
      <Content
        alignOffset={10}
        sideOffset={10}
        align="center"
        className="qz-popover-content qz-popoverRectangle-content"
      >
        {content}
      </Content>
    </Root>
  );
};
