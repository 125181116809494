import * as RTooltip from '@radix-ui/react-tooltip';
import React from 'react';

import './remark.less';

type side = 'left' | 'top' | 'right' | 'bottom' | undefined;

export const TooltipRemark: React.FC<{
  content: React.ReactNode;
  location?: side;
}> = ({ children, content, location }) => (
  <RTooltip.Provider>
    <RTooltip.Root delayDuration={0}>
      <RTooltip.Trigger className="qz-tooltip-trigger">
        {children}
      </RTooltip.Trigger>
      <RTooltip.Content
        className="qz-tooltip-content"
        side={location ? location : 'bottom'}
      >
        <RTooltip.Arrow offset={50} fill="var(--system-background)" />
        {content}
      </RTooltip.Content>
    </RTooltip.Root>
  </RTooltip.Provider>
);
