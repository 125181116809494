import React, { useState, useEffect } from 'react';

export function useOnScreen(
  ref: React.RefObject<HTMLDivElement>,
  rootMargin?: string,
) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    {
      rootMargin,
      threshold: 0, // half of item height
    },
  );

  useEffect(() => {
    if (ref && ref.current) observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}
