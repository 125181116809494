import { UserExam } from '@/typings/exam';

export const Default_Exam_Rule =
  '\n**注意事项**\n\n1. 考试分为一个或多个部分\n2. 进入每个部分前需要授权摄像头和屏幕录制\n3. 考试时系统会进入全屏，手动退出全屏可能会影响你的成绩\n4. 每个部分需要在限制的时间内完成答题\n5. 每个部分试卷提交后无法修改\n6. 完成全部部分后可以提交考试试卷';
export const Default_Exam_Pass_Rule = '\n7. 达到考试的通过分数即可获得认证证书';
export const Default_Exam_Parts_Pass_Rule =
  '\n7. 达到考试的通过分数，并且全部部分都达到通过分数，即可获得认证证书';

export function getExamRule(exam: UserExam) {
  // if (exam.examRule) {
  //   return exam.examRule;
  // }
  if (exam.partsPassScore) {
    return Default_Exam_Rule;
    // return Default_Exam_Rule + Default_Exam_Parts_Pass_Rule;
  } else {
    return Default_Exam_Rule;
    // return Default_Exam_Rule + Default_Exam_Pass_Rule;
  }
}
