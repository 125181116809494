import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import NiceModal from '@ebay/nice-modal-react';

import { PaperFileUploadPart } from '@/typings/exam';
import { Loading } from '@/atoms/loading';
import { Progress } from '@/atoms/progress';
import { downloadOSS, uploadOSS } from '@/utils/oss';
import { Markdown } from '@/components/markdown';
import { Modal, ModalProps, QzNiceModal } from '@/atoms/modal';
import { ReactComponent as UploadSvg } from '@/icons/upload/icon.svg';
import { ReactComponent as DownloadSvg } from '@/icons/download/icon.svg';
import { ReactComponent as DeleteSvg } from '@/icons/delete/icon.svg';

import TopBar from './top-bar';
import SubmitButton from './submit-button';
import baseStyles from './base.module.less';
import styles from './file-upload-part.module.less';
import { AppContext } from '../../../App';
import remote from '@/utils/remote';
import { humanFileSize } from '@/utils';
import { Button } from '@/atoms/button';
import { useHistory } from 'react-router-dom';

type Props = {
  part: PaperFileUploadPart;
  examId: number;
  examTitle: string;
  webcamStatus: string;
  screenStatus: string;
};

const FileUploader = ({
  files,
  examId,
  partId,
  onUploaded,
}: {
  files?: {
    filename: string;
    fullpath: string;
    size: number;
  }[];
  examId: number;
  partId: number;
  onUploaded: (
    files: {
      filename: string;
      fullpath: string;
      size: number;
    }[],
  ) => void;
}) => {
  const { user } = useContext(AppContext);
  const userId = user?.id;
  const [uploadedFile, setUploadedFile] = useState(() => {
    if (files && files[0]) {
      const file = files[0];
      return {
        state: 'finish',
        progess: 0,
        filename: file.filename,
        fullpath: file.fullpath,
        filesize: file.size,
      };
    }
    return {
      state: 'none',
      progess: 0,
      filename: '',
      fullpath: '',
      filesize: 0,
    };
  });
  const onProgress = useCallback((p: number) => {
    setUploadedFile((pre) => ({ ...pre, progess: Math.ceil(p * 100) }));
  }, []);
  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      let surfix;
      if (file.type === 'application/zip') surfix = '.zip';
      if (file.type === 'application/rar') surfix = '.rar';
      const filesize: number = file.size;
      const filename: string = `user_${userId}_submittion${surfix}`;
      setUploadedFile(() => ({
        state: 'uploading',
        progess: 0,
        filename: filename,
        fullpath: '',
        filesize,
      }));
      const fullpath = `qingzhou/${process.env.REACT_APP_ENV}/cert-exam/examId-${examId}/${filename}`;
      await uploadOSS(file, fullpath, onProgress);
      const files = [
        {
          filename,
          fullpath,
          size: filesize,
        },
      ];
      await remote.$patch(
        `/user/exams/${examId}/exam-paper-parts/${partId}/uploadedOSSFiles`,
        {
          uploadedOSSFiles: files,
        },
      );
      onUploaded(files);

      setUploadedFile(() => ({
        state: 'finish',
        progess: 0,
        filename,
        fullpath,
        filesize,
      }));
    },
    [onProgress, userId, examId, partId, onUploaded],
  );

  const onDropRejected = useCallback((fileRejections) => {
    const errorCode: string = fileRejections[0].errors[0].code;
    const errorMap: Record<string, string> = {
      'file-too-large': '文件太大了',
      'file-invalid-type': '文件类型错误',
    };
    let errorMessage = errorMap[errorCode];
    NiceModal.show(QzNiceModal, {
      width: 300,
      title: '无法上传',
      desc: errorMessage || '文件不符合规范',
    } as ModalProps);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    disabled: uploadedFile.state === 'uploading',
    multiple: false,
    maxFiles: 1,
    maxSize: 200 * 1024 * 1024,
    accept: 'application/zip, application/rar',
    onDropAccepted,
    onDropRejected,
  });
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDelete = useCallback(async () => {
    await remote.$patch(
      `/user/exams/${examId}/exam-paper-parts/${partId}/uploadedOSSFiles`,
      {
        uploadedOSSFiles: [],
      },
    );
    onUploaded([]);
    setUploadedFile(() => ({
      state: 'none',
      progess: 0,
      filename: '',
      fullpath: '',
      filesize: 0,
    }));
  }, [examId, partId, onUploaded]);

  if (!userId) {
    return null;
  }
  return (
    <div className={styles.uploader}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <UploadSvg />
        <div>{uploadedFile.filename ? '覆盖上传' : '上传文件'}</div>
        <div>（大小限制：200MB，类型限制：rar、zip）</div>
      </div>
      <div>
        {uploadedFile.filename && (
          <div className={styles.uploaded}>
            <div className={styles.uploadedContent}>
              <div className={styles.uploadedContentFirstRow}>
                <DownloadSvg
                  onClick={() =>
                    downloadOSS(uploadedFile.filename, uploadedFile.fullpath)
                  }
                />
                <div>{uploadedFile.filename}</div>
              </div>
              <div className={styles.uploadedContentSecondRow}>
                {uploadedFile.state === 'uploading' && (
                  <Progress
                    style={{
                      height: 4,
                      borderRadius: 10,
                      margin: '4px 0',
                      width: '100%',
                    }}
                    value={uploadedFile.progess}
                  />
                )}
                <div className={styles.uploadedContentSize}>
                  {humanFileSize(uploadedFile.filesize || 0, true)}
                </div>
              </div>
            </div>
            <div className={styles.uploadedDelete}>
              <div onClick={() => setDeleteOpen(true)}>
                <DeleteSvg width={20} height={20} />
              </div>
              <Modal
                title="移除文件"
                open={deleteOpen}
                className={styles.deleteModal}
                onOpenChange={(open) => setDeleteOpen(open)}
              >
                你确认要移除已经上传的文件吗？
                <br />
                删除后将无法找回
                <div className={styles.deleteButtons}>
                  <Button variant="sub" onClick={() => setDeleteOpen(false)}>
                    取消
                  </Button>
                  <Button
                    onClick={() => {
                      handleDelete();
                      setDeleteOpen(false);
                    }}
                  >
                    提交
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const FileUploadPart = ({
  examId,
  examTitle,
  part,
  webcamStatus,
  screenStatus,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState(part.uploadedOSSFiles);
  const history = useHistory();

  const partId = part.id;
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    await remote.$patch(
      `/user/exams/${examId}/exam-paper-parts/${partId}/status`,
      {
        status: 'submitted',
      },
    );
    history.replace(`/cert-exam/${examId}/session`);
  }, [examId, partId, history]);

  return (
    <div className={baseStyles.wrapper}>
      {submitting && (
        <div className={baseStyles.loading}>
          <Loading />
          试卷 {part.title} 提交中
        </div>
      )}
      <TopBar
        examId={examId}
        examTitle={examTitle}
        partTitle={part.title}
        startedAt={part.startedAt}
        timeLimit={part.timeLimit}
        onTimeUp={handleSubmit}
        webcamStatus={webcamStatus}
        screenStatus={screenStatus}
      />
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.contentTitle}>题目描述</div>

          <div>
            <Markdown linkTarget="_blank">{part.desc}</Markdown>
          </div>
          <div className={styles.contentTitle}>答案上传</div>
          <FileUploader
            files={files}
            onUploaded={setFiles}
            examId={examId}
            partId={partId}
          />
        </div>
      </div>
      <div className={baseStyles.footer}>
        <div className={baseStyles.footerSide}></div>
        <SubmitButton
          handleSubmit={handleSubmit}
          disableMessage={files && files.length > 0 ? '' : '尚未上传文件'}
        />
        <div className={baseStyles.footerSide}></div>
      </div>
    </div>
  );
};

export default FileUploadPart;
