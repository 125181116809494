export const Link = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9844 15.5156L13.0234 14.4609C11.9922 14.3828 11.3203 14.0703 10.8125 13.5625C9.44531 12.1953 9.45313 10.2578 10.8047 8.90625L13.3516 6.35938C14.7188 5 16.6406 4.99219 18.0078 6.35938C19.375 7.72656 19.3594 9.65625 18.0078 11.0078L16.4766 12.5391C16.6953 13.0391 16.7422 13.6172 16.6641 14.125L18.9609 11.8359C20.8281 9.96094 20.8438 7.3125 18.9531 5.41406C17.0547 3.51563 14.3984 3.53125 12.5234 5.40625L9.85938 8.07813C7.98438 9.95313 7.96875 12.6094 9.86719 14.5C10.3594 14.9922 10.9844 15.3438 11.9844 15.5156ZM12.1797 9.07813L11.1406 10.1328C12.1719 10.2188 12.8438 10.5234 13.3516 11.0313C14.7188 12.3984 14.7109 14.3359 13.3594 15.6875L10.8047 18.2344C9.44531 19.5938 7.52344 19.6016 6.15625 18.2422C4.78906 16.8672 4.79688 14.9453 6.15625 13.5859L7.6875 12.0547C7.46875 11.5625 7.41406 10.9766 7.5 10.4688L5.20313 12.7578C3.33594 14.6328 3.32031 17.2891 5.21094 19.1797C7.10938 21.0781 9.76563 21.0625 11.6328 19.1953L14.3047 16.5156C16.1797 14.6406 16.1953 11.9844 14.2969 10.0938C13.8047 9.60156 13.1797 9.25 12.1797 9.07813Z"
        fill="currentColor"
      />
    </svg>
  );
};
