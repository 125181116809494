/*  通过id跳转查看某个考题  */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import remote from '@/utils/remote';
import {
  ExerciseQuizQuestion,
  ExerciseOJProblem,
} from '@/components/exercise/types';
import QuizViewer from '@/components/exercise/quiz/quiz-viewer';
import OjViewer from '@/components/exercise/oj/oj-viewer';

const QuestionPage = () => {
  const [quizData, setQuizData] = useState<ExerciseQuizQuestion>();
  const [ojData, setOjData] = useState<ExerciseOJProblem>();
  const [questionType, setQuestionType] = useState<string>('');

  const history = useHistory();
  useEffect(() => {
    const urlInfo = history?.location?.pathname?.split('/');
    if (urlInfo[1] === 'exercise') {
      exerciseInit(urlInfo[3], urlInfo[4]);
    } else {
      initData(Number(urlInfo[2]), urlInfo[3]);
      setQuestionType(urlInfo[3]);
    }
  }, [history?.location?.pathname]);

  const initData = async (id: number, type: string) => {
    let questionData: ExerciseOJProblem | ExerciseQuizQuestion;
    if (type === 'oj') {
      questionData = await remote.$post('/teaching/getQuestionInfo', {
        questionId: id,
        type: 'OJ',
      });
      setOjData(questionData);
    } else {
      questionData = await remote.$post('/teaching/getQuestionInfo', {
        questionId: id,
        type: 'Quiz',
        // "type": "OJ"|"Quiz"
      });
      setQuizData(questionData);
    }
  };
  const exerciseInit = async (sessionId: string, recordId: string) => {
    const questionData: any = await remote.$get(
      `/assessment-session-record/history/assessmentSessionId/${sessionId}/recordId/${recordId}`,
    );
    if (questionData.type === 'oj-problem') {
      setOjData(questionData);
      setQuestionType('oj');
    } else {
      setQuizData(questionData);
      setQuestionType('quiz');
    }
  };

  return (
    <div>
      {questionType === 'oj' ? (
        <OjViewer question={ojData} />
      ) : (
        <QuizViewer question={quizData} />
      )}
    </div>
  );
};

export default QuestionPage;
