import { Button } from '@/atoms/button';
import { Modal } from '@/atoms/modal';
import { UserExamCert } from '@/typings/exam';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import styles from './index.module.less';

type Props = { cert: UserExamCert; realName: string };

const CertViewer = ({ cert, realName }: Props) => {
  const handleOpenChange = useCallback(
    (open) => {
      if (open) {
        setTimeout(() => {
          if (!cert.serialNumber) return;
          const canvas: HTMLCanvasElement | null = document.getElementById(
            'cert-viewer',
          ) as HTMLCanvasElement;
          if (!canvas) {
            return;
          }
          const context = canvas.getContext('2d');
          if (!context) {
            return;
          }
          const config = cert.templateConfig;
          if (!config) return;
          const img = new Image(); // 创建img元素
          img.crossOrigin = 'anonymous';
          img.onload = function () {
            // 执行drawImage语句
            // var width = canvas.width;
            // var height = width / (612 / 858);
            context.drawImage(img, 0, 0);
            const fontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;
            context.font = `${config.userName.fontSize}px ${fontFamily}`;
            context.fillText(realName, config.userName.x, config.userName.y);

            context.font = `${config.date.fontSize}px ${fontFamily}`;
            context.fillText(
              dayjs(cert.createdAt).format('YYYY年MM月DD日'),
              config.date.x,
              config.date.y,
            );
            context.font = `${config.serialNumber.fontSize}px ${fontFamily}`;
            context.fillText(
              `证书编号：${cert.serialNumber}`,
              config.serialNumber.x,
              config.serialNumber.y,
            );
          };
          img.src = cert.templateConfig?.imageUrl || ''; // 设置图片源地址
        }, 300);
      }
    },
    [cert, realName],
  );
  const handleDownload = useCallback(() => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', `${cert.serialNumber}.png`);
    let canvas: HTMLCanvasElement | null = document.getElementById(
      'cert-viewer',
    ) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    let dataURL = canvas.toDataURL('image/png');
    let url = dataURL.replace(
      /^data:image\/png/,
      'data:application/octet-stream',
    );
    downloadLink.setAttribute('href', url);
    downloadLink.click();
  }, [cert.serialNumber]);

  if (!cert.serialNumber) {
    return null;
  }
  return (
    <Modal
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      trigger={<a> 查看证书 </a>}
      onOpenChange={handleOpenChange}
    >
      <div className={styles.certView}>
        <Button onClick={handleDownload} size="small" variant="sub">
          下载
        </Button>
        <canvas id="cert-viewer" width={816} height={612} />
      </div>
    </Modal>
  );
};

export default CertViewer;
