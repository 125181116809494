import {
  AnswerSequence,
  ExamInfo,
  ExamTypingSequence,
  ResetSequence,
  SubmitSequence,
  TestSequence,
  TypingRecord,
  TypingSequence,
} from '@/typings/typing-sequence';
import { limitMaxCountPromise } from '@/utils';
import remote from '@/utils/remote';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';

const diffJS = require('@/utils/diff');

const blob = new Blob([diffJS]);
const url = window.URL.createObjectURL(blob);

/**
 * 还原增加类型下答题记录
 * @param oriStr 上一次操作的code
 * @param pos 本次要操作的字符位置
 * @param code 要增加的字符
 * @returns newCode
 */
export function handleAddOp(oriStr: string, pos: number, code: string) {
  return oriStr.substring(0, pos) + code + oriStr.substring(pos);
}

/**
 * 还原删除类型下答题记录
 * @param oriStr 上一次操作的code
 * @param pos 本次要操作的字符位置
 * @param count 本次要删除的字符数
 * @returns newCode
 */
export function handleRemoveOp(oriStr: string, pos: number, count: number) {
  return oriStr.substring(0, pos) + oriStr.substring(pos + count);
}

export function useTypingSequence(examInfo: ExamInfo) {
  const { userId, examId, partId } = examInfo;
  if (!userId || !examId || !partId) {
    throw new Error('[TypingSequence] 缺少初始化参数');
  }

  const STORAGE_NAME = `ts-${userId}-${examId}-${partId}`;
  const isSubmitting = useRef(false);
  let instance: React.MutableRefObject<ExamTypingSequence> = useRef({
    userId,
    examId,
    partId,
    typingSequences: [],
  });
  useEffect(() => {
    if (localStorage.getItem(STORAGE_NAME)) {
      instance.current = JSON.parse(localStorage.getItem(STORAGE_NAME)!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function saveTypingSequenceAtLocal() {
    localStorage.setItem(STORAGE_NAME, JSON.stringify(instance.current));
  }

  function removeTypingSequenceAtLocal() {
    localStorage.removeItem(STORAGE_NAME);
  }

  function initTypingSequence({
    typeSectionId,
    questionId,
    defaultCode,
  }: {
    typeSectionId: number;
    questionId: number;
    defaultCode: string;
  }) {
    instance.current.typingSequences.push({
      typeSectionId,
      questionId,
      lastUserAnswer: defaultCode,
      editTimes: [
        {
          editAt: new Date(),
          totalTime: 0,
        },
      ],
      typingRecords: [
        {
          type: 'INITIAL',
          userAnswer: defaultCode,
          startTime: 0,
        },
      ],
      recordsIndex: 0,
    });

    saveTypingSequenceAtLocal();
  }

  function getCurrentTS(typeSectionId: number, questionId: number) {
    return instance.current.typingSequences.find(
      (v) => v.typeSectionId === typeSectionId && v.questionId === questionId,
    );
  }

  function startCurrentTypingSequence({
    typeSectionId,
    questionId,
    defaultCode,
  }: {
    typeSectionId: number;
    questionId: number;
    defaultCode: string;
  }) {
    const currentTS = getCurrentTS(typeSectionId, questionId);
    if (currentTS) {
      currentTS.editTimes.push({
        editAt: new Date(),
        totalTime: 0,
      });
    } else {
      initTypingSequence({ typeSectionId, questionId, defaultCode });
    }
    saveTypingSequenceAtLocal();
  }

  function pauseCurrentTypingSequence({
    typeSectionId,
    questionId,
  }: {
    typeSectionId: number;
    questionId: number;
  }) {
    const currentTS = getCurrentTS(typeSectionId, questionId);
    if (currentTS) {
      const timeArrLength = currentTS.editTimes.length - 1;
      currentTS.editTimes[timeArrLength].totalTime += dayjs().diff(
        currentTS.editTimes[timeArrLength].editAt,
        'ms',
      );
    }
    saveTypingSequenceAtLocal();
  }

  async function doSubmit(ts: TypingSequence, callback?: any) {
    const { typingRecords } = ts;
    if (!Array.isArray(typingRecords) || typingRecords.length === 0) return;

    isSubmitting.current = true;
    const { typeSectionId, questionId, editTimes, recordsIndex } = ts;
    const { userId, examId, partId } = instance.current;
    const totalTime = editTimes.reduce((acc, cur) => acc + cur.totalTime, 0);

    await remote
      .$post(
        `/typing-sequence`,
        {
          userId,
          examId,
          partId,
          typeSectionId,
          totalTime,
          questionId,
          typingRecords: typingRecords.filter(Boolean).flat(1),
          recordsIndex,
        },
        {
          onServerError: () => {},
        },
      )
      .then(() => {
        callback && callback();
      })
      .finally(() => {
        isSubmitting.current = false;
        ts.recordsIndex++;
      });
  }

  /**
   * 检查当前题目的records大小，如果过大，则发送当前内容存进库中
   */
  async function checkCurrentTypingSequenceSize({
    typeSectionId,
    questionId,
  }: {
    typeSectionId: number;
    questionId: number;
  }) {
    const MAX_MOMERY_SIZE = 100 * 1024 - 200; // 请求体大小100KB，预留200字节空间
    const currentTS = getCurrentTS(typeSectionId, questionId);
    if (isSubmitting.current) return;
    if (
      new Blob([
        JSON.stringify(currentTS!.typingRecords.filter(Boolean).flat(1)),
      ]).size >= MAX_MOMERY_SIZE
    ) {
      const count = currentTS!.typingRecords.length;
      await doSubmit(currentTS!, () => {
        for (let i = 0; i < count; i++) {
          currentTS!.typingRecords[i] = null;
        }
      });
    }
  }

  async function addTypingRecord(
    newRecord: AnswerSequence | TestSequence | SubmitSequence | ResetSequence,
    {
      typeSectionId,
      questionId,
    }: { typeSectionId: number; questionId: number },
  ) {
    // 先检查当前题目的records大小，如果过大，则发送当前内容存进库中
    await checkCurrentTypingSequenceSize({ typeSectionId, questionId });
    // Diff操作可能会阻塞页面运行，因此使用Worker开启子线程，异步计算
    const worker = new Worker(url);

    const currentTS = getCurrentTS(typeSectionId, questionId);
    const timeArrLength = currentTS!.editTimes.length - 1;

    currentTS!.editTimes[timeArrLength].totalTime = dayjs().diff(
      dayjs(currentTS!.editTimes[timeArrLength].editAt),
      'ms',
    );
    const startTime = currentTS!.editTimes.reduce(
      (acc, cur) => acc + cur.totalTime,
      0,
    );

    const baseRecord: TypingRecord & { userAnswer?: string } = {
      type: newRecord.type,
      startTime,
    };
    if (newRecord.type === 'UPDATE_ANSWER') {
      baseRecord.userAnswer = newRecord.userAnswer;
    }

    currentTS?.typingRecords.push(baseRecord);

    worker.addEventListener('message', async (e: any) => {
      // diff计算完成的操作
      const { idx, res: record } = e.data;
      const currentTS = getCurrentTS(typeSectionId, questionId);
      if (record === null) return;
      currentTS!.typingRecords[idx] = record;
      saveTypingSequenceAtLocal();
      worker.terminate();
    });

    worker.postMessage({
      newRecord,
      startTime,
      lastUserAnswer: currentTS!.lastUserAnswer,
      idx: currentTS!.typingRecords.length - 1,
    });
    if ((newRecord as AnswerSequence).userAnswer !== undefined) {
      currentTS!.lastUserAnswer = (newRecord as AnswerSequence).userAnswer;
      saveTypingSequenceAtLocal();
    }
  }

  async function submitTypingSequence() {
    if (!Array.isArray(instance.current.typingSequences)) {
      throw new Error('[Typing Sequnece] 数据缺失');
    }
    if (instance.current.typingSequences.length === 0) return;

    await limitMaxCountPromise(
      5,
      instance.current.typingSequences.map(async (ts) => doSubmit(ts)),
    );

    removeTypingSequenceAtLocal();
  }

  return {
    startCurrentTypingSequence,
    pauseCurrentTypingSequence,
    addTypingRecord,
    submitTypingSequence,
  };
}
